
export enum Status {
  Success = "Success",
  Error = "Error",
  ERROR_JWT = "jwt",
  ERROR_JWT_01 = "JWT01",
  ERROR_JWT_02 = "JWT02",
  ERROR_PER = "PER01",
  ERROR_AUT01 = "AUT01",
  ERROR_AUT2 = "AUT2",
}

export interface SuccessResponse<T> {
  status: Status.Success;
  data:T;
}



export interface ErrorResponse<T> {
  status: Status.Error;
  error:string;
  data:T;
}

export interface ErrorAlertVal<> {
  codeList:Array<String>,
  valueList:Array<String>
}


export interface ResponseTemplate<T>{
  body: SuccessResponse<T> | ErrorResponse<T>;
  headers: object
  ok: boolean
  status: number
  statusText: string
  type: number
  url:string
}


// export type APIResponse<T> = SuccessResponse<T> | ErrorResponse<T>;
export type APIResponse<T> = ResponseTemplate<T>;

export interface PostIdsResponse {
  posts: PostIds
}
export interface PostIds {
  expiry: number,
  postIds: Array<string>
}



export interface LikesReq {
  "postId": string
}

export interface AddLikesRes {
  
}

export interface ShareReq {
  "postId": string,
  "via": number
}

export interface ShareRes {
  
}
