import { NgModule } from '@angular/core';
import { PageHeaderModule } from '../../components/page-header/page-header.module';
import { CommonCompModule } from '../../shared/common.component.module';
import { CategoryPageRoutingModule } from './category-routing.module';
import { CategoryPage } from './category.page';


@NgModule({
  imports: [
    CommonCompModule,
    CategoryPageRoutingModule,
    PageHeaderModule,
  ],
  declarations: [CategoryPage],
  exports: [CategoryPage]
})
export class CategoryPageModule { }
