import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetPostIdsClientReq, GetPostIdsClientRes, GetPostClientReq, GetPostClientRes, SearchPostClientReq, SearchPostClientRes, GetUserPostIdsClientReq, GetUserPostIdsClientRes, DeletePostClientReq, DeletePostClientRes } from 'social-media-api';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, SERVICE_URL } from '../../util/constants';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { APIResponse } from '../service.model';

@Injectable({
  providedIn: 'root'
})
export class FeedService
{

  constructor(private logger: LoggerService, private httpService: HttpService) { }

  ///post ids
  getPostIdsReq = (pOffSetReq: number): GetPostIdsClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getPostIdsReq....");


      const reqBody: GetPostIdsClientReq = {
        "offset": pOffSetReq,
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getPostIdsReq",
        err.toString(),
        "getPostIdsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getPostIdsRes(pReq: GetPostIdsClientReq): Observable<APIResponse<GetPostIdsClientRes>>
  {
    let retValue: Observable<APIResponse<GetPostIdsClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_POSTIDS, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getPostIdsRes",
        err.toString(),
        "getPostIdsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// get post

  getPostsReq = (pPostIds: string[], pIsUser: boolean = false): GetPostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getPostIdsReq....");


      const reqBody: GetPostClientReq = {
        "postIds": pPostIds,
        isUser: pIsUser
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getPostIdsReq",
        err.toString(),
        "getPostIdsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getPostsRes(pReq: GetPostClientReq): Observable<APIResponse<GetPostClientRes>>
  {
    let retValue: Observable<APIResponse<GetPostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_POSTS, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getPostIdsRes",
        err.toString(),
        "getPostIdsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  /// search post

  searchPostsReq = (searchReq: SearchPostClientReq): SearchPostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getPostIdsReq....");


      const reqBody: SearchPostClientReq = {
        searchTerm: searchReq.searchTerm,
        categoryList: searchReq.categoryList,
        offset: searchReq.offset
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getPostIdsReq",
        err.toString(),
        "getPostIdsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  searchPostsRes(pReq: SearchPostClientReq): Observable<APIResponse<SearchPostClientRes>>
  {
    let retValue: Observable<APIResponse<SearchPostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_SEARCH_POST, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getPostIdsRes",
        err.toString(),
        "getPostIdsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  ///user post ids
  getUserPostIdsReq = (pUserName: string, pOffSetReq: number): GetUserPostIdsClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUserPostIdsReq....");


      const reqBody: GetUserPostIdsClientReq = {
        offset: pOffSetReq,
        userName: pUserName
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getUserPostIdsReq",
        err.toString(),
        "getUserPostIdsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getUserPostIdsRes(pReq: GetUserPostIdsClientReq): Observable<APIResponse<GetUserPostIdsClientRes>>
  {
    let retValue: Observable<APIResponse<GetUserPostIdsClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_USER_POSTIDS, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getUserPostIdsRes",
        err.toString(),
        "getUserPostIdsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// get trending post

  getTrendingPostsReq = (pOffset: number) =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getTrendingPostsReq....");


      const reqBody = {
        "offset": pOffset,
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getTrendingPostsReq",
        err.toString(),
        "getTrendingPostsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getTrendingPostsRes(pReq): Observable<APIResponse<any>>
  {
    let retValue: Observable<APIResponse<any>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_TRENDING_POST, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getTrendingPostsRes",
        err.toString(),
        "getTrendingPostsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  getDeletePostsReq = (pPostId: string) =>
  {
    let retValue: DeletePostClientReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getDeletePostsReq....");

      const reqBody: DeletePostClientReq = { postId: pPostId };

      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getDeletePostsReq",
        err.toString(),
        "getDeletePostsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getDeletePostsRes(pReq): Observable<APIResponse<DeletePostClientRes>>
  {
    let retValue: Observable<APIResponse<DeletePostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_DELETE_POST, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.FEED_SERVICE,
        "error in getDeletePostsRes",
        err.toString(),
        "getDeletePostsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }
}
