import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetCountriesClientRes, GetCurrencyClientRes, GetLanguageRes } from 'social-media-api';
import { GetNotificationSettingRes, GetPrivacySettingRes, UpdateCommentsFromClientReq, UpdateCommentsFromClientRes, UpdateNotificationSettingsClientReq, UpdateNotificationSettingsClientRes, UpdatePrivacySettingsClientReq, UpdatePrivateStatusClientReq } from 'social-media-api/dist/services/social-media/settings/Settings.model';
import { GetUserSettingsResponse, UpdateUserSettingsRequest, UpdateUserSettingsResponse } from 'social-media-api/dist/services/userSettings/userSettings.model';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, SERVICE_URL } from '../../util/constants';
import { APIResponse } from '../service.model';
import { HttpService } from './../http/http.service';
import { LoggerService } from './../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private logger: LoggerService, private httpService: HttpService) { }

  
///get currency

getCurrencyRes(): Observable<APIResponse<GetCurrencyClientRes>> {
  let retValue: Observable<APIResponse<GetCurrencyClientRes>>;

  try {
  
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_CURRENCY, null);
  
  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
    "error in addPostRes",
    err.toString(),
     "addPostRes",
      );
     this.logger.getResponse(logRequest);
  }
  return retValue;
}


  
///get country

getCountryRes(): Observable<APIResponse<GetCountriesClientRes>> {
  let retValue: Observable<APIResponse<GetCountriesClientRes>>;

  try {
  
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_COUNTRY, null);
  
  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
    "error in getCountryRes",
    err.toString(),
     "getCountryRes",
      );
     this.logger.getResponse(logRequest);
  }
  return retValue;
}

///get language

getLanguageRes(): Observable<APIResponse<GetLanguageRes>> {
  let retValue: Observable<APIResponse<GetLanguageRes>>;

  try {
  
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_LANGUAGE, null);
  
  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
    "error in getLanguageRes",
    err.toString(),
     "getLanguageRes",
      );
     this.logger.getResponse(logRequest);
  }
  return retValue;
}


/// update notification 

updateNotificationReq = (pNotification): UpdateNotificationSettingsClientReq => {
  let retValue: any;
  try {
    if (BASE_CONFIG.IS_DEBUG) console.log("updateNotificationReq....");


    const reqBody: UpdateNotificationSettingsClientReq = {
      likes: pNotification.likes,
      postComments: pNotification.postComments,
      commentLikes: pNotification.commentLikes,
      firstPost: pNotification.firstPost,
      newFollowers: pNotification.newFollowers,
      acceptFollow: pNotification.acceptFollow,
      accountSuggestion: pNotification.accountSuggestion,
      newsLetter: 0 //TODO: pending work
    };
    retValue = reqBody
  } catch (err: any) {
    const body = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in updateNotificationReq",
      err.toString(),
      "updateNotificationReqReq",
    );
    this.logger.getResponse(body);
  }
  return retValue;
}

updateNotificationRes(pReq: UpdateNotificationSettingsClientReq): Observable<APIResponse<UpdateNotificationSettingsClientRes>> {
  let retValue: Observable<APIResponse<UpdateNotificationSettingsClientRes>>;

  try {

    retValue = this.httpService.makePostRequest(SERVICE_URL.API_UPDATE_NOTIFICATION, pReq);

  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in updateNotificationRes",
      err.toString(),
      "updateNotificationRes",
    );
    this.logger.getResponse(logRequest);
  }
  return retValue;
}


/// update privacy 

updatePrivacyReq = (pPrivacy): UpdatePrivacySettingsClientReq => {
  let retValue: any;
  try {
    if (BASE_CONFIG.IS_DEBUG) console.log("updatePrivacyReq....");


    const reqBody: UpdatePrivacySettingsClientReq = {
      likes: pPrivacy.like,
      isPrivateAccount: pPrivacy.isPrivateAccount,
    activityStatus: pPrivacy.activityStatus,
    allowCommentsFrom: pPrivacy.allowCommentsFrom,
    };
    retValue = reqBody
  } catch (err: any) {
    const body = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in updatePrivacyReq",
      err.toString(),
      "updatePrivacyReq",
    );
    this.logger.getResponse(body);
  }
  return retValue;
}

updatePrivacyRes(pReq: UpdatePrivacySettingsClientReq): Observable<APIResponse<UpdatePrivateStatusClientReq>> {
  let retValue: Observable<APIResponse<UpdatePrivateStatusClientReq>>;

  try {

    retValue = this.httpService.makePostRequest(SERVICE_URL.API_UPDATE_PRIVACY, pReq);

  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in updatePrivacyRes",
      err.toString(),
      "updatePrivacyRes",
    );
    this.logger.getResponse(logRequest);
  }
  return retValue;
}


getNotificationRes(): Observable<APIResponse<GetNotificationSettingRes>> {
  let retValue: Observable<APIResponse<GetNotificationSettingRes>>;

  try {

    retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_NOTIFICATION, null);

  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in getNotificationRes",
      err.toString(),
      "getNotificationRes",
    );
    this.logger.getResponse(logRequest);
  }
  return retValue;
}




getPrivacyRes(): Observable<APIResponse<GetPrivacySettingRes>> {
  let retValue: Observable<APIResponse<GetPrivacySettingRes>>;

  try {

    retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_PRIVACY, null);

  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in getPrivacyRes",
      err.toString(),
      "getPrivacyRes",
    );
    this.logger.getResponse(logRequest);
  }
  return retValue;
}

getUserSettingsRes(): Observable<APIResponse<GetUserSettingsResponse>> {
  let retValue: Observable<APIResponse<GetUserSettingsResponse>>;

  try {

    retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_USER_SETTINGS, null);

  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in getUserSettingsRes",
      err.toString(),
      "getUserSettingsRes",
    );
    this.logger.getResponse(logRequest);
  }
  return retValue;
}

getUpdateUserSettingsReq(pSettings: any): UpdateUserSettingsRequest {
  let retValue: UpdateUserSettingsRequest;

  try {

    const reqBody: UpdateUserSettingsRequest = {
      languageCode: pSettings.languageCode,
      emailNotification: pSettings.emailNotification,
      appNotification: pSettings.appNotification,
      theme: pSettings.theme,
      currency: pSettings.currency
    }
    retValue = reqBody;
  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in getUpdateUserSettingsRes",
      err.toString(),
      "getUpdateUserSettingsRes",
    );
    this.logger.getResponse(logRequest);
  }
  return retValue;
}
getUpdateUserSettingsRes(pRes: UpdateUserSettingsRequest): Observable<APIResponse<UpdateUserSettingsResponse>> {
  let retValue: Observable<APIResponse<UpdateUserSettingsResponse>>;

  try {

    retValue = this.httpService.makePostRequest(SERVICE_URL.API_UPDATE_USER_SETTINGS, pRes);

  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in getUpdateUserSettingsRes",
      err.toString(),
      "getUpdateUserSettingsRes",
    );
    this.logger.getResponse(logRequest);
  }
  return retValue;
}

getUpdateAllowCommentFromReq(pAllowFrom: number): UpdateCommentsFromClientReq {
  let retValue: UpdateCommentsFromClientReq;

  try {

    const reqBody: UpdateCommentsFromClientReq = {
      status: pAllowFrom
    }
    retValue = reqBody;
  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in getUpdateAllowCommentFromReq",
      err.toString(),
      "getUpdateAllowCommentFromReq",
    );
    this.logger.getResponse(logRequest);
  }
  return retValue;
}
getUpdateAllowCommentFromRes(pRes: UpdateCommentsFromClientReq): Observable<APIResponse<UpdateCommentsFromClientRes>> {
  let retValue: Observable<APIResponse<UpdateCommentsFromClientRes>>;

  try {

    retValue = this.httpService.makePostRequest(SERVICE_URL.API_COMMENTS_FROM_SETTINGS, pRes);

  } catch (err) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.SETTINGS_SERVICE,
      "error in getUpdateAllowCommentFromRes",
      err.toString(),
      "getUpdateAllowCommentFromRes",
    );
    this.logger.getResponse(logRequest);
  }
  return retValue;
}
}
