<div>
  <ion-row class="header_row">
    <!-- home -->
    <ion-col
      [ngClass]="{'home_icon_detail' : isDetailPage}"
      size="2"
      size-sm="4"
      class="home_icon"
    >
      <div
        (click)="isDetailPage ? navToPost() : scrollToTop()"
        class="profile_btn"
      >
        <ion-icon src="/assets/svg/web/web_home.svg"></ion-icon>
      </div>

    </ion-col>

    <!-- logo -->
    <ion-col
      size="3"
      size-sm="4"
      class="logo_icon"
      (click)="isDetailPage ? navToPost() : scrollToTop()"
    >
      <ion-icon
        src="/assets/svg/settings/tinq_real_logo.svg"
        class="post_page_logo"
      ></ion-icon>
    </ion-col>


    <ion-col
      size="7"
      size-sm="4"
      class="ion-text-right header_home_post all_icon"
    >

      <!-- fit to screen -->
      <div
        *ngIf="isDetailPage"
        (click)="onClickFitToScreen()"
        class="profile_btn"
        #infoFeature="fivFeature"
        [fivFeature]="tinQFeature"
      >
        <ion-icon [name]="isFitToScreen ? 'tablet-landscape-outline' : 'tablet-portrait-outline'"></ion-icon>
      </div>

      <!-- search -->
      <div
        (click)="toggleIsSearch()"
        class="profile_btn"
        #searchFeature="fivFeature"
        [fivFeature]="tinQFeature"
      >
        <ion-icon src="/assets/svg/web/web_search.svg"></ion-icon>
      </div>

      <!-- add post -->
      <div
        (click)="navToAddPost()"
        class="profile_btn"
        #addPostFeature="fivFeature"
        [fivFeature]="tinQFeature"
      >
        <ion-icon src="/assets/svg/web/web_add.svg"></ion-icon>
      </div>

      <!-- bookmark -->
      <div
        (click)="navToSavedPosts()"
        class="profile_btn"
      >
        <ion-icon src="/assets/svg/web/web_bookmarks.svg"></ion-icon>
      </div>

      <!-- notification -->
      <div
        (click)="navToNotification()"
        class="profile_btn"
      >
        <ion-icon src="/assets/svg/web/web_notification.svg"></ion-icon>
      </div>

      <!-- profile -->
      <div
        (click)="navToAppUserProfile()"
        class="profile_btn"
        #profileFeature="fivFeature"
        [fivFeature]="tinQFeature"
      >
        <ion-icon src="/assets/svg/web/web_avatar.svg"></ion-icon>
      </div>

    </ion-col>
  </ion-row>
</div>

<ng-template #tinQFeature>
  <div padding>
    <ion-text color="light">
      <h1>{{currFeature.title | translate}}</h1>
      <p>{{currFeature.desc | translate}}</p>
    </ion-text>
    <ion-buttons>
      <ion-button
        color="light"
        shape="round"
        fill="outline"
        (click)="showNextFeature()"
      >
        <ion-icon
          slot="end"
          name="arrow-forward-outline"
        ></ion-icon>
        {{'nextText' | translate}}
      </ion-button>
      <!-- <ion-button
        color="light"
        shape="round"
        fill="outline"
        (click)="feature.hide();"
      >
        <ion-icon
          slot="end"
          name="close"
        ></ion-icon>
        Skip
      </ion-button> -->
    </ion-buttons>
  </div>
</ng-template>
