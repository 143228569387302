import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApproveUserFollowClientReq, ApproveUserFollowClientRes, BlockUserClientReq, BlockUserClientRes, CancelSentFollowReq, CancelSentFollowRes, CreateUserReq, FollowClientReq, FollowClientRes, GetBlockedUserClientRes, GetMutedUserClientRes, GetPreferenceClientRes, GetProfileDetailClientRes, GetUserDetailsClientReq, GetUserDetailsClientRes, GetUserFollowerClientRes, GetUserFollowersClientReq, GetUserFollowerSuggestionsClientReq, GetUserFollowerSuggestionsClientRes, GetUserFollowingClientRes, GetUserFollowingsClientReq, IsUserFollowedClientReq, IsUserFollowedClientRes, MuteClientReq, MuteClientRes, ReceivedFollowRequestClientRes, RemoveDpRes, RemoveFollowClientRes, RemoveUnFollowClientReq, SearchUserClientReq, SearchUserClientRes, SendedFollowRequestClientRes, UnBlockUserClientReq, UnBlockUserClientRes, UnFollowClientReq, UnFollowClientRes, UnmuteClientReq, UnmuteClientRes, UpdatePreferenceClientReq, UpdatePreferenceClientRes, UpdateProfileDetailsReq, UpdateProfileDetailsRes, UpdateUserBioClientReq, UpdateUserBioClientRes, ValidateUserNameReq, ValidateUserNameRes } from 'social-media-api';
import { ReportUserReq, ReportUserRes } from '../../model/user.model';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, SERVICE_URL } from '../../util/constants';
import { HttpService } from '../http/http.service';
import { APIResponse } from '../service.model';
import { LoggerService } from './../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class UserService
{

  constructor(
    private logger: LoggerService,
    private httpService: HttpService
  ) { }


  ///follow
  followUserReq = (pUserId: string, pPostId: string): FollowClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("followUserReq....");


      const reqBody: FollowClientReq = {
        "followTo": pUserId,
        "postId": pPostId,
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in followUserReq",
        err.toString(),
        "followUserReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  followUserRes(pReq: FollowClientReq): Observable<APIResponse<FollowClientRes>>
  {
    let retValue: Observable<APIResponse<FollowClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_FOLLOW_USER, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in followUserRes",
        err.toString(),
        "followUserRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  ///unfollow
  unFollowUserReq = (pUserId: string,): UnFollowClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("unFollowUserReq....");


      const reqBody: UnFollowClientReq = {
        "followedTo": pUserId,
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in unFollowUserReq",
        err.toString(),
        "unFollowUserReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  unFollowUserRes(pReq: UnFollowClientReq): Observable<APIResponse<UnFollowClientRes>>
  {
    let retValue: Observable<APIResponse<UnFollowClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_UNFOLLOW_USER, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in unFollowUserRes",
        err.toString(),
        "unFollowUserRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///getPreference

  getPreferenceRes(): Observable<APIResponse<GetPreferenceClientRes>>
  {
    let retValue: Observable<APIResponse<GetPreferenceClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_PREFERENCE, null);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getPreferenceRes",
        err.toString(),
        "getPreferenceRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  ///update Preference
  updatePreferenceReq = (pGroups: string[]): UpdatePreferenceClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("updatePreferenceReq....");


      const reqBody: UpdatePreferenceClientReq = {
        preference: {
          groups: pGroups
        }
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in updatePreferenceReq",
        err.toString(),
        "updatePreferenceReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  updatePreferenceRes(pReq: UpdatePreferenceClientReq): Observable<APIResponse<UpdatePreferenceClientRes>>
  {
    let retValue: Observable<APIResponse<UpdatePreferenceClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_UPDATE_PREFERENCE, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in updatePreferenceRes",
        err.toString(),
        "updatePreferenceRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  ///followers list
  getFollowersListReq = (pUserName: string, pOffset: number): GetUserFollowersClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getFollowersListReq....");


      const reqBody: GetUserFollowersClientReq = {
        offset: pOffset,
        userName: pUserName
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getFollowersListReq",
        err.toString(),
        "getFollowersListReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getFollowersListRes(pReq: GetUserFollowersClientReq): Observable<APIResponse<GetUserFollowerClientRes>>
  {
    let retValue: Observable<APIResponse<GetUserFollowerClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_FOLLOWERS_LIST, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getFollowersListRes",
        err.toString(),
        "getFollowersListRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  ///followings list
  getFollowingsListReq = (pUserName: string, pOffset: number): GetUserFollowingsClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getFollowingsListReq....");


      const reqBody: GetUserFollowingsClientReq = {
        offset: pOffset,
        userName: pUserName
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getFollowingsListReq",
        err.toString(),
        "getFollowingsListReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getFollowingsListRes(pReq: GetUserFollowingsClientReq): Observable<APIResponse<GetUserFollowingClientRes>>
  {
    let retValue: Observable<APIResponse<GetUserFollowingClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_FOLLOWING_LIST, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getFollowingsListRes",
        err.toString(),
        "getFollowingsListRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///followers suggestion list
  getFollowersSuggListReq = (pOffset: number): GetUserFollowerSuggestionsClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getFollowersSuggListReq....");


      const reqBody: GetUserFollowerSuggestionsClientReq = {
        offset: pOffset
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getFollowersSuggListReq",
        err.toString(),
        "getFollowersSuggListReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getFollowersSuggListRes(pReq: GetUserFollowerSuggestionsClientReq): Observable<APIResponse<GetUserFollowerSuggestionsClientRes>>
  {
    let retValue: Observable<APIResponse<GetUserFollowerSuggestionsClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_FOLLOWERS_SUGGESTION_LIST, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getFollowersSuggListRes",
        err.toString(),
        "getFollowersSuggListRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  ///user details
  getUserDetailsReq = (pUserName: string[], pOffset: number): GetUserDetailsClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUserDetailsReq....");


      const reqBody: GetUserDetailsClientReq = {
        userName: pUserName,
        offset: pOffset
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getUserDetailsReq",
        err.toString(),
        "getUserDetailsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getUserDetailsRes(pReq: GetUserDetailsClientReq): Observable<APIResponse<GetUserDetailsClientRes>>
  {
    let retValue: Observable<APIResponse<GetUserDetailsClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_USER_DETAILS, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getUserDetailsRes",
        err.toString(),
        "getUserDetailsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///profile details

  getProfileDetailsRes(): Observable<APIResponse<GetProfileDetailClientRes>>
  {
    let retValue: Observable<APIResponse<GetProfileDetailClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_PROFILE_DETAILS, null);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getProfileDetailsRes",
        err.toString(),
        "getProfileDetailsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///user is followed
  getUserIsFollowedReq = (pFollowedTo: string): IsUserFollowedClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUserIsFollowedReq....");


      const reqBody: IsUserFollowedClientReq = {
        followedTo: pFollowedTo
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getUserIsFollowedReq",
        err.toString(),
        "getUserIsFollowedReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getUserIsFollowedRes(pReq: IsUserFollowedClientReq): Observable<APIResponse<IsUserFollowedClientRes>>
  {
    let retValue: Observable<APIResponse<IsUserFollowedClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_IS_USER_FOLLOWED, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getUserIsFollowedRes",
        err.toString(),
        "getUserIsFollowedRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///update user bio
  updateUserBioReq = (pBio: string): UpdateUserBioClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("updateUserBioReq....");


      const reqBody: UpdateUserBioClientReq = {
        bio: pBio
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in updateUserBioReq",
        err.toString(),
        "updateUserBioReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  updateUserBioRes(pReq: UpdateUserBioClientReq): Observable<APIResponse<UpdateUserBioClientRes>>
  {
    let retValue: Observable<APIResponse<UpdateUserBioClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_UPDATE_USER_BIO, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in updateUserBioRes",
        err.toString(),
        "updateUserBioRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  ///block user 
  blockUserReq = (pblockedTo: string): BlockUserClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("blockUserReq....");


      const reqBody: BlockUserClientReq = {
        blockedTo: pblockedTo
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in blockUserReq",
        err.toString(),
        "blockUserReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  blockUserRes(pReq: BlockUserClientReq): Observable<APIResponse<BlockUserClientRes>>
  {
    let retValue: Observable<APIResponse<BlockUserClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_BLOCK_USER, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in blockUserRes",
        err.toString(),
        "blockUserRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///unblock user 
  unBlockUserReq = (pblockedTo: string): UnBlockUserClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("unBlockUserReq....");


      const reqBody: UnBlockUserClientReq = {
        blockedTo: pblockedTo
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in unBlockUserReq",
        err.toString(),
        "unBlockUserReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  unBlockUserRes(pReq: UnBlockUserClientReq): Observable<APIResponse<UnBlockUserClientRes>>
  {
    let retValue: Observable<APIResponse<UnBlockUserClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_UNBLOCK_USER, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in unBlockUserRes",
        err.toString(),
        "unBlockUserRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///mute user 
  muteUserReq = (pmuteTo: string): MuteClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("muteUserReq....");


      const reqBody: MuteClientReq = {
        muteTo: pmuteTo
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in muteUserReq",
        err.toString(),
        "muteUserReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  muteUserRes(pReq: MuteClientReq): Observable<APIResponse<MuteClientRes>>
  {
    let retValue: Observable<APIResponse<MuteClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_MUTE_USER, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in muteUserRes",
        err.toString(),
        "muteUserRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///unmute user 
  unMuteUserReq = (pmuteTo: string): UnmuteClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("unMuteUserReq....");


      const reqBody: UnmuteClientReq = {
        muteTo: pmuteTo
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in unMuteUserReq",
        err.toString(),
        "unMuteUserReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  unMuteUserRes(pReq: UnmuteClientReq): Observable<APIResponse<UnmuteClientRes>>
  {
    let retValue: Observable<APIResponse<UnmuteClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_UNMUTE_USER, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in unMuteUserRes",
        err.toString(),
        "unMuteUserRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }



  getBlockedUserLsRes(): Observable<APIResponse<GetBlockedUserClientRes>>
  {
    let retValue: Observable<APIResponse<GetBlockedUserClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_BLOCKED_LIST, null);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getBlockedUserLsRes",
        err.toString(),
        "getBlockedUserLsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  getMutedUserLsRes(): Observable<APIResponse<GetMutedUserClientRes>>
  {
    let retValue: Observable<APIResponse<GetMutedUserClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_MUTEED_LIST, null);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getMutedUserLsRes",
        err.toString(),
        "getMutedUserLsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///remove follow 
  removeFollowReq = (pFollowedBy: string): RemoveUnFollowClientReq =>
  {
    let retValue: RemoveUnFollowClientReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeFollowReq....");


      const reqBody: RemoveUnFollowClientReq = {
        followedBy: pFollowedBy
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in removeFollowReq",
        err.toString(),
        "removeFollowReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  removeFollowRes(pReq: RemoveUnFollowClientReq): Observable<APIResponse<RemoveFollowClientRes>>
  {
    let retValue: Observable<APIResponse<RemoveFollowClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_REMOVE_FOLLOW, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in removeFollowRes",
        err.toString(),
        "removeFollowRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///search User 
  searchUserReq = (pSearchChars: string, pUserName: string, pIsFollower: boolean, pIsFollowing: boolean, pOffset: number): SearchUserClientReq =>
  {
    let retValue: SearchUserClientReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("searchUserReq....");

      const reqBody: SearchUserClientReq = {
        searchTerm: pSearchChars,
        userName: pUserName,
        isFollower: pIsFollower,
        isFollowing: pIsFollowing,
        offset: pOffset
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in searchUserReq",
        err.toString(),
        "searchUserReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  searchUserRes(pReq: SearchUserClientReq): Observable<APIResponse<SearchUserClientRes>>
  {
    let retValue: Observable<APIResponse<SearchUserClientRes>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_SEARCH_USER, pReq);
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in searchUserRes",
        err.toString(),
        "searchUserRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  // Received Follow Requests

  getReceivedFollowRes(): Observable<APIResponse<ReceivedFollowRequestClientRes>>
  {
    let retValue: Observable<APIResponse<ReceivedFollowRequestClientRes>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_RECEIVED_FOLLOW_REQ, null);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getReceivedFollowRes",
        err.toString(),
        "getReceivedFollowRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  // Sended Follow Requests

  getSentFollowRes(): Observable<APIResponse<SendedFollowRequestClientRes>>
  {
    let retValue: Observable<APIResponse<SendedFollowRequestClientRes>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_SENDED_FOLLOW_REQ, null);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getSentFollowRes",
        err.toString(),
        "getSentFollowRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  // Approve Follow Requests

  getApproveFollowReq = (pFollowRequestId: string, pIsApproved: boolean): ApproveUserFollowClientReq =>
  {
    let retValue: ApproveUserFollowClientReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getApproveFollowReq....");

      const reqBody: ApproveUserFollowClientReq = {
        followRequestId: pFollowRequestId,
        isApproved: pIsApproved
      };

      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getApproveFollowReq",
        err.toString(),
        "getApproveFollowReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };


  getApproveFollowRes(pReq: ApproveUserFollowClientReq): Observable<APIResponse<ApproveUserFollowClientRes>>
  {
    let retValue: Observable<APIResponse<ApproveUserFollowClientRes>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_APPROVE_FOLLOW_REQ, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getApproveFollowRes",
        err.toString(),
        "getApproveFollowRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  // update profile details

  getUpdateUserProfileReq(pFirstName: string, pLastName: string | null, pUserName: string, pMobileNo: string, pCountryId: string, pBio: string | null): UpdateProfileDetailsReq
  {
    let retValue: UpdateProfileDetailsReq;

    try
    {
      const reqBody: UpdateProfileDetailsReq = {
        countryId: pCountryId,
        firstName: pFirstName,
        lastName: pLastName,
        mobileNo: pMobileNo ? pMobileNo : null,
        userName: pUserName.toLowerCase(),
        bio: pBio ? pBio : null,
        latitude: BASE_CONFIG.LATITUDE,
        longitude: BASE_CONFIG.LONGITUDE
      };
      retValue = reqBody;

    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getUpdateUserProfileReq",
        err.toString(),
        "getUpdateUserProfileReq",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  getUpdateUserProfileRes(pReq: UpdateProfileDetailsReq): Observable<APIResponse<UpdateProfileDetailsRes>>
  {
    let retValue: Observable<APIResponse<UpdateProfileDetailsRes>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_UPDATE_PROFILE_DETAILS, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getUpdateUserProfileRes",
        err.toString(),
        "getUpdateUserProfileRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  // verify user
  getVerifyUserNameReq = (pFirstName: string, pLastName: string, pUserName: string, pEmailId: string): ValidateUserNameReq =>
  {
    let retValue: ValidateUserNameReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getVerifyUserNameReq....");


      const reqBody: ValidateUserNameReq = {
        firstName: pFirstName,
        lastName: pLastName,
        userName: pUserName,
        email: pEmailId,
      };
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getVerifyUserNameReq",
        err.toString(),
        "getVerifyUserNameReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getVerifyUserNameRes(pReq: ValidateUserNameReq): Observable<APIResponse<ValidateUserNameRes>>
  {
    let retValue: Observable<APIResponse<ValidateUserNameRes>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_VERIFY_USERNAME, pReq);

    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getVerifyUserNameRes",
        err.toString(),
        "getVerifyUserNameRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  // create user
  getCreateUserNameReq = (pFirstName: string, pLastName: string, pUserName: string, pEmailId: string, pPassword: string,): CreateUserReq =>
  {
    let retValue: CreateUserReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getCreateUserNameReq....");


      const reqBody: CreateUserReq = {
        firstname: pFirstName,
        lastname: pLastName,
        userName: pUserName,
        emailid: pEmailId,
        password: pPassword
      };
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getCreateUserNameReq",
        err.toString(),
        "getCreateUserNameReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getCreateUserNameRes(pReq: CreateUserReq): Observable<APIResponse<any>>
  {
    let retValue: Observable<APIResponse<any>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_CREATE_USER, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getCreateUserNameRes",
        err.toString(),
        "getCreateUserNameRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  // report user
  getReportUserReq = (pReportTo: string, pReason: number, pReasonText: string): ReportUserReq =>
  {
    let retValue: ReportUserReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getReportUserReq....");

      const reqBody: ReportUserReq = {
        reportTo: pReportTo,
        reason: pReason,
        reasonText: pReasonText
      };
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getReportUserReq",
        err.toString(),
        "getReportUserReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getReportUserRes(pReq: ReportUserReq): Observable<APIResponse<ReportUserRes>>
  {
    let retValue: Observable<APIResponse<ReportUserRes>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_REPORT_USER, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getReportUserRes",
        err.toString(),
        "getReportUserRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  // cancel user follow request
  getCancelSentFollowReq(pUserId: string): CancelSentFollowReq
  {
    let retValue: CancelSentFollowReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getCancelSentFollowReq....");

      const reqBody: CancelSentFollowReq = {
        followRequestId: pUserId
      };
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getCancelSentFollowReq",
        err.toString(),
        "getCancelSentFollowReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getCancelSentFollowRes(pReq: CancelSentFollowReq): Observable<APIResponse<CancelSentFollowRes>>
  {
    let retValue: Observable<APIResponse<CancelSentFollowRes>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_CANCEL_FOLLOW_REQUEST, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getCancelSentFollowReq",
        err.toString(),
        "getCancelSentFollowReq",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  getUploadProfilePictureRes(pReq: any): any
  {
    let retValue: any;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.APT_PICTURE_UPLOAD, pReq, false, false, true);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in getUploadProfilePictureRes",
        err.toString(),
        "getUploadProfilePictureRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  removeUserProfile()
  {
    let retValue: Observable<APIResponse<RemoveDpRes>>;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeUserProfile");

      retValue = this.httpService.makePostRequest(SERVICE_URL.APT_PICTURE_REMOVE, null);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_SERVICE,
        "error in removeUserProfile",
        err.toString(),
        "removeUserProfile",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

}
