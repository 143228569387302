import { GetUserDetailsResponse } from "social-media-api-pkg";
export class ReceivedFollowersAction
{
    static readonly type = '[follower] followers detail';
    constructor(public followerNames: string[]) { }
}

export class SentFollowingsAction
{
    static readonly type = '[follower] followings detail';
    constructor(public followingNames: string[]) { }
}

export class PeopleSuggLsAction
{
    static readonly type = '[follower] PeopleSugg detail';
    constructor(public people: GetUserDetailsResponse[]) { }
}