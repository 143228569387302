import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonCompModule } from '../../shared/common.component.module';
import { GuestUserModelComponent } from './guest-user-model.component';



@NgModule({
  declarations: [GuestUserModelComponent],
  imports: [
    CommonModule,
    CommonCompModule
  ],
  exports: [GuestUserModelComponent]
})
export class GuestUserModelModule { }
