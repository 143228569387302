import { BASE_CONFIG } from "./base-settings";

export class COMPONENT_NAME
{
  static readonly AUTH_PIN_PAGE = "auth-pin.page.ts";
  static readonly APP_COMPONENT = "app.component.ts";
  static readonly LOGIN_PAGE = "login.page.ts";
  static readonly HOME_PAGE = "home.page.ts";
  static readonly TABS_PAGE = "tabs.page.ts";
  static readonly C_INTRO_SLIDES_PAGE = "c-intro-slides.components.ts";
  static readonly USER_POSTS_PAGE = "users-posts.components.ts";
  static readonly CATEGORY_PAGE = "category.page.ts";
  static readonly C_PAGE_HEADER_PAGE = "page-header.component.ts";
  static readonly SETTINGS_PAGE = "settings.page.ts";
  static readonly EDIT_PROFILE_PAGE = "edit-profile.page.ts";
  static readonly ALL_POST_PAGE = "all-post.page.ts";
  static readonly POST_DETAIL_PAGE = "post-detail.page.ts";
  static readonly PROFILE_PAGE = "profile.page.ts";
  static readonly ADD_POST_PAGE = "add-post.page.ts";
  static readonly DATE_FUNCTIONS_PAGE = "date-functions.page.ts";
  static readonly USER_MESSAGE_PAGE = "user-message.component.ts";
  static readonly POPOVER_PAGE = "popover.component.ts";
  static readonly ADMIN_ADD_POST_PAGE = "admin-add-post.page.ts";
  static readonly ADMIN_ALL_POST_PAGE = "admin-all-post.page.ts";
  static readonly ADD_NOTIFICATION_PAGE = "add-notification.page.ts";
  static readonly DATA_TABLE_COMPONENT = "data-table.component.ts";
  static readonly BUTTON_VIEW_COMPONENT = "button-view.component.ts";
  static readonly DATE_PICKER_COMPONENT = "date-picker.component.ts";
  static readonly ADMIN_LOGIN_PAGE = "admin-login.page.ts";
  static readonly APP_SETTINGS_PAGE = "app-settings.page.ts";
  static readonly PRIVACY_PAGE = "privacy-security.page.ts";
  static readonly FOLLOWERS_LIST_PAGE = "followers-list.page.ts";
  static readonly FOLLOWING_LIST_PAGE = "following-list.page.ts";
  static readonly CHANGE_PASSWORD_PAGE = "change-password.page.ts";
  static readonly COMMENTS_PAGE = "comments.page.ts";
  static readonly ALLOW_COMMENTS_PAGE = "allow-comments.page.ts";
  static readonly BLOCK_COMMENTS_PAGE = "block-comments.page.ts";
  static readonly POST_SETTINGS_PAGE = "post-settings.page.ts";
  static readonly REPORT_MODAL_PAGE = "report-modal.page.ts";
  static readonly FOOTER_PAGE = "footer.component.ts";
  static readonly BLOCKED_USER_PAGE = "blocked-user.page.ts";
  static readonly ACCOUNT_LIST_PAGE = "account-list.component.ts";
  static readonly MUTED_USER_PAGE = "muted-user.page.ts";
  static readonly REGISTER_PAGE = "register.page.ts";
  static readonly CUSTOM_MODAL_PAGE = "custom-modal.component.ts";
  static readonly VIDEO_PLAY_PAGE = "video-play.component.ts";
  static readonly MESSAGE_DETAIL_PAGE = "message-detail.component.ts";
  static readonly FOLLOWERS_SETTINGS_PAGE = "followers-settings.page.ts";
  static readonly FOLLOWERS_SUGGESTION_PAGE = "followers-suggestion.page.ts";
  static readonly POST_ACTION_SHEET_COMPONENT = "post-action-sheet.component.ts";
  static readonly NOTIFICATION_PAGE = "notification.page.ts";
  static readonly MESSAGE_DETAIL_STATE = "message-detail.state.ts";
  static readonly ALL_POST_STATE = "all-post.state.ts";
  static readonly ABOUT_PAGE = "about.page.ts";
  static readonly HELP_PAGE = "help.page.ts";
  static readonly SIDE_MENU_PAGE = "side-menu.page.ts";
  static readonly ADMIN_PAGE = "admin.page.ts";
  static readonly FORGOT_PAGE = "forgot.page.ts";
  static readonly SEARCH_PAGE = "search.page.ts";
  static readonly FOLLOW_REQUEST_LIST = "follow-request-list.ts";
  static readonly FOLLOW_SENT_LIST = "follow-request-sent.ts";
  static readonly POST_SLIDES = "post-slides.component.ts";
  static readonly TEXT_INPUT = "text-input.component.ts";
  static readonly USER_NAME_SUGGESTION_COMPONENT = "user-name-suggestion.component.ts";
  static readonly SELECT_COMPONENT = "ion-selectable.component.ts";
  static readonly CAPTCHA_COMPONENT = "captcha.component.ts";
  static readonly FOLLOWERS_SUGGESTION_COMPONENT = "followers-suggestion.component.ts";
  static readonly SAVE_POST_PAGE = "save-posts.component.ts";
  static readonly ADD_POST_COMPONENT = "add-post.component.ts";
  static readonly USER_PROFILE_COMPONENT = "user-profile.component.ts";
  static readonly GUEST_USER_PROFILE_PAGE = "guest-user-profile.page.ts";
  static readonly ADMIN_PAGE_HEADER_COMPONENT = "guest-user-profile.page.ts";
  static readonly PUBLIC_POLICY_COMPONENT = "public-policy.component.ts";
  static readonly FEED_POST_COMPONENT = "feed-post.component.ts";
  static readonly USER_NOTIFICATIONS_PAGE = "notifications.page.ts";
  static readonly PAGE_NOT_FOUND_COMPONENT = "page-not-found.page.ts";
  static readonly POST_POLICY_COMPONENT = "policies.page.ts";
  static readonly ADD_PRODUCT_COMPONENT = "add-product.component.ts";
  static readonly ALL_PRODUCT_PAGE = "all-product.page.ts";
  static readonly A24_DATA_TABLE_COMPONENT = "a24-data-table.ts";
  static readonly POST_DURATION_COMPONENT = "duration.component.ts";
  static readonly ADD_PROFILE_PAGE = "add-profile.page.ts";
  static readonly FILE_UPLOAD_COMPONENT = "file-upload.component.ts";
  static readonly HEART_BUTTON_COMPONENT = "button-heart.component.ts";
  static readonly IMAGE_EDIT_COMPONENT = "image-edit.component.ts";
  static readonly PROFILE_UPLOAD_COMPONENT = "profile-upload.component.ts";
  static readonly DATE_SELECTOR_COMPONENT = "date-selector.component.ts";

  static readonly ACCORDION_COMPONENT = "accordion.component.ts";
  static readonly BUTTON_COMPONENT = "button.component.ts";
  static readonly CHECKBOX_COMPONENT = "check-box-component.component.ts";
  static readonly PAGINATION_LENGTH_COMPONENT = "pagination-length.component.ts";
  static readonly SEGMENT_COMPONENT = "segment.component.ts";
  static readonly MOBILE_HEADER_COMPONENT = "mobile-header.component.ts";
  static readonly WEB_HEADER_COMPONENT = "web-header.component.ts";
  static readonly GUEST_USER_MODEL_COMPONENT = "guest_user_model.component.ts";
  static readonly ADMIN_HEADER_COMPONENT = "admin-header.component.ts";
  static readonly PAGINATION_COMPONENT = "pagination.component.ts";
  static readonly IMAGE_CROP_COMPONENT = "image-crop.component.ts";
  static readonly POST_HEADER_COMPONENT = "post-header.component.ts";
  static readonly TRENDING_POSTS_COMPONENT = "trending-post.component.ts";
}
export class FILE_NAME
{
  static readonly AUTH_SERVICE = "auth.service.ts";
  static readonly AUTH_GUARD = "auth-guard.service.ts";
  static readonly FIREBASE_AUTH_SERVICE = "firebase.auth.service.ts";
  static readonly LOGOUT_SERVICE = "logout.service.ts";
  static readonly UTIL = "util.ts";
  static readonly HTTP_SERVICE = "http.service.ts";
  static readonly LOGIN_SERVICE = "login.service.ts";
  static readonly SHARE_SERVICE = "share.service.ts";
  static readonly USER_SERVICE = "user.service.ts";
  static readonly FEED_SERVICE = "feed.service.ts";
  static readonly POST_SERVICE = "post.service.ts";
  static readonly COMMENT_SERVICE = "comments.service.ts";
  static readonly SETTINGS_SERVICE = "settings.service.ts";
  static readonly PRODUCT_SERVICE = "product.service.ts";
  static readonly ADMIN_SERVICE = "admin.service.ts";
  static readonly VIDEO_PROCESSING_SERVICE = "video-processing.service.ts";
  static readonly ADMIN_AUTH_GUARD = "admin-guard.ts";
  static readonly DATE_FUNCTIONS = "date-functions.ts";
  static readonly ADD_VIEWS_SERVICE = "add.views.service.ts";
  static readonly PROFILE_FLOW_SERVICE = "profile.flow.service.ts";
  static readonly DEVICE_SERVICE_SERVICE = "devices.service.ts";
  static readonly USER_DEVICE = "profile.flow.service.ts";
  static readonly POST_FUNCTIONS = "post-functions.ts";
  static readonly IMAGE_EDITOR = "image-editor.ts";
  static readonly NOTIFICATION_SERVICE = "notification.service.ts";
  static readonly ALL_POST_SERVICE = "all-post.service.ts";
  static readonly POSTS_SERVICE = "posts.service.ts";
  static readonly ADD_POST_SERVICE = "add-post.service.ts";
  static readonly VIEWPORT_DIRECTIVE = "check-in-viewport.directive.ts";
  static readonly FROM_INTERSECTION_OBSERVER = "from-intersection-observer.ts";
}
export class PATHNAME
{
  static readonly LOGIN = '/auth/login';
  static readonly REGISTER = '/auth/register';
  static readonly FORGOT = '/auth/forgot';
  static readonly MAIN_TAB = '/tabs/main';
  static readonly POST = '/post';
  static readonly CATEGORY = '/category';
  static readonly PROFILE = '/profile';
  static readonly SETTINGS = '/settings';
  static readonly EDIT_PROFILE = '/settings/edit-profile';
  static readonly FOLLOWERS_SETTINGS = '/settings/followers-settings';
  static readonly NOTIFICATION = '/settings/notification';
  static readonly POST_SETTINGS = '/settings/notification/post-settings';
  static readonly PRIVACY = '/settings/privacy';
  static readonly BLOCKED_ACCTS = '/settings/privacy/blocked';
  static readonly MUTED_ACCTS = '/settings/privacy/muted';
  static readonly APP_SETTINGS = '/settings/app';
  static readonly HELP = '/settings/help';
  static readonly ABOUT = '/settings/about';
  static readonly POST_DETAIL = '/post/detail';
  static readonly ADD_POST = '/post/add';
  static readonly FOLLOWERS_LIST = '/profile/followers';
  static readonly FOLLOWING_LIST = '/profile/following';
  static readonly CHANGE_PWD = '/settings/privacy/change-password';
  static readonly COMMENTS = '/settings/comments';
  static readonly ALLOW_COMMENTS = '/settings/comments/allow';
  static readonly BLOCK_COMMENTS = '/settings/comments/block';
  static readonly FOLLOWER_SUGGESTION = '/follower-suggestion';
  static readonly FOLLOW_REQUEST = '/profile/follow-requests';
  static readonly FOLLOW_SENT = '/profile/follow-sent';
  static readonly SAMPLE_POST_DETAIL = '/post/sample/detail';
  static readonly USER_NOTIFICATION = '/notifications';
  static readonly ADD_PROFILE = '/profile/add';
  static readonly SAVED_POSTS = '/post/bookmark';
  static readonly NO_NETWORK = '/no-network';

  //admin
  static readonly ADMIN = 'admin';
  static readonly ADMIN_LOGIN = '/admin/login';
  static readonly ADMIN_ADD_POST = '/admin/post/add';
  static readonly ADMIN_ALL_POST = '/admin/post/all';
  static readonly ADMIN_ALL_NOTIFICATION = '/admin/notification/all';
  static readonly ADMIN_ADD_NOTIFICATION = '/admin/notification/add';


  static readonly PUBLIC_POLICY = '/policies';
}

export class DATE_TIME_FORMAT
{
  static API_DATE_FORMAT = "yyyy-MM-dd";
  static DATE_WITH_TIME = "dd-MMM-yyyy hh:mm";
  static COMMON_DATE_FORMAT = "DD MMM YYYY";
}


export class SERVICE_URL
{
  static readonly APP_AUTH_URL = 'api/auth/firebase';
  ///feed
  static readonly API_GET_POSTIDS = 'api/post/feed/postIds';
  static readonly API_GET_POSTS = 'api/post/feed/getPosts';
  static readonly API_SEARCH_POST = 'api/post/feed/searchPost';
  static readonly API_GET_USER_POSTIDS = 'api/post/byUser/getIds';

  ///post
  static readonly API_ADD_POST = 'api/post/add';
  static readonly API_ADD_LIKE = 'api/post/addLike';
  static readonly API_REMOVE_LIKE = 'api/post/removeLike';
  static readonly API_DISLIKE = 'api/post/dislike';
  static readonly API_REMOVE_DISLIKE = 'api/post/removeDislike';
  static readonly API_ADD_VIEWS = 'api/post/views/add';
  static readonly API_ADD_REPORT = 'api/post/report/add';
  static readonly API_SHARE = 'api/post/share';
  static readonly API_TRENDING_POST = 'api/post/feed/trending';
  static readonly API_GET_CATEGORY = 'api/static/groups/get';
  static readonly API_GET_TAGS = 'api/post/tags';
  static readonly API_SAVE_POST = 'api/post/save';
  static readonly API_REMOVE_SAVE_POST = 'api/post/save/remove';
  static readonly API_GET_SAVE_POST = 'api/post/save/get';
  static readonly API_DELETE_POST = 'api/post/status/delete';
  static readonly API_UPDATE_POST_STATUS = 'api/post/update/status';
  static readonly API_INCREASE_VIEW_COUNT = 'api/post/views/count/increase';
  static readonly API_POST_DELETE_IMAGE = 'api/post/image/delete';
  static readonly API_REMOVE_PRODUCT_FROM_POST = 'api/product/remove/from/post';

  ///comments
  static readonly API_ADD_COMMENT = 'api/post/comment/add';
  static readonly API_ADD_COMMENT_LIKE = 'api/post/comment/like';
  static readonly API_REMOVE_COMMENT_LIKE = 'api/post/comment/like/delete';
  static readonly API_REPORT_COMMENT = 'api/post/comment/report';
  static readonly API_ADD_COMMENT_DISLIKE = 'api/post/comment/dislike';
  static readonly API_REMOVE_COMMENT_DISLIKE = 'api/post/comment/dislike/delete';
  static readonly API_GET_COMMENT = 'api/post/comment/get';
  static readonly API_UPDATE_COMMENT = 'api/post/comment/update';
  static readonly API_DELETE_COMMENT = 'api/post/comment/delete';

  ///user
  static readonly API_UPDATE_PREFERENCE = 'api/user/preference/update';
  static readonly API_FOLLOW_USER = 'api/user/follow';
  static readonly API_UNFOLLOW_USER = 'api/user/unfollow';
  static readonly API_GET_PREFERENCE = 'api/user/preference/get';
  static readonly API_GET_FOLLOWERS_LIST = 'api/user/follower/get';
  static readonly API_GET_FOLLOWING_LIST = 'api/user/following/get';
  static readonly API_GET_FOLLOWERS_SUGGESTION_LIST = 'api/user/followerSuggestion/get';
  static readonly API_IS_USER_FOLLOWED = 'api/user/follower/isFollowed';
  static readonly API_UPDATE_USER_BIO = 'api/user/bio/update';
  static readonly API_GET_USER_DETAILS = 'api/user/details';
  static readonly API_GET_PROFILE_DETAILS = 'api/user/profileDetails/get';
  static readonly API_BLOCK_USER = 'api/user/block';
  static readonly API_UNBLOCK_USER = 'api/user/unblock';
  static readonly API_GET_BLOCKED_LIST = 'api/user/block/list';
  static readonly API_MUTE_USER = 'api/user/mute';
  static readonly API_UNMUTE_USER = 'api/user/unmute';
  static readonly API_GET_MUTEED_LIST = 'api/user/mute/list';
  static readonly API_REMOVE_FOLLOW = 'api/user/removefollow';
  static readonly API_CHANGE_PWD = 'api/user/changepassword';
  static readonly API_SEARCH_USER = 'api/user/search';
  static readonly API_RECEIVED_FOLLOW_REQ = 'api/user/followRequest/received';
  static readonly API_SENDED_FOLLOW_REQ = 'api/user/followRequest/sended';
  static readonly API_APPROVE_FOLLOW_REQ = 'api/user/followRequest/approve';
  static readonly API_UPDATE_PROFILE_DETAILS = 'api/user/profileDetails/update';
  static readonly API_VERIFY_USERNAME = 'api/user/userName/verify';
  static readonly API_CREATE_USER = 'api/userManagement/createUser';
  static readonly API_REPORT_USER = 'api/user/report';
  static readonly API_CANCEL_FOLLOW_REQUEST = 'api/user/followRequest/cancel';

  ///product
  static readonly API_GET_PRODUCT = 'api/product/get';
  static readonly API_GET_ALL_PRODUCT = 'api/product/get/all';

  ///settings
  static readonly API_GET_PRIVACY = 'api/settings/privacy/get';
  static readonly API_GET_NOTIFICATION = 'api/settings/notification/get';
  static readonly API_UPDATE_NOTIFICATION = 'api/settings/notification/update';
  static readonly API_UPDATE_PRIVACY = 'api/settings/privacy/update';
  static readonly API_GET_CURRENCY = 'api/static/currency/get';
  static readonly API_GET_COUNTRY = 'api/static/countries/get';
  static readonly API_GET_USER_SETTINGS = 'api/user/getUserSettings';
  static readonly API_UPDATE_USER_SETTINGS = 'api/user/updateUserSettings';
  static readonly API_COMMENTS_FROM_SETTINGS = 'api/settings/privacy/commentsFrom';

  ///admin
  static readonly API_LOGIN = 'api/auth/signin';
  static readonly API_GET_ALL_POST_IDS = 'api/admin/post/feed/postIds';
  static readonly API_GET_ALL_POSTS = 'api/admin/post/get';
  static readonly API_ADMIN_DELETE_POST = 'api/admin/post/status/delete';
  static readonly API_GET_CAPTCHA = 'api/utils/getCaptcha';
  static readonly API_ADMIN_GET_ALL_USER = 'api/admin/user/get/all';

  ///static
  static readonly API_GET_LANGUAGE = 'api/static/language/get';

  ///videoprocessing
  static readonly APT_VIDEO_PROCESSING = 'api/video/upload';
  static readonly APT_VIDEO_REMOVE = 'api/video/delete';

  static readonly APT_PICTURE_UPLOAD = 'api/user/profile/picture/upload';
  static readonly APT_PICTURE_REMOVE = 'api/user/profile/picture/remove';


  static readonly RENEW_JWT = 'api/auth/renew';
  static readonly API_LOGOUT = 'api/auth/logout';

  // user device
  static readonly API_GET_ALL_DEVICE = 'api/userDevice/getAll';
  static readonly API_ADD_DEVICE = 'api/userDevice/add';
  // static readonly API_GET_USER_SETTINGS = 'api/user/getUserSettings';

  // notifications
  static readonly API_GET_ALL_NOTIFICATION = 'api/user/notification/get';
  static readonly API_CLEAR_NOTIFICATIONS = 'api/user/notification/clear';



}

export class ROUTER_PARAM
{
  static readonly PARAM = "param";
  static readonly ONLY_MODE_PARAM = "?mode=";
  static readonly FROM_PARAM = "?from=";
  static readonly MODE_PARAM = "&mode=";

  // modes
  static readonly UPDATE_FLOW = "update";
}

export class FB_AUTH_TYPE
{
  static SIGNIN_WITH_POPUP = "signInWithPopup";
  static SIGNIN_WITH_REDIRECT = "signInWithRedirect";
  static SIGNIN_WITH_EMAIL_AND_PW = "signInWithEmailAndPassword";

}
export class PLATFORM
{
  static android = "android";
  static ios = "ios";
  static web = 'web';
  static chrome = 'chrome';
  static windows = 'windows';
  static blackberry = 'blackberry';
  static firefox = 'firefox';
}
export class ORG_ID
{
  static orgId = "cc3e9168-1f2b-11eb-82d3-cf638f6347ce";
}
export class AUTH_KEY
{
  static readonly ok = "OK";
  static readonly error = "ERROR";
  static readonly existingUser = "existinguser-norefresh";
  static readonly tokenExpired = "token-expired";
  static readonly newUserRefresh = "newuser-refresh";
}

export class SOCIAL_LOGIN_TYPE
{
  static readonly GOOGLE = "google";
  static readonly TWITTER = "twitter";
  static readonly FACEBOOK = "facebook";
}

export class HTTP_CODES
{
  static ERROR_JWT = "jwt";
  static ERROR_JWT_01 = "JWT01";
  static ERROR_JWT_02 = "JWT02";
  static ERROR_PER = "PER01";
  static ERROR_AUT2 = "AUT2";

  static SUBSCRIPTION_FAILED = "start-failed";

  static ERROR_INVOICES_PAID = "PST03";

  static readonly SUCCESS = 200;
  static readonly ERROR = 400;
  static readonly SUCCESS_STR = "Success";
  static readonly ERROR_STR = "Error";
  static readonly ERROR_NET = "Connectivity";

}

export enum FirebaseSignInProvider
{
  CUSTOM = 'custom',
  EMAIL = 'email',
  PASSWORD = 'password',
  FIREBASE = 'firebase',
  PHONE = 'phone',
  ANONYMOUS = 'anonymous',
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  GITHUB = 'github.com',
  TWITTER = 'twitter.com',
  MICROSOFT = 'microsoft.com',
  APPLE = 'apple.com'
}


export class ShareVia
{
  static Self_share = 0;
  static WhatsApp = 1;
  static Facebook = 2;
  static Twitter = 3;
  static Instagram = 4;
  static Telegram = 5;
  static Signal = 6;
  static Imo = 7;
  static Sms = 8;
  static Copied = 9;
  static GMail = 10;
  static Others = 11;
}

export enum ReportReason
{
  Sexual = 1,
  Inappropriate = 2,
  CopyRight = 3
}

export enum PostType
{
  Submitted = "Submitted",
  Draft = "Draft"
}


export enum EcommerceWebsite
{
  Amazon = "AMZ",
  Flipkart = "FKT",
  Walmart = "WMRT",
  Others = "OTHERS"
}

export enum Unit
{
  Pieces = "pcs",
  Kilogram = "kg",
  Gram = "g",
  Meter = "m",
  Centimeter = "cm",
  Litre = "l",
  Millilitre = "ml",
  Teaspoon = 'tsp'
}

export enum PostChanges
{
  Like = 1,
  Unlike = 2,
  Added_Comment = 3,
  Removed_Comment = 4,
  Share = 5,
  Save = 6,
  Remove_saved = 7
}


export enum CommentsChanges
{
  Like = 1,
  Unlike = 2,
  Reported = 3,
}


export enum ACTIONS
{
  ADD = 'Add',
  EDIT = 'Edit',
  DELETE = 'Delete'
}

export enum NotificationAction
{
  OpenApp = 1,
  url = 2,
}

export enum NotificationSchedule
{
  SendNow = 1,
  SendLater = 2,
}

export enum DaysType
{
  Days = 1,
  Date = 2,
}

export enum PostStatus
{
  All = -1,
  Draft = 0,
  Published = 1,
  PendingForApproval = 2,
  Rejected = 3,
  Unpublished = 4,
  Deleted = 5
}

export enum UserRole
{
  Admin = 'System.Admin',
  User = 'App.User',
}

export enum ALERT_MODAL_STATUS
{
  SUCCESS = "success",
  WARNING = "warning",
  FAIL = "danger",
  DEFAULT = 'dark'
}
export enum AllowCommentsFrom
{
  Everyone = 0,
  PeopleIFollow = 1,
  PeopleMyFollowers = 2
}
export enum NotificationLikeStatus
{
  off = 0,
  PeopleIFollow = 1,
  everyone = 2
}
export const enum NotificationPostCommentStatus
{
  off = 0,
  PeopleIFollow = 1,
  everyone = 2
}
export enum NotificationCommentLikeStatus
{
  off = 0,
  PeopleIFollow = 1,
  everyone = 2
}
export enum NotificationFirstPostStatus
{
  off = 0,
  PeopleIFollow = 1,
  everyone = 2
}
export enum NotificationNewFollowerStatus
{
  off = 0,
  on = 1
}
export enum NotificationAcceptFollowStatus
{
  off = 0,
  on = 1
}
export enum NotificationAccountSuggestion
{
  off = 0,
  on = 1
}
export enum AppTheme
{
  Dark = '1',
  Light = '2'
}

export enum UserRelationshipStatus
{
  nil = 0,
  requested = 1,
  followed = 2,
  blocked = 3
}

export enum PostLinkType
{
  video = 'Video',
  image = 'Image'
}

export class LINKS
{

  // static DYNAMIC_BASE_URL = BASE_CONFIG.SHARE_URL + "/post/detail?";
  static DYNAMIC_BASE_URL = BASE_CONFIG.SHARE_URL + "?";
  static APP_LINK = BASE_CONFIG.BASE_URL;                        // TODO: need to change app URL
  // static GUEST_SIGNIN_URL = "https://tinq-staging.ascent24.io/"; // TODO: need to change this URL
  static GUEST_SIGNIN_URL = "https://tinq.ascent24.io/"; // TODO: need to change this URL
  static SHARE_DATA_URL = "http://tinq.ascent24.io/assets/tinq/home.html";
}

export enum DefaultImages
{
  userProfile = '/assets/svg/default_avatar.svg',
  videoImage = '/assets/svg/play_button.svg',
}

export enum BaseSettings
{
  TableLsPerPage = 10,
  searchTextLength = 3,
  showMinPostsCount = 4,
  minCategorySelection = 3,
  showUsersCount = 5,       // number of users in PeopleSuggestion component horizontal view
  showPeopleSuggCount = 10, // show PeopleSuggestion component after every 10 posts
  loadMoreUsersCount = 20, // more user list count in PeopleSuggestion Page
  userPostCount = 15, // profile page posts count
  slicePostDetails = 10, // post detail page posts count
  viewedPostsCount = 10, // post detail page posts count
}

export enum ButtonsAction
{
  Block = 'Block',
  Confirm = 'Confirm',
  Decline = 'Decline',
  Follow = 'Follow',
  Mute = 'Mute',
  Remove = 'Remove',
  Report = 'Report',
  Unblock = 'Unblock',
  UnFollow = 'UnFollow',
  Unmute = 'Unmute',
  CancelRequest = 'Cancel Request',
}

export enum PageNames
{
  Profile = 'Profile',
  Followers = 'Followers',
  Following = 'Following',
  Blocked = 'Blocked',
  Muted = 'Muted',
  Post = 'Post',
  RequestSent = 'Sent',
  RequestReceived = 'Received',
  Privacy = 'Privacy',
  Search = 'Search',
  Feed = 'Feed',
  Trending = 'Trending',
  Notification = 'Notification',
  Favorite = 'Favorite',
  Details = 'Details',
}

export enum StoreUpdateTypes
{
  Add = 'Add',
  Reset = 'Reset',
  Remove = 'Remove',

}

export enum PostFunctionTypes
{
  Like = 'Like',
  Unlike = 'Unlike',
  Comment = 'Comment',
  AddFavorite = 'AddFavorite',
  RemoveFavorite = 'RemoveFavorite',

}

export enum SuggestedCategories
{
  Science = 'science',
  Music = 'music',
  Gifts = 'gifts',
  Lifestyle = 'lifestyle'
}

export enum DefaultTitle
{
  title = 'My Awesome #DIY #Science'
}

export class TopCountries
{
  // Australia, Canada, China, Germany, India, Indonesia, Japan, Malaysia, New Zealand, Pakistan, Singapore, Switzerland, United Kingdom, United States
  static readonly countryLs = ['AU', 'CA', 'CN', 'DE', 'IN', 'ID', 'JP', 'MY', 'NZ', 'PK', 'SG', 'CH', 'UK', 'US'];
}

export class TopCurrencies
{
  // Australia, Canada, China, Germany, India, Indonesia, Japan, Malaysia, New Zealand, Pakistan, Singapore, Switzerland, United Kingdom, United States
  static readonly currencyLs = ['AUD', 'CAD', 'CNY', 'EUR', 'INR', 'IDR', 'JPY', 'MYR', 'NZD', 'PKR', 'SGD', 'CHF', 'GBP', 'USD'];
}

export enum AgeGroupTags
{
  child = "5-13",
  teen = "13-18",
  adult = "18-60"
}

export enum FieldOfWork
{
  education = "education"
}

export enum NotificationNewsLetter
{
  off = 0,
  on = 1
}

export enum NotificationActionType
{
  like = 'like',
  command = 'command',
  commandLike = 'commandLike',
  newFollow = 'newFollow',
  followAccepted = 'followAccepted',
  post = 'post',
  report = 'report'
}

export enum ProductUrl
{
  refLinkParam = 'ref=tinq'
}

export enum FilterType 
{
  CheckBoxType = "Check-Box Type",
  MultiDropdownType = "Multi-Dropdown Type",
  SingleDropdownType = "Single-Dropdown Type",
  SegmentType = "Segment Type"
}

export enum APIError
{
  errorPrefixText = 'Firebase: '
}

export enum NotificationListType
{
  today = 'today',
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek'
}

export enum ProductItems
{
  // ecom Id
  Qty = 'qty',

  // link Id
  URL = 'url',
  Source = 'source',
  Price = 'price',

  // product Id
  Title = 'title',
  Unit = 'unit',
  Image = 'image',
}

export enum IntersectionStatus
{
  Visible = 'Visible',
  Pending = 'Pending',
  NotVisible = 'NotVisible',
}

export enum FeatureTypes
{
  Search = 0,
  AddPost = 1,
  Profile = 2,
  ViewChange = 3,
  HeaderDummy = 4,
  Info = 0,
  Product = 1,
  Footer = 2,
  PostDetailDummy = 3,
  PostClick = 0,
  Bookmark = 1,
  PostDummy = 2,
}

export enum PASSWORD_TYPE
{
  PASSWORD = "password",
  TEXT = "text",
}

export enum GENDER
{
  Male = 'male',
  Female = 'female',
  PreferNotToSay = 'preferNotToSay'
}
