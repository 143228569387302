import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { GetPostCommentClientRes } from 'social-media-api';
import { CommentsService } from '../../services/comments/comments.service';
import { LoggerService } from '../../services/logger/logger.service';
import { APIResponse } from '../../services/service.model';
// import { MessageModel } from '../../tabs/messages/messages.model';
import { getErrorMessage, VALIDATE_SUCCESS_RES } from '../../util/apiValidate';
import { CommentsChanges, COMPONENT_NAME } from '../../util/constants';
import { UtilFunctions } from './../../util/util';
import { MessageDetailActions, MessageDraftAction, MessageSendActions } from './message-detail.actions';
import { MessageDetailPageStateModel, MessageDraft } from './message-detail.model';

export const initialState: MessageDetailPageStateModel = {
  commentsLs: [],
  comments: null,
  offset: null,
  isLoading: false,
  nextMsgOffset: false,
  postId: null,
  draftMsgs: []
};

export const STATE_TOKEN = new StateToken<MessageDetailPageStateModel>('messageDetail');

@State({
  name: STATE_TOKEN,
  defaults: initialState,
})
@Injectable()
export class MessageDetailPageState
{
  constructor(private commentService: CommentsService, private util: UtilFunctions, private logger: LoggerService) { }

  @Action(MessageDetailActions.FetchMessageDetail)
  fetchMessages(ctx: StateContext<MessageDetailPageStateModel>, action: MessageDetailActions.FetchMessageDetail)
  {
    const state = ctx.getState();

    if (!state.commentsLs || action.isRefresh)
    {
      ctx.patchState({
        ...state,
        // activePage: 1,
        isLoading: true,

      });
    }

    let getCommentReq$ = this.commentService.getCommentsReq(action.postId, action.offset);
    let getCommentRes$ = this.commentService.getCommentsRes(getCommentReq$);
    getCommentRes$.subscribe({
      next: (res: APIResponse<GetPostCommentClientRes>) =>
      {
        if (VALIDATE_SUCCESS_RES(res))
        {
          // ctx.dispatch(new MessageDetailActions.FetchMessageDetailSuccess(res.body.data.postComments, action.isRefresh));
          let sortedCmdLs = res.body.data.postCommand.sort((a, b) => a.id < b.id ? 1 : -1);
          let nextOffset = res.body.data.nextOffSet ? true : false;
          ctx.dispatch(new MessageDetailActions.FetchMessageDetailSuccess(sortedCmdLs, nextOffset, action.isRefresh));
        }
        else
        {
          const error = getErrorMessage(res);

          let logRequest = this.util.createLogRequest(COMPONENT_NAME.MESSAGE_DETAIL_STATE, "fetchMessages", error.error[0]);
          this.logger.getResponse(logRequest);

          const isJwt = this.util.handleJWT(error);
          if (!isJwt)
          {
            let alertValue = this.util.getErrorAlertValue(error);
          }
          // this.util.toShowToast(JSON.stringify(error));
        }
      },
      error: (err: HttpErrorResponse) =>
      {

      },
      // complete: () => console.info('complete') 
    });

    // return this.apiService.get(action.api).pipe(
    //   tap((messageDetail: MessageModel) =>
    //     ctx.dispatch(new MessageDetailActions.FetchMessageDetailSuccess(action.messageId, messageDetail)),
    //   ),
    //   catchError(() => ctx.dispatch(new MessageDetailActions.FetchMessageDetailFail('Error! Please try again.'))),
    // );
  }

  @Action(MessageDetailActions.FetchMessageDetailSuccess)
  fetchMessagesSuccess(
    ctx: StateContext<MessageDetailPageStateModel>,
    action: MessageDetailActions.FetchMessageDetailSuccess,
  )
  {
    const state = ctx.getState();
    const stateObj = this.util.cloneDeep(state);
    if (action)
    {
      if (stateObj.commentsLs && stateObj.commentsLs.length > 0)
      {
        if (action.isRefresh)
        {
          stateObj.commentsLs = action.postcomments.concat(stateObj.commentsLs);
        } else
        {
          stateObj.commentsLs = stateObj.commentsLs.concat(action.postcomments);
        }
      } else
      {
        stateObj.commentsLs = [];
        if (action.isRefresh)
        {
          stateObj.commentsLs = action.postcomments.concat(stateObj.commentsLs);
        } else
        {
          stateObj.commentsLs = stateObj.commentsLs.concat(action.postcomments);
        }
      }
      stateObj.comments = action.postcomments;
    }
    ctx.patchState({
      commentsLs: stateObj.commentsLs,
      comments: stateObj.comments,
      offset: stateObj.offset,
      nextMsgOffset: action.nextOffset,
      isLoading: false,
    });
  }

  // @Action(MessageDetailActions.FetchMessageDetailFail)
  // fetchMessagesFail(
  //   ctx: StateContext<MessageDetailPageStateModel>,
  //   action: MessageDetailActions.FetchMessageDetailFail,
  // ) {
  //   ctx.patchState({
  //     isLoading: false,
  //     isSuccess: false,
  //     isFailed: true,
  //     error: action.error,
  //   });
  // }

  @Action(MessageDetailActions.FetchMessageOffset)
  fetchCommentsOffset(ctx: StateContext<MessageDetailPageStateModel>, action: MessageDetailActions.FetchMessageOffset)
  {
    ctx.patchState({
      offset: action.offset
    });
  }

  @Action(MessageDetailActions.FetchMessageComments)
  fetchCommentsPostId(ctx: StateContext<MessageDetailPageStateModel>, action: MessageDetailActions.FetchMessageComments)
  {
    ctx.patchState({
      commentsLs: action.comments
    });
  }

  @Action(MessageDetailActions.FetchMessagePostID)
  fetchComments(ctx: StateContext<MessageDetailPageStateModel>, action: MessageDetailActions.FetchMessagePostID)
  {
    ctx.patchState({
      postId: action.postId
    });
  }

  @Action(MessageSendActions.Send)
  sendMessage(ctx: StateContext<MessageDetailPageStateModel>, action: MessageSendActions.Send)
  {
    let msgDetail = {};
    msgDetail['comment'] = action.messageDetail.comment;
    msgDetail['comment_date'] = new Date();
    msgDetail['commented_user_dp_url'] = action.messageDetail.userDp;
    msgDetail['commented_user_first_name'] = action.messageDetail.userFirstName;
    msgDetail['commented_user_id'] = action.messageDetail.userId;
    msgDetail['commented_user_last_name'] = action.messageDetail.userLastName;
    msgDetail['dislike'] = "0";
    msgDetail['id'] = action.messageId;
    msgDetail['is_my_comment'] = true;
    msgDetail['liked_status'] = 0;
    msgDetail['likes'] = "0";
    msgDetail['report'] = 0;
    msgDetail['status'] = 1;
    const state = ctx.getState();
    const messages = this.util.cloneDeep(state.commentsLs);

    if (action.isUpdate)
    {
      for (let i = 0; i < messages.length; i++)
      {
        if (messages[i].id == action.messageId)
        {
          messages[i].comment = action.messageDetail.comment;
        }
      }
    } else
    {
      messages.push(msgDetail);
    }

    ctx.patchState({
      commentsLs: messages
    });

  }

  @Action(MessageSendActions.Delete)
  deleteMessage(ctx: StateContext<MessageDetailPageStateModel>, action: MessageSendActions.Delete)
  {

    const state = ctx.getState();
    const messages = this.util.cloneDeep(state.commentsLs);

    const index: number = messages.findIndex(x => x.id === action.message.id);
    if (index !== -1)
    {
      messages.splice(index, 1);
    }


    ctx.patchState({
      commentsLs: messages
    });

  }

  @Action(MessageDetailActions.MessageChangesActions)
  commentsChanges(ctx: StateContext<MessageDetailPageStateModel>, action: MessageDetailActions.MessageChangesActions)
  {

    const state = ctx.getState();
    let commentsLs = this.util.cloneDeep(state.commentsLs);
    for (let i = 0; i < commentsLs.length; i++)
    {
      if (action.message.id == commentsLs[i].id)
      {
        if (action.changes == CommentsChanges.Like)
        {
          commentsLs[i].likes = Number(commentsLs[i].likes) + 1;
          commentsLs[i].liked_status = 1;
        }
        if (action.changes == CommentsChanges.Unlike)
        {
          commentsLs[i].likes = Number(commentsLs[i].likes) - 1;
          commentsLs[i].liked_status = 0;
        }
        if (action.changes == CommentsChanges.Reported)
        {
          commentsLs[i].report = 1;
        }

      }
    }
    ctx.patchState({
      commentsLs: commentsLs
    });
  }

  @Action(MessageDetailActions.Reset)
  resetMessages(ctx: StateContext<MessageDetailPageStateModel>)
  {
    ctx.setState(initialState);
  }

  @Action(MessageDraftAction.Draft)
  storeDraftMessages(ctx: StateContext<MessageDetailPageStateModel>, action: MessageDraftAction.Draft)
  {
    const state = ctx.getState();

    let draftMsgs: MessageDraft[] = this.util.cloneDeep(state.draftMsgs);

    const foundInd = draftMsgs.findIndex((cmd: MessageDraft) => cmd.postId == action.message.postId);

    if (foundInd !== -1)
    {
      draftMsgs[foundInd].msg = action.message.msg;
    }
    else
    {
      draftMsgs.push(action.message);
    }

    ctx.patchState({ draftMsgs: draftMsgs });
  }

  @Action(MessageDraftAction.Remove)
  async removeDraftMessage(ctx: StateContext<MessageDetailPageStateModel>, action: MessageDraftAction.Remove)
  {
    const state = ctx.getState();

    let draftMsgs: MessageDraft[] = await this.util.cloneDeep(state.draftMsgs);

    const foundInd = draftMsgs.length > 0 ? draftMsgs.findIndex((cmd: MessageDraft) => cmd.postId == action.postId) : -1;

    if (foundInd !== -1)
    {
      draftMsgs.splice(foundInd, 1);
    }

    ctx.patchState({ draftMsgs: draftMsgs });
  }

  @Action(MessageDraftAction.Reset)
  resetDraftMessages(ctx: StateContext<MessageDetailPageStateModel>)
  {
    const draftMsgs = [];
    ctx.patchState({ draftMsgs: draftMsgs });
  }

}
