import { BASE_CONFIG } from './../../util/base-settings';
import { Injectable } from '@angular/core';
import { FILE_NAME, SERVICE_URL } from '../../util/constants';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { Observable } from 'rxjs';
import { APIResponse } from '../service.model';
import { AuthRequest, AuthResponse } from '../auth/auth.model';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService
{

  constructor(private logger: LoggerService, private HttpPost: HttpService) { }

  apiGetAuthReq(pToken: string): AuthRequest
  {
    let retValue: AuthRequest;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("apiGetAuthReq");
      const hVal = "Bearer " + pToken;

      retValue = {
        "token": hVal,
        "orgId": BASE_CONFIG.orgId
      };

    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.FIREBASE_AUTH_SERVICE,
        "error in apiGetAuthReq",
        err.toString(),
        "apiGetAuthReq",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  };

  apiGetAuthRes(pAuthReq: any): Observable<APIResponse<AuthResponse>>
  {
    let retValue: Observable<APIResponse<AuthResponse>>;

    try
    {
      if (pAuthReq != null)
      {
        retValue = this.HttpPost.makePostRequest(SERVICE_URL.APP_AUTH_URL, pAuthReq);
      } else
      {
        throw "invalid request";
      }
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.FIREBASE_AUTH_SERVICE,
        "error in apiGetAuthRes",
        err.toString(),
        "apiGetAuthRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }
}
