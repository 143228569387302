import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { UtilFunctions } from '../../util/util';
import { SettingsModel } from './../../model/settings.model';
import {
    CategorySelectAction,
    DetailsGuideFeatureAction,
    FollowSuggSelectAction,
    GeoLocationAction,
    IntroVisitAction,
    IsUserLoggedInAction,
    PeopleSuggReqFromAction,
    PostGuideFeatureAction,
    ProfileVisitAction
} from './settings.action';

export const settingsInitState: SettingsModel = {
    isIntroVisited: null,
    isProfileVisited: true,
    isCategorySelected: null,
    isFollowSuggSelected: null,
    isUserLoggedIn: false,
    reqFrom: null,
    location: null,
    featureShownDetail: {
        isPostGuideShown: false,
        isDetailsGuideShown: false
    }
};

export const SETTINGS_TOKEN = new StateToken<SettingsModel>('basicSettings');

@State({
    name: SETTINGS_TOKEN,
    defaults: settingsInitState,
})

@Injectable()
export class SettingsState {
    constructor(
        private util: UtilFunctions
    ) { }

    @Action(IntroVisitAction)
    updateIntroVisited(ctx: StateContext<SettingsModel>, action: IntroVisitAction) {
        ctx.patchState({ isIntroVisited: action.isIntroVisited });
    }

    @Action(CategorySelectAction)
    updateCategorySel(ctx: StateContext<SettingsModel>, action: CategorySelectAction) {
        ctx.patchState({ isCategorySelected: action.categorySelect });
    }

    @Action(FollowSuggSelectAction)
    updateFollowSugg(ctx: StateContext<SettingsModel>, action: FollowSuggSelectAction) {
        ctx.patchState({ isFollowSuggSelected: action.followSuggSelect });
    }

    @Action(ProfileVisitAction)
    updateProfileVisited(ctx: StateContext<SettingsModel>, action: ProfileVisitAction) {
        ctx.patchState({ isProfileVisited: action.isProfileVisited });
    }

    @Action(IsUserLoggedInAction)
    updateIsUserLogIn(ctx: StateContext<SettingsModel>, action: IsUserLoggedInAction) {
        ctx.patchState({ isUserLoggedIn: action.isUserLoggedIn });
    }

    @Action(PeopleSuggReqFromAction)
    updatePeopleSuggReqFrom(ctx: StateContext<SettingsModel>, action: PeopleSuggReqFromAction) {
        ctx.patchState({ reqFrom: action.reqFrom });
    }

    @Action(GeoLocationAction)
    getDeviceLocation(ctx: StateContext<SettingsModel>, action: GeoLocationAction) {
        ctx.patchState({ location: action.location });
    }

    @Action(PostGuideFeatureAction)
    updatePostGuideFeature(ctx: StateContext<SettingsModel>, action: PostGuideFeatureAction) {
        const state = ctx.getState();
        let featureDetails = this.util.cloneDeep(state.featureShownDetail);

        featureDetails.isPostGuideShown = action.isPostGuideShown;
        ctx.patchState({ featureShownDetail: featureDetails });
    }

    @Action(DetailsGuideFeatureAction)
    updateDetailsGuideFeature(ctx: StateContext<SettingsModel>, action: DetailsGuideFeatureAction) {
        const state = ctx.getState();
        let featureDetails = state.featureShownDetail;

        featureDetails.isDetailsGuideShown = action.isDetailGuideShown;
        ctx.patchState({ featureShownDetail: featureDetails });
    }
}