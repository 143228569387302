import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProfileDetails } from "social-media-api-pkg";
import { AppUserProfileDetailAction, IsAppUserAction, UpdateVisitedPageAction, UserProfileDetailAction } from '../../pages/profile/profile.actions';
import { ProfileDetail, VisitedPageDetail } from '../../pages/profile/profile.modal';
import { getUserVisitedPage } from '../../pages/profile/profile.selectors';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, PageNames, PATHNAME, StoreUpdateTypes } from '../../util/constants';
import { PostFunctions } from '../../util/post-functions';
import { UtilFunctions } from '../../util/util';
import { LoggerService } from '../logger/logger.service';


@Injectable({
  providedIn: 'root'
})
export class ProfileFlowService
{

  canProceed: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  subscriptions: Subscription[] = [];
  postId: string = null;

  constructor(
    private postFunctions: PostFunctions,
    private logger: LoggerService,
    private util: UtilFunctions,
    private store: Store,

  ) { }


  addUserName(pUserName: string, pIsAppUser: boolean, pPageName: string, isSkipApiCall: boolean = false, pPostId: string = '')
  {
    let userObj: VisitedPageDetail = {
      isAppUser: pIsAppUser,
      userName: pUserName,
      pageName: pPageName,
      postId: pPostId ? pPostId : null
    };
    this.postId = pPostId;
    this.store.dispatch(new UpdateVisitedPageAction(StoreUpdateTypes.Add, userObj));

    if (!isSkipApiCall)
    {
      if (pIsAppUser)
      {
        // let userProfileStore = this.store.select(getAppUserProfile()).subscribe(profile =>
        // {
        //   if (profile)
        //   {
        //     this.store.dispatch(new AppUserProfileDetailAction(profile));
        //     this.store.dispatch(new IsAppUserAction(true));

        //     this.navToPage(profile.userName, pPageName);
        //   }
        //   else
        //   {
        //     this.getUserProfile(pPageName);
        //   }
        // });

        // userProfileStore.unsubscribe();
        this.getUserProfile(pPageName);
      }
      else
      {
        this.getUserProfileByUserName(pUserName, pPageName);
      }
    }
    else
    {
      this.navToPage(pUserName, pPageName);
    }
  }

  removeUserName()
  {
    this.store.dispatch(new UpdateVisitedPageAction(StoreUpdateTypes.Remove));

    let pages = [];
    const userStore = this.store.select(getUserVisitedPage()).subscribe(pagesDetails =>
    {
      if (pagesDetails)
      {
        pages = pagesDetails;
      }
    });
    userStore.unsubscribe();

    if (pages && pages.length > 0)
    {
      let lastUserDetail = pages[pages.length - 1];

      if (lastUserDetail)
      {
        this.postId = lastUserDetail.postId;

        if (lastUserDetail.isAppUser)
        {
          this.getUserProfile(lastUserDetail.pageName);
        }
        else
        {
          this.getUserProfileByUserName(lastUserDetail.userName, lastUserDetail.pageName);
        }
      }
    }
    else
    {
      this.store.dispatch(new UpdateVisitedPageAction(StoreUpdateTypes.Reset));
      this.util.navigate(PATHNAME.POST);
    }
  }

  resetUserName()
  {
    this.store.dispatch(new UpdateVisitedPageAction(StoreUpdateTypes.Reset));
  }

  private getUserProfile(pPageName: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUserProfile");

      let userProfile$ = this.postFunctions.getUserProfile(FILE_NAME.PROFILE_FLOW_SERVICE)
        .subscribe(response =>
        {
          if (response.detail)
          {
            let userDetail: ProfileDetails = this.util.cloneDeep(response.detail);

            let userProfile: ProfileDetail = {
              userId: userDetail.id,
              bio: userDetail.bio,
              dpUrl: userDetail.dp_url,
              firstName: userDetail.first_name,
              lastName: userDetail.last_name,
              userName: userDetail.userName,
              isFollowed: null,
              following: userDetail.following,
              follower: userDetail.follower,
              isBlocked: false,
              isMuted: false,
              isPrivateAccount: false,
              canFollow: userDetail.canFollow
            };

            this.store.dispatch(new AppUserProfileDetailAction(userProfile));
            this.store.dispatch(new IsAppUserAction(true));

            this.navToPage(userDetail.userName, pPageName);
          }
        });

      this.subscriptions.push(userProfile$);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.PROFILE_FLOW_SERVICE,
        "error in getUserProfile",
        err.toString(),
        "getUserProfile",
      );
      this.logger.getResponse(logRequest);
    }
  }

  private getUserProfileByUserName(pUserName: string, pPageName: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUserProfileByUserName");

      let userDetails$ = this.postFunctions.getUsersDetailByName([pUserName], FILE_NAME.PROFILE_FLOW_SERVICE)
        .subscribe(response =>
        {
          if (response.userDetail && response.userDetail.length > 0)
          {
            let userDetail = response.userDetail[0];

            let userProfile: ProfileDetail = {
              userId: userDetail.user_id,
              bio: userDetail.bio,
              dpUrl: userDetail.dp_url,
              firstName: userDetail.first_name,
              lastName: userDetail.last_name,
              userName: userDetail.user_name,
              isFollowed: userDetail.is_followed,
              following: userDetail.user_following,
              follower: userDetail.user_followers,
              isPrivateAccount: userDetail.is_private,
              isBlocked: userDetail.user_is_blocked,
              isMuted: userDetail.is_muted_user,
              canFollow: userDetail.can_follow
            };

            this.store.dispatch(new UserProfileDetailAction(userProfile));
            this.store.dispatch(new IsAppUserAction(false));

            this.navToPage(userDetail.user_name, pPageName);
          }
        });

      this.subscriptions.push(userDetails$);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.PROFILE_FLOW_SERVICE,
        "error in getUserProfileByUserName",
        err.toString(),
        "getUserProfileByUserName",
      );
      this.logger.getResponse(logRequest);
    }
  }

  private navToPage(pUserName: string, pPageName: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToPage");

      this.canProceed.next(pPageName);
      // navigation
      switch (pPageName)
      {
        case PageNames.Followers:
          {
            this.util.navigate(PATHNAME.FOLLOWERS_LIST);
            break;
          }
        case PageNames.Following:
          {
            this.util.navigate(PATHNAME.FOLLOWING_LIST);
            break;
          }
        case PageNames.RequestSent:
          {
            this.util.navigate(PATHNAME.FOLLOW_SENT);
            break;
          }
        case PageNames.RequestReceived:
          {
            this.util.navigate(PATHNAME.FOLLOW_REQUEST);
            break;
          }
        case PageNames.Profile:
          {
            this.util.navigate(PATHNAME.PROFILE, { queryParams: { username: pUserName } });
            break;
          }
        case PageNames.Privacy:
          {
            this.util.navigate(PATHNAME.PRIVACY);
            break;
          }
        case PageNames.Post:
          {
            this.util.navigate(PATHNAME.POST);
            break;
          }
        case PageNames.Blocked:
          {
            this.util.navigate(PATHNAME.BLOCKED_ACCTS);
            break;
          }
        case PageNames.Muted:
          {
            this.util.navigate(PATHNAME.MUTED_ACCTS);
            break;
          }
        case PageNames.Notification:
          {
            this.util.navigate(PATHNAME.USER_NOTIFICATION);
            break;
          }
        case PageNames.Details:
          {
            this.util.navigate(PATHNAME.POST_DETAIL, { queryParams: { 'post-id': this.postId } });
            // this.util.navigate(PATHNAME.SAMPLE_POST_DETAIL, { queryParams: { 'post-id': this.postId } });
            break;
          }

        default: break;
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.PROFILE_FLOW_SERVICE,
        "error in navToPage",
        err.toString(),
        "navToPage",
      );
      this.logger.getResponse(logRequest);
    }
  }
}



