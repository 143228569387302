import { RouterState, RouterStateModel } from '@ngxs/router-plugin';
import { createSelector } from '@ngxs/store';
import { MessageDetailPageStateModel } from './message-detail.model';
import { MessageDetailPageState } from './message-detail.state';

export const selectMessageDetail = () =>
  createSelector(
    [MessageDetailPageState, RouterState],
    (messageDetail: MessageDetailPageStateModel) => messageDetail.commentsLs
  );

export const getMsgDetail = () =>
  createSelector(
    [MessageDetailPageState, RouterState],
    (messageDetail: MessageDetailPageStateModel) =>
      messageDetail
  );

export const selectMessagesListDetail = () =>
  createSelector(
    [MessageDetailPageState, RouterState],
    (messageDetail: MessageDetailPageStateModel, routerState: RouterStateModel) =>
    {
      // const messagesDetail = messageDetail.listData[routerState.state.root.params.messageId];
      // return messagesDetail
      //   ? chain(messagesDetail.messages)
      //       .groupBy((message) => moment(message.createdAt).format('DD-MM-YYYY'))
      //       .map((messages) => ({ createdAt: messages[0].createdAt, data: messages }))
      //       .value()
      //   : [];
    },
  );

export const getNextMsgOffset = () =>
  createSelector(
    [MessageDetailPageState, RouterState],
    (messageDetail: MessageDetailPageStateModel) => messageDetail.nextMsgOffset,
  );

export const getDraftMsgs = () =>
  createSelector([MessageDetailPageState, RouterState], (messageDetail: MessageDetailPageStateModel) => messageDetail.draftMsgs);
