import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { FILE_NAME, SERVICE_URL } from "../../util/constants";
import { AuthRequest, AuthResponse } from "../auth/auth.model";
import { HttpService } from "../http/http.service";
import { LoggerService } from "../logger/logger.service";
import { APIResponse } from "../service.model";


@Injectable({
  providedIn: 'root'
})
export class LoginService
{

  constructor(
    private logger: LoggerService,
    private httpService: HttpService,
  ) { }

  apiAuthService(req: AuthRequest): Observable<APIResponse<AuthResponse>>
  {
    let retValue: Observable<APIResponse<AuthResponse>>;

    try
    {
      if (req != null)
      {
        retValue = this.httpService.makePostRequest(SERVICE_URL.APP_AUTH_URL, req);
      } else
      {
        throw "invalid sign in request";
      }
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.LOGIN_SERVICE,
        "error in apiAuthService",
        err.toString(),
        "apiAuthService",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }
}
