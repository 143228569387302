import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddPostClientReq, AddPostClientRes, AdminPostApprovalClientReq, AdminPostApprovalClientRes, DeleteImageReq, DeleteImageRes, DeletePostClientReq, DeletePostClientRes, DislikePostClientReq, DislikePostClientRes, GetGroupsClientRes, GetPostTagsClientReq, GetPostTagsClientRes, GetSavedPostClientRes, LikePostClientReq, LikePostClientRes, RemoveDislikePostClientReq, RemoveDislikePostClientRes, RemovePostLikeClientReq, RemovePostLikeClientRes, RemoveSavedPostClientReq, RemoveSavedPostClientRes, ReportPostClientReq, ReportPostClientRes, SavePostClientReq, SavePostClientRes, SharePostClientReq, SharePostClientRes } from 'social-media-api';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, PostStatus, SERVICE_URL } from '../../util/constants';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { APIResponse } from '../service.model';


@Injectable({
  providedIn: 'root'
})
export class PostService
{
  searchedLs = new BehaviorSubject(null);

  constructor(private logger: LoggerService, private httpService: HttpService) { }

  ///add post
  addPostReq = (pAddPostReq): AddPostClientReq =>
  {
    let retValue: AddPostClientReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("addPostReq....");


      const reqBody: AddPostClientReq = {
        id: pAddPostReq.id,
        mainImage: pAddPostReq.mainImage,
        timeInMinutes: pAddPostReq.timeInMinutes,
        description: pAddPostReq.description,
        fieldOfWork: pAddPostReq.fieldOfWork,
        reflink: pAddPostReq.reflink,
        isVideo: pAddPostReq.isVideo,
        title: pAddPostReq.title,
        userTags: pAddPostReq.userTags,
        userMentions: pAddPostReq.userMentions,
        postMentions: pAddPostReq.postMentions,
        postedDate: pAddPostReq.postedDate,
        groupTags: pAddPostReq.groupTags,
        ageGroupTags: pAddPostReq.ageGroupTags,
        postType: "Submitted",
        videoIds: pAddPostReq.videoIds,
        products: pAddPostReq.products
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in addPostReq",
        err.toString(),
        "addPostReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  addPostRes(pReq: AddPostClientReq): Observable<APIResponse<AddPostClientRes>>
  {
    let retValue: Observable<APIResponse<AddPostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_ADD_POST, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in addPostRes",
        err.toString(),
        "addPostRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// likes

  addLikesReq = (pPostId: string): LikePostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("addLikesReq....");


      const reqBody: LikePostClientReq = {
        postId: pPostId
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in addLikesReq",
        err.toString(),
        "addLikesReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  addLikesRes(pReq: LikePostClientReq): Observable<APIResponse<LikePostClientRes>>
  {
    let retValue: Observable<APIResponse<LikePostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_ADD_LIKE, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in addLikesRes",
        err.toString(),
        "addLikesRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// dislikes

  disLikesReq = (pPostId: string): DislikePostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("disLikesReq....");


      const reqBody: DislikePostClientReq = {
        postId: pPostId
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in disLikesReq",
        err.toString(),
        "disLikesReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  disLikesRes(pReq: DislikePostClientReq): Observable<APIResponse<DislikePostClientRes>>
  {
    let retValue: Observable<APIResponse<DislikePostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_DISLIKE, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in disLikesRes",
        err.toString(),
        "disLikesRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  /// remove post like
  removeLikesReq = (pPostId: string): RemovePostLikeClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeLikesReq....");


      const reqBody: RemovePostLikeClientReq = {
        postId: pPostId
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in removeLikesReq",
        err.toString(),
        "removeLikesReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  removeLikesRes(pReq: RemovePostLikeClientReq): Observable<APIResponse<RemovePostLikeClientRes>>
  {
    let retValue: Observable<APIResponse<RemovePostLikeClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_REMOVE_LIKE, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in removeLikesRes",
        err.toString(),
        "removeLikesRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  /// remove dislike
  removeDislikeReq = (pPostId: string): RemoveDislikePostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeDislikeReq....");


      const reqBody: RemoveDislikePostClientReq = {
        postId: pPostId
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in removeDislikeReq",
        err.toString(),
        "removeDislikeReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  removeDislikeRes(pReq: RemoveDislikePostClientReq): Observable<APIResponse<RemoveDislikePostClientRes>>
  {
    let retValue: Observable<APIResponse<RemoveDislikePostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_REMOVE_DISLIKE, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in removeDislikeRes",
        err.toString(),
        "removeDislikeRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  /// report post req
  reportPostReq = (pPostId: string, pReason: number, pReasonText: string): ReportPostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("reportPostReq....");


      const reqBody: ReportPostClientReq = {
        postId: pPostId,
        reason: pReason,
        reasonText: pReasonText
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in reportPostReq",
        err.toString(),
        "reportPostReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  reportPostRes(pReq: ReportPostClientReq): Observable<APIResponse<ReportPostClientRes>>
  {
    let retValue: Observable<APIResponse<ReportPostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_ADD_REPORT, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in reportPostRes",
        err.toString(),
        "reportPostRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  /// get category
  getCategoryRes(): Observable<APIResponse<GetGroupsClientRes>>
  {
    let retValue: Observable<APIResponse<GetGroupsClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_CATEGORY, null);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in getCategoryRes",
        err.toString(),
        "getCategoryRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///share post
  shareReq = (pPostId: string, pVia: number): SharePostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("shareReq....");


      const reqBody: SharePostClientReq = {
        postId: pPostId,
        via: pVia
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in shareReq",
        err.toString(),
        "shareReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  shareRes(pReq: SharePostClientReq): Observable<APIResponse<SharePostClientRes>>
  {
    let retValue: Observable<APIResponse<SharePostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_SHARE, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in shareRes",
        err.toString(),
        "shareRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///get tags
  getTagsReq = (pSearch: string): GetPostTagsClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getTagsReq....");


      const reqBody: GetPostTagsClientReq = {
        searchTerm: pSearch
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in getTagsReq",
        err.toString(),
        "getTagsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getTagsRes(pReq: GetPostTagsClientReq): Observable<APIResponse<GetPostTagsClientRes>>
  {
    let retValue: Observable<APIResponse<GetPostTagsClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_TAGS, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in getTagsRes",
        err.toString(),
        "getTagsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  ///save post
  savePostReq = (postId: string): SavePostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("savePostReq....");


      const reqBody: SavePostClientReq = {
        postId: postId
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in savePostReq",
        err.toString(),
        "savePostReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  savePostRes(pReq: SavePostClientReq): Observable<APIResponse<SavePostClientRes>>
  {
    let retValue: Observable<APIResponse<SavePostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_SAVE_POST, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in savePostRes",
        err.toString(),
        "savePostRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  ///remove save post
  removeSavedPostReq = (postId: string): RemoveSavedPostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeSavedPostReq....");


      const reqBody: RemoveSavedPostClientReq = {
        postId: postId
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in removeSavedPostReq",
        err.toString(),
        "removeSavedPostReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  removeSavePostRes(pReq: RemoveSavedPostClientReq): Observable<APIResponse<RemoveSavedPostClientRes>>
  {
    let retValue: Observable<APIResponse<RemoveSavedPostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_REMOVE_SAVE_POST, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in removeSavePostRes",
        err.toString(),
        "removeSavePostRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///get save post
  getSavePostRes(): Observable<APIResponse<GetSavedPostClientRes>>
  {
    let retValue: Observable<APIResponse<GetSavedPostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_SAVE_POST, null);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in getSavePostRes",
        err.toString(),
        "getSavePostRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// delete comment 

  deletePostsReq = (pPostId: string, pCommentId: string): DeletePostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("deletePostsReq....");


      const reqBody: DeletePostClientReq = {
        postId: pPostId,
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in deletePostsReq",
        err.toString(),
        "deletePostsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  deletePostsRes(pReq: DeletePostClientReq): Observable<APIResponse<DeletePostClientRes>>
  {
    let retValue: Observable<APIResponse<DeletePostClientRes>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_DELETE_POST, pReq);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in deletePostsRes",
        err.toString(),
        "deletePostsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// post status

  updatePostStatusReq = (pPostId: string[], pStatus: number): AdminPostApprovalClientReq =>
  {
    let retValue: AdminPostApprovalClientReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("updatePostStatusReq....");

      const reqBody: AdminPostApprovalClientReq = {
        postId: pPostId,
        status: pStatus ? pStatus : PostStatus.Published
      };
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in updatePostStatusReq",
        err.toString(),
        "updatePostStatusReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  updatePostStatusRes(pReq: AdminPostApprovalClientReq): Observable<APIResponse<AdminPostApprovalClientRes>>
  {
    let retValue: Observable<APIResponse<AdminPostApprovalClientRes>>;
    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_UPDATE_POST_STATUS, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in updatePostStatusReq",
        err.toString(),
        "updatePostStatusReq",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// post status

  removeImageReq = (pPostId: string, imageIds: string[]): DeleteImageReq =>
  {
    let retValue: DeleteImageReq;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeImageReq....");

      const reqBody: DeleteImageReq = {
        postId: pPostId,
        imageId: imageIds
      };
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in removeImageReq",
        err.toString(),
        "removeImageReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  removeImageRes(pReq: DeleteImageReq): Observable<APIResponse<DeleteImageRes>>
  {
    let retValue: Observable<APIResponse<DeleteImageRes>>;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeImageRes....");
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_POST_DELETE_IMAGE, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in removeImageRes",
        err.toString(),
        "removeImageRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }
  getUserAllReq(pReq: any): Observable<APIResponse<any>>
  {
    let retValue: Observable<APIResponse<any>>;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUserAllReq....");
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_ADMIN_GET_ALL_USER, pReq);
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_SERVICE,
        "error in getUserAllReq",
        err.toString(),
        "getUserAllReq",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

}
