import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, Subscriber, Subscription } from "rxjs";
import
{
  CancelSentFollowRes,
  FollowClientRes,
  GetPostClientRes,
  GetProfileDetailClientRes,
  GetUserDetailsClientRes,
  LikePostClientRes,
  RemoveDpRes,
  RemoveFollowClientRes,
  RemovePostLikeClientRes,
  RemoveSavedPostClientRes,
  SavePostClientRes,
  SharePostClientRes,
  UnFollowClientRes
} from "social-media-api";
import { ProfileDetails } from "social-media-api-pkg";
import { PostActions } from "../pages/post/all-post/all-post.actions";
import { AppUserProfileDetailAction, IsAppUserAction } from "../pages/profile/profile.actions";
import { ProfileDetail } from "../pages/profile/profile.modal";
import { UserSettingsAction } from "../pages/settings/settings.actions";
import { FeedService } from "../services/feed/feed.service";
import { LoggerService } from "../services/logger/logger.service";
import { PostService } from "../services/post/post.service";
import { APIResponse } from "../services/service.model";
import { SettingsService } from "../services/settings/settings.service";
import { UserService } from "../services/user/user.service";
import { CategorySelectAction, FollowSuggSelectAction, PeopleSuggReqFromAction, ProfileVisitAction } from "../store/settings/settings.action";
import { getErrorMessage, VALIDATE_SUCCESS_RES } from "./apiValidate";
import { BASE_CONFIG } from "./base-settings";
import { COMPONENT_NAME, FILE_NAME, PATHNAME } from "./constants";
import { UserDevices } from "./user-device";
import { UtilFunctions } from "./util";

@Injectable({
  providedIn: 'root',
})

export class PostFunctions
{

  subscription: Subscription[] = [];

  constructor(
    private store: Store,
    private util: UtilFunctions,
    private logger: LoggerService,
    private postService: PostService,
    private feedService: FeedService,
    private userService: UserService,
    private userDevices: UserDevices,
    private settingsService: SettingsService,
    private router: Router,
  ) { }

  logAPIError(pResponse: any, pFuncName: string, pFileName: string)
  {
    try
    {
      const error = getErrorMessage(pResponse);

      let logRequest = this.util.createLogRequest(pFileName, pFuncName, error.error[0]);
      this.logger.getResponse(logRequest);

      const isJwt = this.util.handleJWT(error);

      if (!isJwt)
      {
        let alertValue = this.util.getErrorAlertValue(error);
      }
      // this.util.toShowToast(JSON.stringify(error));
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in logAPIError",
        err.toString(),
        "logAPIError",
      );
      this.logger.getResponse(logRequest);
    }
  }

  unsubscribeAllRes()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("unsubscribeAllRes");

      this.subscription.forEach(subscribe => 
      {
        subscribe.unsubscribe();
      });
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in unsubscribeAllRes",
        err.toString(),
        "unsubscribeAllRes",
      );
      this.logger.getResponse(logRequest);
    }
  }

  addLike(pPostId: string, pReqFrom: string): Observable<LikePostClientRes>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("addLike");

      let addLikeReq = this.postService.addLikesReq(pPostId);

      if (addLikeReq)
      {
        return new Observable((observer: Subscriber<LikePostClientRes>) =>
        {
          let addLike$ = this.postService.addLikesRes(addLikeReq)
            .subscribe({
              next: (res: APIResponse<LikePostClientRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "addLike", pReqFrom);
                }

                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
              // complete: () => console.info('complete') 
            });

          this.subscription.push(addLike$);
        });

      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in addLike",
        err.toString(),
        "addLike",
      );
      this.logger.getResponse(logRequest);
    }
  }

  removeLike(pPostId: string, pReqFrom: string): Observable<RemovePostLikeClientRes>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeLike");

      let removeLikeReq = this.postService.removeLikesReq(pPostId);

      if (removeLikeReq)
      {
        return new Observable((observer: Subscriber<RemovePostLikeClientRes>) =>
        {
          let removeLike$ = this.postService.removeLikesRes(removeLikeReq)
            .subscribe({
              next: (res: APIResponse<RemovePostLikeClientRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "removeLike", pReqFrom);
                }

                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
              // complete: () => console.info('complete') 
            });

          this.subscription.push(removeLike$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in removeLike",
        err.toString(),
        "removeLike",
      );
      this.logger.getResponse(logRequest);
    }
  }

  addFavorite(pPostId: string, pReqFrom: string): Observable<SavePostClientRes>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("addFavorite");

      let addFavoriteReq = this.postService.savePostReq(pPostId);

      if (addFavoriteReq)
      {
        return new Observable((observer: Subscriber<SavePostClientRes>) =>
        {
          let addFavorite$ = this.postService.savePostRes(addFavoriteReq)
            .subscribe({
              next: (res: APIResponse<SavePostClientRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "addFavorite", pReqFrom);
                }

                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
              // complete: () => console.info('complete') 
            });

          this.subscription.push(addFavorite$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in addFavorite",
        err.toString(),
        "addFavorite",
      );
      this.logger.getResponse(logRequest);
    }
  }

  removeFavorite(pPostId: string, pReqFrom: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeFavorite");

      let removeFavoriteReq = this.postService.removeSavedPostReq(pPostId);

      if (removeFavoriteReq)
      {
        return new Observable((observer: Subscriber<RemoveSavedPostClientRes>) =>
        {
          let removeFavorite$ = this.postService.removeSavePostRes(removeFavoriteReq)
            .subscribe({
              next: (res: APIResponse<RemoveSavedPostClientRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "removeFavorite", pReqFrom);
                }

                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
            });

          this.subscription.push(removeFavorite$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in removeFavorite",
        err.toString(),
        "toCallShareApi",
      );
      this.logger.getResponse(logRequest);
    }
  }

  sharePost(pPostId: string, pVia: number, pReqFrom: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("sharePost");

      let shareReq = this.postService.shareReq(pPostId, pVia);

      if (shareReq)
      {
        return new Observable((observer: Subscriber<SharePostClientRes>) =>
        {
          let sharePost$ = this.postService.shareRes(shareReq)
            .subscribe({
              next: (res: APIResponse<SharePostClientRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "sharePost", pReqFrom);
                }
                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
              // complete: () => console.info('complete') 
            });

          this.subscription.push(sharePost$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in sharePost",
        err.toString(),
        "sharePost",
      );
      this.logger.getResponse(logRequest);
    }
  }

  getUsersDetailByName(pUserName: string[], pReqFrom: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUsersDetailByName");

      let getUsersDetailByNameReq = this.userService.getUserDetailsReq(pUserName, 0); // TODO: user offset based request

      if (getUsersDetailByNameReq)
      {
        return new Observable((observer: Subscriber<GetUserDetailsClientRes>) =>
        {
          let userDetail$ = this.userService.getUserDetailsRes(getUsersDetailByNameReq)
            .subscribe({
              next: (res: APIResponse<GetUserDetailsClientRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "getUsersDetailByName", pReqFrom);
                }
                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
              // complete: () => console.info('complete') 
            });

          this.subscription.push(userDetail$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in getUsersDetailByName",
        err.toString(),
        "getUsersDetailByName",
      );
      this.logger.getResponse(logRequest);
    }
  }

  getUserProfile(pReqFrom: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUserProfile");

      return new Observable((observer: Subscriber<GetProfileDetailClientRes>) =>
      {
        let userProfile$ = this.userService.getProfileDetailsRes()
          .subscribe({
            next: (res: APIResponse<GetProfileDetailClientRes>) =>
            {
              if (VALIDATE_SUCCESS_RES(res))
              {
                observer.next(res.body.data);
              }
              else
              {
                this.logAPIError(res, "getUserProfile", pReqFrom);
              }
              observer.complete();
            },
            error: (err: HttpErrorResponse) => { },
            // complete: () => console.info('complete') 
          });

        this.subscription.push(userProfile$);
      });
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in getUserProfile",
        err.toString(),
        "getUserProfile",
      );
      this.logger.getResponse(logRequest);
    }
  }

  getPostDetails(pPostIds: string[], pReqFrom: string, pIsMyPosts: boolean = false)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getPostDetails");

      let getPostDetailsReq = this.feedService.getPostsReq(pPostIds, pIsMyPosts);

      if (getPostDetailsReq)
      {
        return new Observable((observer: Subscriber<GetPostClientRes>) =>
        {
          let postDetail$ = this.feedService.getPostsRes(getPostDetailsReq)
            .subscribe({
              next: (res: APIResponse<GetPostClientRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "getPostDetails", pReqFrom);
                }
                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
              // complete: () => console.info('complete') 
            });

          this.subscription.push(postDetail$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in getPostDetails",
        err.toString(),
        "getPostDetails",
      );
      this.logger.getResponse(logRequest);
    }
  }

  setUserProfile(pIsNewUser: boolean, pIsReloadPage: boolean)
  {
    let retValue: boolean = true;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("setUserProfile");

      let userProfile$ = this.getUserProfile(COMPONENT_NAME.LOGIN_PAGE)
        .subscribe(response =>
        {
          if (response.detail)
          {
            let userDetail: ProfileDetails = this.util.cloneDeep(response.detail);

            let userProfile: ProfileDetail = {
              userId: userDetail.id,
              bio: userDetail.bio,
              dpUrl: userDetail.dp_url,
              firstName: userDetail.first_name,
              lastName: userDetail.last_name,
              userName: userDetail.userName,
              isFollowed: null,
              following: userDetail.following,
              follower: userDetail.follower,
              isBlocked: false,
              isMuted: false,
              isPrivateAccount: false,
              canFollow: userDetail.canFollow
            };

            this.store.dispatch(new AppUserProfileDetailAction(userProfile));
            this.store.dispatch(new IsAppUserAction(true));

            if (!userDetail.userName)
            {
              this.store.dispatch(new ProfileVisitAction(false));
            }

            let isNewUser: boolean = (!userDetail.userName || pIsNewUser);

            this.navigateNextPage(isNewUser, pIsReloadPage);
          }
        });

      this.subscription.push(userProfile$);
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in setUserProfile",
        err.toString(),
        "setUserProfile",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  getUserSettings()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUserSettings");

      let userSettings$ = this.settingsService.getUserSettingsRes()
        .subscribe(
          {
            next: (res) => 
            {
              if (VALIDATE_SUCCESS_RES(res))
              {
                if (res.body.data.settings)
                {
                  this.store.dispatch(new UserSettingsAction(res.body.data.settings));
                }
              }
              else
              {
                this.logAPIError(res, "getUserSettings", FILE_NAME.POST_FUNCTIONS);
              }
            },
            error: (err: HttpErrorResponse) => { },
          });

      this.subscription.push(userSettings$);
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in getUserSettings",
        err.toString(),
        "getUserSettings",
      );
      this.logger.getResponse(logRequest);
    }
  }

  getPreference()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getPreference");

      let preference$ = this.userService.getPreferenceRes()
        .subscribe(
          {
            next: async (res) =>
            {
              if (VALIDATE_SUCCESS_RES(res))
              {
                if (res.body.data.preference === null)
                {
                  this.store.dispatch(new CategorySelectAction(false));

                  this.util.navigate(PATHNAME.CATEGORY, null, true);
                }
                else
                {
                  this.store.dispatch(new CategorySelectAction(true));
                  this.store.dispatch(new PeopleSuggReqFromAction(null));
                  this.store.dispatch(new FollowSuggSelectAction(true));
                  this.store.dispatch(new PostActions.CategoryLsAction(res.body.data.preference['groups']));

                  // this.util.navigate(PATHNAME.POST, null, true);
                }
                this.util.closeModal();
              }
              else
              {
                this.logAPIError(res, "getPostDetails", FILE_NAME.POST_FUNCTIONS);
              }
            },
            error: (err: HttpErrorResponse) => { },
          });

      this.subscription.push(preference$);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in getPreference",
        err.toString(),
        "getPreference",
      );
      this.logger.getResponse(logRequest);
    }
  }

  async handleRedirect(pIsNewUser: boolean, pIsReloadPage: boolean = false)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("handleRedirect");

      this.store.dispatch(new PostActions.PostIdsAction(null));
      this.store.dispatch(new PostActions.clearPostListAction(null));

      this.addUserDeviceId();
      // this.userDevices.checkUserDevice();
      this.getUserSettings();
      this.setUserProfile(pIsNewUser, pIsReloadPage);

      // this.util.closeModal();
      // if (pIsNewUser)
      // {
      //   this.util.navigate(PATHNAME.ADD_PROFILE, null, true);
      // }
      // else
      // {
      //   // this.getUserSettings();
      //   this.getPreference();

      //   if (pIsReloadPage)
      //   {
      //     this.checkUrl();
      //   }
      //   else
      //   {
      //     this.util.navigate(PATHNAME.POST, null, true);
      //   }
      // }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in handleRedirect",
        err.toString(),
        "handleRedirect",
      );
      this.logger.getResponse(logRequest);
    }
  }

  addUserDeviceId()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("addUserDeviceId");

      const isGuestUser = this.util.checkGuestUser();

      if (!isGuestUser)
      {
        this.userDevices.checkUserDevice();
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in addUserDeviceId",
        err.toString(),
        "addUserDeviceId",
      );
      this.logger.getResponse(logRequest);
    }
  }

  navigateNextPage(pIsNewUser: boolean, pIsReloadPage: boolean)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navigateNextPage");

      this.util.closeModal();

      if (pIsNewUser)
      {
        this.util.navigate(PATHNAME.ADD_PROFILE, null, true);
      }
      else
      {
        this.getPreference();

        if (pIsReloadPage)
        {
          this.checkUrl();
        }
        else
        {
          this.util.navigate(PATHNAME.POST, null, true);
        }
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in navigateNextPage",
        err.toString(),
        "navigateNextPage",
      );
      this.logger.getResponse(logRequest);
    }
  }


  updateProfileImg(pImgFile: any, pReqFrom: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("updateProfileImg");

      if (pImgFile)
      {
        let formData: any = new FormData();

        formData.append('image', pImgFile);

        return new Observable((observer: Subscriber<any>) =>
        {
          let updateProfileImg$ = this.userService.getUploadProfilePictureRes(formData)
            .subscribe({
              next: (res: APIResponse<any>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "updateProfileImg", pReqFrom);
                }

                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
            });
          this.subscription.push(updateProfileImg$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in updateProfileImg",
        err.toString(),
        "updateProfileImg",
      );
      this.logger.getResponse(logRequest);
    }
  }

  removeProfileImg(pReqFrom: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeProfileImg");

      return new Observable((observer: Subscriber<RemoveDpRes>) =>
      {
        let removeProfileImg$ = this.userService.removeUserProfile()
          .subscribe({
            next: (res: APIResponse<RemoveDpRes>) =>
            {
              if (VALIDATE_SUCCESS_RES(res))
              {
                observer.next(res.body.data);
              }
              else
              {
                this.logAPIError(res, "updateProfileImg", pReqFrom);
              }

              observer.complete();
            },
            error: (err: HttpErrorResponse) => { },
          });
        this.subscription.push(removeProfileImg$);
      });
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in updateProfileImg",
        err.toString(),
        "updateProfileImg",
      );
      this.logger.getResponse(logRequest);
    }
  }

  followUser(pUserId: string, pReqFrom: string): Observable<FollowClientRes>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("followUser");

      let followUserReq = this.userService.followUserReq(pUserId, null);

      if (followUserReq)
      {
        return new Observable((observer: Subscriber<FollowClientRes>) =>
        {
          let followUser$ = this.userService.followUserRes(followUserReq)
            .subscribe({
              next: (res: APIResponse<FollowClientRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "followUser", pReqFrom);
                }

                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
              // complete: () => console.info('complete') 
            });

          this.subscription.push(followUser$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in followUser",
        err.toString(),
        "followUser",
      );
      this.logger.getResponse(logRequest);
    }
  }

  unFollowUser(pUserId: string, pReqFrom: string): Observable<UnFollowClientRes>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("unFollowUser");

      let unFollowUserReq = this.userService.unFollowUserReq(pUserId);

      if (unFollowUserReq)
      {
        return new Observable((observer: Subscriber<UnFollowClientRes>) =>
        {
          let unFollowUser$ = this.userService.unFollowUserRes(unFollowUserReq)
            .subscribe({
              next: (res: APIResponse<UnFollowClientRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "unFollowUser", pReqFrom);
                }

                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
              // complete: () => console.info('complete') 
            });

          this.subscription.push(unFollowUser$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in unFollowUser",
        err.toString(),
        "unFollowUser",
      );
      this.logger.getResponse(logRequest);
    }
  }

  cancelFollowReq(pUserId: string, pReqFrom: string): Observable<CancelSentFollowRes>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("cancelFollowReq");

      let cancelFollowReq = this.userService.getCancelSentFollowReq(pUserId);

      if (cancelFollowReq)
      {
        return new Observable((observer: Subscriber<CancelSentFollowRes>) =>
        {
          let cancelFollowReq$ = this.userService.getCancelSentFollowRes(cancelFollowReq)
            .subscribe({
              next: (res: APIResponse<CancelSentFollowRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "cancelFollowReq", pReqFrom);
                }

                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
              // complete: () => console.info('complete') 
            });

          this.subscription.push(cancelFollowReq$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in cancelFollowReq",
        err.toString(),
        "cancelFollowReq",
      );
      this.logger.getResponse(logRequest);
    }
  }
  removeFollow(pUserId: string, pReqFrom: string): Observable<RemoveFollowClientRes>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeFollow");

      const removeFollowReq = this.userService.removeFollowReq(pUserId);

      if (removeFollowReq)
      {
        return new Observable((observer: Subscriber<RemoveFollowClientRes>) =>
        {
          let cancelFollowReq$ = this.userService.removeFollowRes(removeFollowReq)
            .subscribe({
              next: (res: APIResponse<RemoveFollowClientRes>) =>
              {
                if (VALIDATE_SUCCESS_RES(res))
                {
                  observer.next(res.body.data);
                }
                else
                {
                  this.logAPIError(res, "removeFollow", pReqFrom);
                }

                observer.complete();
              },
              error: (err: HttpErrorResponse) => { },
              // complete: () => console.info('complete') 
            });

          this.subscription.push(cancelFollowReq$);
        });
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in removeFollow",
        err.toString(),
        "removeFollow",
      );
      this.logger.getResponse(logRequest);
    }
  }

  checkUrl()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("checkUrl");

      const url = this.router.url.split('?')[0];

      if (url == PATHNAME.POST)
      {
        this.util.reloadPage(); // after closing guest modal we need to reload page
      }
      else
      {
        this.util.navigate(PATHNAME.POST, null, true);
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.POST_FUNCTIONS,
        "error in checkUrl",
        err.toString(),
        "checkUrl",
      );
      this.logger.getResponse(logRequest);
    }
  }
}