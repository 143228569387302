<ion-content
  class="test"
  [ngClass]="{'intro_category': !isWebSidePanel && !isFromFeedPage}"
>

  <div class="categories">
    <div class="categories_select">
      <h2 class="inner_title">{{ ((isFromFeedPage || isWebSidePanel) ? "categoryTitle" : "chooseCategory") | translate
        }}</h2>

      <ion-button
        *ngIf="!isWebSidePanel && !isFromFeedPage"
        class="transparent_txt_btn"
        (click)="selectAll()"
      >
        {{ (isSelectedAll ? 'deselectAll' : 'selectAll') | translate}}
      </ion-button>

      <ion-button
        *ngIf="isFromFeedPage"
        class="transparent_txt_btn"
        (click)="searchSelCategory()"
      >
        {{'OKText' | translate}}
      </ion-button>
    </div>


    <div class="category_row">
      <div
        class="category-col"
        *ngFor="let option of categoryLs; trackBy:trackItems"
        (click)="isWebSidePanel ? toSavePreference(option) : setSelCategory(option)"
        [ngClass]="option.isSelected?'selected':''"
      >
        <div class="category_box">
          <!-- <ion-icon [src]="'/assets/svg/category/' + option.groupid + '.svg'"></ion-icon> -->
          <p
            class="category-group"
            [ngStyle]="{'color': option.isSelected == true ? 'orange': ''}"
          >
            {{option.groupname}}
          </p>
        </div>
      </div>
    </div>

    <div
      *ngIf="selectAlert"
      class="invalid_msg"
    >
      {{selectAlert}}
    </div>
  </div>

</ion-content>

<ion-footer
  *ngIf="!isWebSidePanel"
  [ngClass]="{'intro_footer': !isWebSidePanel}"
>
  <ion-button
    class="category_btn transparent_txt_btn"
    (click)="onSaveThePreference()"
    *ngIf="!isFromFeedPage"
  >
    <!-- {{ isShowFollowSugg ? ("nextText" | translate) : ("finish" | translate) }} -->
    {{ "nextText" | translate }}
  </ion-button>

  <!-- <ion-button
    *ngIf="isFromFeedPage"
    class="category_btn transparent_txt_btn"
    (click)="searchSelCategory()"
  >
    {{'OKText' | translate}}
  </ion-button> -->

</ion-footer>
