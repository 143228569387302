import { PostX } from "social-media-api-pkg";
import { PostRequest } from "../../../model/post.model";
import { PostChanges, StoreUpdateTypes } from "../../../util/constants";
import { PeopleSuggLs, PostDetailIdObj, PostDetailObj, PostIdObj, PostStateModel } from "./all-post.model";
/* Post Action */
export namespace PostActions
{

  export class FetchPostAction
  {
    static readonly type = '[post] Fetch post';
    // constructor(public pages: number,public postReq: PostRequest) {}
    constructor(public isRefresh: boolean, public postReq: PostRequest) { }
  }

  export class PostSuccessAction
  {
    static readonly type = '[post] Fetch post success';
    constructor(public post: PostX[], public isRefresh: boolean) { }
  }

  export class PostDetailAction
  {
    static readonly type = '[post] post detail';
    constructor(public postDetail: PostX) { }
  }

  export class PostIdsAction
  {
    static readonly type = '[post] post ids';
    constructor(public postIds: Array<PostIdObj>, public toRemove?: boolean) { }
  }

  export class clearPostListAction
  {
    static readonly type = '[post] post list';
    constructor(public post: PostX[]) { }
  }


  export class ClearPostDetailListAction
  {
    static readonly type = '[post] post detail list';
    constructor(public postDetailPostLs: PostX[]) { }
  }

  export class clearPostAction
  {
    static readonly type = '[post] posts list';
    constructor(public posts: PostStateModel) { }
  }

  export class PostOffsetAction
  {
    static readonly type = '[post] post offset';
    constructor(public offset: number) { }
  }

  export class CategoryLsAction
  {
    static readonly type = '[post] post category';
    constructor(public category: string[]) { }
  }

  export class AllCategoriesAction
  {
    static readonly type = '[post] all categories';
    constructor(public categories: any[]) { }
  }
  export class PostChangeAction
  {
    static readonly type = '[post] edit post';
    constructor(public postid: number | string, public changes: PostChanges) { }
  }
  export class SearchPostChangeAction
  {
    static readonly type = '[post] search post change';
    constructor(public postId: string, public changes: PostChanges) { }
  }

  export class PostDetailChangeAction
  {
    static readonly type = '[post] detail edit post';
    constructor(public postid: number | string, public changes: PostChanges) { }
  }
  export class PostDetailPageAction
  {
    static readonly type = '[post] post detail id';
    constructor(public detailObj: PostDetailIdObj | null) { }
  }
  export class UpdatePostIndexAction
  {
    static readonly type = '[post] update post ids index';
    constructor(public index: number) { }
  }

  export class PostLsAction
  {
    static readonly type = '[post] save post list';
    constructor(public postLs: PostX[], public isRefresh: boolean = false) { }
  }

  export class PostDetailLsAction
  {
    static readonly type = '[post] save post detail list';
    constructor(public postLs: PostX[], public isRefresh: boolean = false) { }
  }

  export class TrendingPostLsAction
  {
    static readonly type = '[post] trending post list';
    constructor(public postLs: PostX[]) { }
  }

  export class SearchedPostLsAction
  {
    static readonly type = '[post] searched post list';
    constructor(public type: StoreUpdateTypes, public postLs: PostX[]) { }
  }

  export class SavedPostLsAction
  {
    static readonly type = '[post] saved post list';
    constructor(public type: StoreUpdateTypes, public postLs: PostX[]) { }
  }

  export class UserPostLsAction
  {
    static readonly type = '[post] user post list';
    constructor(public type: StoreUpdateTypes, public postLs: PostX[]) { }
  }


  export class PostDetailObjAction
  {
    static readonly type = '[post] post detail obj';
    constructor(public detailObj: PostDetailObj | null) { }
  }

  export class NextOffsetAction
  {
    static readonly type = '[post]  detail next offset';
    constructor(public nextoffset: number) { }
  }

  export class PeopleSuggAction
  {
    static readonly type = '[post] follower suggestion';
    constructor(public peopleSuggLs: PeopleSuggLs) { }
  }

  export class AddPostDraftAction
  {
    static readonly type = '[post] add post draft';
    constructor(public postDetail: any) { }
  }

  export class AddVideosToDraftAction
  {
    static readonly type = '[post] add video to post draft';
    constructor(public videoDetail: any) { }
  }

  export class PostLikeChangeAction
  {
    static readonly type = '[post] like post';
    constructor(public postId: string, public changes: PostChanges) { }
  }

  export class PostViewChangeAction
  {
    static readonly type = '[post] fit to screen';
    constructor(public isFitToScreen: boolean) { }
  }

  export class SelProductsAction
  {
    static readonly type = '[post] add selected products';
    constructor(public selProducts: any[]) { }
  }

  export class ResetPostStoreAction
  {
    static readonly type = '[post] reset post store';
    constructor() { }
  }

}