import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CustomSkeletonComponent } from './custom-skeleton.component';


@NgModule({
  declarations: [CustomSkeletonComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [CustomSkeletonComponent]
})
export class CustomSkeletonModule { }
