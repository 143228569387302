export const appConfig = {
  /* App languages */
  langs: {
    default: 'en',
    en: 'en',
  },

  /* App routes */
  routes: {
    home:'home',
    tabs: {
      root: 'post',
    },
    admin: {
      root: 'admin',
    },
    auth: {
      login: 'auth/login',
      register: 'auth/register',
      forgot: 'auth/forgot',
      reset: 'auth/reset',
    },
    redirectOnError: 'post',
    redirectOnNotFound: 'post',
  },
};
