import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from '@ngxs/store';
import { environment } from '../../../environments/environment';
import { LoggerRequest, LOG_TYPE } from "../../model/logger/logger.model";
import { LogReqAction } from '../../store/login/login.actions';
import { getLogReq } from '../../store/login/login.selectors';
import { BASE_CONFIG } from "../../util/base-settings";
import { appConfig } from './../../app.config';


@Injectable({
  providedIn: "root",
})
export class LoggerService
{

  logReqLs: LoggerRequest[] = [];
  logCount: number = 0;
  funcLog: number = 0;
  logState = null;

  constructor(
    private currentRoute: Router,
    private http: HttpClient,
    private store: Store
  ) { }

  buildRequest(
    pModuleName: string,
    pErrTitle: string,
    pErrMessage: string,
    pFunctionName: string,
    pStatus?: string,
    pLogType: LOG_TYPE = LOG_TYPE.ERROR
  ): LoggerRequest
  {
    let retValue: LoggerRequest;
    try
    {
      retValue = {
        appId: BASE_CONFIG.LOG_APP_ID,
        browser: BASE_CONFIG.DEVICE.platform,
        browserversion: BASE_CONFIG.DEVICE.osVersion,
        ostype: BASE_CONFIG.DEVICE.name ? BASE_CONFIG.DEVICE.name : 'web' + ' ' + BASE_CONFIG.DEVICE.model,
        errtitle: pErrTitle,
        functionname: pFunctionName,
        level: pLogType,
        status: pStatus,
        message: pErrMessage,
        module: pModuleName,
        pathName: this.currentRoute.url,
        timestamp: new Date().toISOString(),
        versioncode: BASE_CONFIG.VERSION,
        langcode: appConfig.langs.default,
        isAdmin: environment.isAdmin ? "Admin" : "User"
      };
    }
    catch (err)
    {
      let logRequest = this.buildRequest(
        'logger',
        "error in buildRequest logger",
        err.toString(),
        "buildRequest ",
      );
      this.getResponse(logRequest);
    }
    return retValue;
  }

  getResponse(pLogRequest: LoggerRequest)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log(JSON.stringify(pLogRequest));

      var nologFound = false;

      let sLogReq = this.store.select(getLogReq()).subscribe((logReq: LoggerRequest) =>
      {
        if (logReq)
        {
          if (pLogRequest && logReq && logReq.logRequest && pLogRequest.functionname == logReq.logRequest.functionname)
          {
            this.funcLog += 1;
          }
          else
          {
            nologFound = true;
            this.funcLog = 0;
          }
        }
        else
        {
          nologFound = true;
          this.funcLog = 0;
        }
      });

      sLogReq.unsubscribe();

      if (nologFound)
      {
        this.store.dispatch(new LogReqAction(pLogRequest));
      }
      if (this.logCount < 3 && this.funcLog < 3)
      {
        this.http.post(BASE_CONFIG.LOGGER_URL, pLogRequest).subscribe();
      }
    }
    catch (err)
    {
      this.logCount += 1;
      let logRequest = this.buildRequest(
        'logger',
        "error in log logger",
        err.toString(),
        "log ",
      );
      this.getResponse(logRequest);
    }

    // if(this.logCount<=3) {
    //       // this.http.post(BASE_CONFIG.LOGGER_URL,[pLogRequest]).subscribe()
    //   this.http.post(BASE_CONFIG.LOGGER_URL,[pLogRequest]).subscribe({
    //       next:  res => {
    //        if(res['data'] && res['data'].isLogAdded){

    //        }else{

    //     }
    //   }, 
    //   error: (err: HttpErrorResponse) => {

    //   },
    // })
    // }
    // } catch (err) {
    //   this.logCount +=1;
    //   let logRequest = this.buildRequest(
    //     'logger',
    //    "error in buildRequest logger",
    //   err.toString(),
    //    "buildRequest ",
    //     );

    //    this.getResponse(logRequest);
    // }
  }
}
