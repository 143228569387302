import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FirebaseAuthentication } from '@awesome-cordova-plugins/firebase-authentication';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Store } from '@ngxs/store';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { AuthAction } from '../../core/auth-guard/auth-guard.actions';
import { FirebaseUserModel } from '../../model/Login.model';
import { GuestUserAction, TokenAction, UserAction, UserRoleAction } from '../../store/login/login.actions';
import { ProfileVisitAction } from '../../store/settings/settings.action';
import { getErrorMessage, VALIDATE_SUCCESS_RES } from '../../util/apiValidate';
import { AUTH_KEY, FB_AUTH_TYPE, FILE_NAME, PLATFORM, SOCIAL_LOGIN_TYPE } from '../../util/constants';
import { UtilFunctions } from '../../util/util';
import { FirebaseAuthService } from '../firebase-auth/firebase-auth.service';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { LoginService } from '../login/login.service';
import { APIResponse } from '../service.model';
import { BASE_CONFIG } from './../../util/base-settings';
import { SERVICE_URL } from './../../util/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService
{
  isAppInit = new BehaviorSubject(null);
  constructor(
    public afAuth: AngularFireAuth,
    private logger: LoggerService, private util: UtilFunctions,
    public apiLoginService: LoginService,
    private store: Store,
    private apiFirebaseAuthService: FirebaseAuthService,
    private httpService: HttpService
  ) { }

  //firebase auth api
  async firebaseAuthApi(pToken: string)
  {
    let isValid: boolean = false;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("firebaseAuthApi");

      const retReq: any = this.apiFirebaseAuthService.apiGetAuthReq(pToken);

      if (retReq)
      {
        let authRes = await lastValueFrom(this.apiFirebaseAuthService.apiGetAuthRes(retReq));
        // let res = this.apiFirebaseAuthService.apiGetAuthRes(retReq)
        // res.subscribe(async authRes => {
        if (authRes && VALIDATE_SUCCESS_RES(authRes))
        {
          this.store.dispatch(new AuthAction.AuthResAction(authRes.body.data));

          if (authRes && authRes.body && authRes.body.data && (AUTH_KEY.ok == authRes.body.data.result))
          {
            let msg = authRes.body.data.msg;
            this.store.dispatch(new UserRoleAction(authRes.body.data['userRoles'][0]));
            let jwtToken = "";

            if (msg == AUTH_KEY.newUserRefresh)
            {
              isValid = true;

              if (BASE_CONFIG.IS_WEB)
              {
                jwtToken = await firebase.auth().currentUser?.getIdToken(true);
              }
              else
              {
                jwtToken = await FirebaseAuthentication.getIdToken(true);
              }

              this.store.dispatch(new TokenAction(jwtToken));
              this.store.dispatch(new ProfileVisitAction(false));
            }
            if (msg == AUTH_KEY.existingUser)
            {
              isValid = true;
              this.store.dispatch(new TokenAction(pToken));
              this.store.dispatch(new ProfileVisitAction(true));
            }
            if (msg == AUTH_KEY.tokenExpired)
            {
              isValid = false;
            }
          }
          else
          {
            isValid = false;
          }
        }
        else
        {
          const error = getErrorMessage(authRes);

          let logRequest = this.util.createLogRequest(FILE_NAME.AUTH_SERVICE, 'firebaseAuthApi', JSON.stringify(error));
          this.logger.getResponse(logRequest);

          const isJwt = this.util.handleJWT(error);

          if (!isJwt)
          {
            let alertValue = this.util.getErrorAlertValue(error);
          }

          isValid = false;
        }
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in firebaseAuthApi",
        err.toString(),
        "firebaseAuthApi",
      );
      this.logger.getResponse(logRequest);
    }
    return isValid;
  }

  /// login with email and pwd
  async loginWithEmailPwd(pEmail: any, pPwd: any)
  {
    let isValidLogin = false;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("loginWithEmailPwd");

      isValidLogin = await this.firebaseAuthVerifyEmailPwd(pEmail, pPwd);
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in loginWithEmailPwd",
        err.toString(),
        "loginWithEmailPwd",
      );
      this.logger.getResponse(logRequest);
    }
    return isValidLogin;
  }


  /*
    async firebaseAuthVerifyEmailPwd(pEmail: any, pPwd: any)
    {
      let isValidLogin: any = null;
  
      try
      {
        if (BASE_CONFIG.IS_DEBUG) console.log("loginWithEmailPwd");
  
        await this.afAuth.signInWithEmailAndPassword(pEmail, pPwd).then(
          async (user: firebase.auth.UserCredential) =>
          {
            let idToken = await user.user.getIdToken();
  
            let userObj: FirebaseUserModel = {
              displayName: null,
              email: null,
              phoneNumber: null,
              photoURL: null,
              stsTokenManager: null,
              ProviderId: null
            };
            userObj.displayName = user.user.displayName;
            userObj.email = user.user.email;
            userObj.phoneNumber = user.user.phoneNumber ? user.user.phoneNumber : null;
            userObj.photoURL = user.user.photoURL;
            userObj.stsTokenManager = idToken;
            userObj.ProviderId = user.user.providerId;
  
            this.setUserProfile(userObj);
  
            isValidLogin = await this.firebaseAuthApi(idToken);
          },
          (error) =>
          {
            isValidLogin = {
              message: error
            };
          })
          .catch((error) =>
          {
            isValidLogin = {
              message: error
            };
          });
      } catch (err: any)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.AUTH_SERVICE,
          "error in firebaseAuthVerifyEmailPwd",
          err.toString(),
          "firebaseAuthVerifyEmailPwd",
        );
        this.logger.getResponse(logRequest);
      }
      return isValidLogin;
    }*/

  async firebaseAuthVerifyEmailPwd(pEmail: any, pPwd: any)
  {
    let isValidLogin: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("loginWithEmailPwd");

      if (BASE_CONFIG.IS_WEB)
      {
        isValidLogin = await this.webSignIn(pEmail, pPwd);
      }
      else
      {
        isValidLogin = await this.mobileSignIn(pEmail, pPwd);
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in firebaseAuthVerifyEmailPwd",
        err.toString(),
        "firebaseAuthVerifyEmailPwd",
      );
      this.logger.getResponse(logRequest);
    }
    return isValidLogin;
  }


  async webSignIn(pEmail: string, pPwd: string)
  {
    let isValidLogin: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("webSignIn");

      await this.afAuth.signInWithEmailAndPassword(pEmail, pPwd).then(
        async (user: firebase.auth.UserCredential) =>
        {
          let idToken = await user.user.getIdToken();

          let userObj: FirebaseUserModel = {
            displayName: null,
            email: null,
            phoneNumber: null,
            photoURL: null,
            stsTokenManager: null,
            ProviderId: null
          };

          userObj.displayName = user.user.displayName;
          userObj.email = user.user.email;
          userObj.phoneNumber = user.user.phoneNumber ? user.user.phoneNumber : null;
          userObj.photoURL = user.user.photoURL;
          userObj.stsTokenManager = idToken;
          userObj.ProviderId = user.user.providerId;

          this.setUserProfile(userObj);

          isValidLogin = await this.firebaseAuthApi(idToken);
        },
        (error) =>
        {
          isValidLogin = {
            message: error
          };
        })
        .catch((error) =>
        {
          isValidLogin = {
            message: error
          };
        });
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in webSignIn",
        err.toString(),
        "webSignIn",
      );
      this.logger.getResponse(logRequest);
    }
    return isValidLogin;
  }

  async mobileSignIn(pEmail: string, pPwd: string)
  {
    let isValidLogin: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("mobileSignIn");

      let res: any = null;
      try
      {
        res = await FirebaseAuthentication.signInWithEmailAndPassword(pEmail, pPwd);

        if (res == 'OK' || (BASE_CONFIG.PLATFORM == PLATFORM.ios && res == null))
        {
          let userDetails = await FirebaseAuthentication.getCurrentUser();

          let idToken = await FirebaseAuthentication.getIdToken(true);

          let userObj: FirebaseUserModel = {
            displayName: null,
            email: null,
            phoneNumber: null,
            photoURL: null,
            stsTokenManager: null,
            ProviderId: null
          };

          userObj.email = userDetails.email;
          userObj.stsTokenManager = idToken;
          userObj.ProviderId = userDetails.providerId;

          this.setUserProfile(userObj);
          isValidLogin = await this.firebaseAuthApi(idToken);
        }
        else
        {
          isValidLogin = {
            message: res
          };
        }
      }
      catch (err: any)
      {
        isValidLogin = {
          message: err
        };
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in mobileSignIn",
        err.toString(),
        "mobileSignIn",
      );
      this.logger.getResponse(logRequest);
    }
    return isValidLogin;
  }

  ///register flow
  async createEmailPwd(pEmail: string, pPwd: string)
  {
    let created = null;
    try
    {
      try
      {
        created = await this.afAuth.createUserWithEmailAndPassword(pEmail, pPwd);

      } catch (err: any)
      {
        created = {
          message: err
        };
      }
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in createEmailPwd",
        err.toString(),
        "createEmailPwd",
      );
      this.logger.getResponse(body);
    }
    return created;
  }

  //to reset mail
  async passwordResetMail(pEmail: string)
  {
    let mailSentObj;
    try
    {
      if (BASE_CONFIG.IS_WEB)
      { //web
        try
        {
          mailSentObj = await this.afAuth.sendPasswordResetEmail(pEmail);

        } catch (err: any)
        {
          mailSentObj = {
            message: err
          };
        }
      }
      else
      { //mbl
        try
        {
          mailSentObj = await FirebaseAuthentication.sendPasswordResetEmail(pEmail);

        } catch (err: any)
        {
          mailSentObj = {
            message: err
          };
        }

      }
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in passwordResetMail",
        err.toString(),
        "passwordResetMail",
      );
      this.logger.getResponse(body);
    }
    return mailSentObj;
  }

  ///guest login
  async signInAnonymously()
  {
    let isValidLogin = false;
    let res: any = null;
    let idToken = null;

    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("signInAnonymously");

      this.store.dispatch(new GuestUserAction(true));

      if (BASE_CONFIG.IS_WEB)   //web
      {
        let user = await this.afAuth.signInAnonymously();

        if (user)
        {
          idToken = await user.user.getIdToken();
          isValidLogin = await this.firebaseAuthApi(idToken);
        }
      }
      else  //mbl
      {
        res = await FirebaseAuthentication.signInAnonymously();

        if (res == "OK" || (BASE_CONFIG.PLATFORM == PLATFORM.ios && res == null))
        {
          idToken = await this.generateToken();
          isValidLogin = await this.firebaseAuthApi(idToken);
        }
      }
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in signInAnonymously",
        err.toString(),
        "signInAnonymously",
      );
      this.logger.getResponse(body);
    }
    return isValidLogin;
  }

  ///on social type login

  onSocialLogin(loginProvider)
  {
    let provider: firebase.auth.AuthProvider;

    if (loginProvider === SOCIAL_LOGIN_TYPE.GOOGLE)
    {
      provider = new firebase.auth.GoogleAuthProvider();

      return this.loginWithGoogleProvider(
        provider,
        FB_AUTH_TYPE.SIGNIN_WITH_POPUP,
      );
    }
    else if (loginProvider === SOCIAL_LOGIN_TYPE.FACEBOOK)
    {
      provider = new firebase.auth.FacebookAuthProvider();
    }
    else if (loginProvider === SOCIAL_LOGIN_TYPE.TWITTER)
    {
      provider = new firebase.auth.TwitterAuthProvider();
    }
  }

  /// login with social provider

  async loginWithGoogleProvider(provider: firebase.auth.AuthProvider, pAuthType: string)
  {
    let isValidLogin: boolean = false;
    let userObj: FirebaseUserModel = {
      displayName: null,
      email: null,
      phoneNumber: null,
      photoURL: null,
      stsTokenManager: null,
      ProviderId: null
    };

    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("loginWithGoogleProvider");

      switch (pAuthType)
      {
        case FB_AUTH_TYPE.SIGNIN_WITH_POPUP:

          if (BASE_CONFIG.IS_WEB)  //web
          {
            let signInRes: firebase.auth.UserCredential = await this.afAuth.signInWithPopup(provider);

            if (signInRes)
            {
              let idToken = await signInRes.user.getIdToken();
              isValidLogin = await this.firebaseAuthApi(idToken);
              let clonedUser: firebase.User = this.util.cloneDeep(signInRes.user);

              userObj.displayName = clonedUser.displayName;
              userObj.email = clonedUser.email;
              userObj.phoneNumber = clonedUser.phoneNumber;
              userObj.photoURL = clonedUser.photoURL;
              userObj.stsTokenManager = idToken;
              userObj.ProviderId = signInRes.credential.providerId;

              this.setUserProfile(userObj);
            }
          }
          else  //mbl
          {
            await GoogleAuth.signOut(); // signOut incase user previously logged in 

            await GoogleAuth.signIn().then(async googleUser =>
            {
              if (googleUser)
              {
                const credential = firebase.auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);
                let res = await FirebaseAuthentication.signInWithGoogle(credential.idToken, credential.accessToken);

                if (res == "OK" || (BASE_CONFIG.PLATFORM == PLATFORM.ios && res == null))
                {
                  let idToken = await this.generateToken();
                  isValidLogin = await this.firebaseAuthApi(idToken);

                  let clonedGoogleUser = this.util.cloneDeep(googleUser);

                  userObj.displayName = clonedGoogleUser.displayName;
                  userObj.email = clonedGoogleUser.email;
                  userObj.phoneNumber = clonedGoogleUser.phoneNumber;
                  userObj.photoURL = clonedGoogleUser.imageUrl;
                  userObj.stsTokenManager = clonedGoogleUser.idToken;

                  this.setUserProfile(clonedGoogleUser);
                }
              }
            }).catch(err =>
            {
              // dnt need to handle it
            });
          }
          break;

        case FB_AUTH_TYPE.SIGNIN_WITH_REDIRECT:
          break;
        default: break;
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in loginWithGoogleProvider",
        err.toString(),
        "loginWithGoogleProvider",
      );
      this.logger.getResponse(logRequest);
    }

    return isValidLogin;
  }

  ///generate token
  async generateToken()
  {
    let idToken = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("generateNewToken");

      idToken = await FirebaseAuthentication.getIdToken(false);
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in generateNewToken",
        err.toString(),
        "generateNewToken",
      );
      this.logger.getResponse(body);
    }
    return idToken;
  }

  ///generate new token
  async refreshToken()
  {
    let idToken = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("refreshToken");

      idToken = await FirebaseAuthentication.getIdToken(true);
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in refreshToken",
        err.toString(),
        "refreshToken",
      );
      this.logger.getResponse(body);
    }
    return idToken;
  }

  ///set user profile
  setUserProfile(user)
  {
    let firebaseUser = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("setUserProfile");

      firebaseUser = this.util.cloneDeep(user);
      this.store.dispatch(new GuestUserAction(false));

      this.store.dispatch(new UserAction(firebaseUser));
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in setUserProfile",
        err.toString(),
        "setUserProfile",
      );
      this.logger.getResponse(body);
    }
  }

  /// change password
  getChangePwdReq(pOldPwd: string, pNewPwd: string)
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getChangePwdReq....");

      const reqBody: any = {
        oldPassword: pOldPwd,
        newPassword: pNewPwd
      };
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in getChangePwdReq",
        err.toString(),
        "getChangePwdReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  }

  getChangePwdRes(pReq: any): Observable<APIResponse<any>>
  {
    let retValue: Observable<APIResponse<any>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_CHANGE_PWD, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in getChangePwdRes",
        err.toString(),
        "getChangePwdRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

}