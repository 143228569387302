import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { filter, fromEvent, merge, Observable, Observer, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { appConfig } from './app.config';
import { AuthState, FirebaseUserModel } from './model/Login.model';
import { getPostView } from './pages/post/all-post/all-post.selectors';
import { selectIsDarkMode } from './pages/settings/settings.selectors';
import { AppService } from './services/app/app.service';
import { AuthService } from './services/auth/auth.service';
import { FeatureGuideService } from './services/feature-guide.service';
import { HttpService } from './services/http/http.service';
import { LoggerService } from './services/logger/logger.service';
import { ProfileFlowService } from './services/profile/profile-flow.service';
import { AppInitAction, AuthStateAction } from './store/login/login.actions';
import { getAuthState, getUserDetails } from './store/login/login.selectors';
import { BASE_CONFIG } from './util/base-settings';
import { COMPONENT_NAME, LINKS, PATHNAME } from './util/constants';
import { UtilFunctions } from './util/util';
import
  {
    ActionPerformed, PushNotifications, PushNotificationSchema, Token
  } from '@capacitor/push-notifications';
import { selectLoginResponse } from './pages/admin/admin.selector';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit
{
  authState: any = null;
  // isAppInit = new BehaviorSubject(null);
  isPrgrsCompleted: boolean = false;
  viewportHeight: string = '100vh';

  splitPane: string = 'md';
  isWeb: boolean = false;
  canShowHeader: boolean = true;
  showLoading: boolean = true;
  isSearchOpen: boolean = false;
  isDetailPage: boolean = false;
  isGuestUser: boolean = true;
  subscriptions: Subscription[] = [];
  isFitToScreen: boolean = false;
  canShowBackdrop: boolean = true;

  constructor(
    private store: Store,
    private router: Router,
    private platform: Platform,
    private util: UtilFunctions,
    private logger: LoggerService,
    private afAuth: AngularFireAuth,
    private appService: AppService,
    private httpService: HttpService,
    private featureGuideService: FeatureGuideService,
    private authService: AuthService,
    private translateService: TranslateService,
    private profileFlowService: ProfileFlowService,
    private zone:NgZone,

  )
  {
    this.setupThemeListner();
    this.initializeApp();
    this.authService.isAppInit.next(true);
  }

  async ngOnInit()
  {
    this.toHandleBackFlow();

    this.translateService.use(appConfig.langs.default);
    this.httpService.dataSubject.subscribe(res =>
    {
      this.isPrgrsCompleted = null;
      this.isPrgrsCompleted = res;
    });
    let isAuthState = this.store.select(getAuthState()).subscribe((details: AuthState) =>
    {
      if (details)
      {
        if (details.isCurrentUser == null)
        {
          this.authState = true;

        } else
        {
          this.authState = false;
        }
      }
    });

    isAuthState.unsubscribe();

    if (this.authState)
    {
      this.store.dispatch(new AuthStateAction(true));
    }
    else
    {
      this.store.dispatch(new AuthStateAction(false));
    }

    await this.onAuthCheck();
    this.showLoading = false;
  }


  initializeApp()
  {
    try
    {
    if (BASE_CONFIG.IS_DEBUG) console.log(BASE_CONFIG.VERSION);
    
    this.platform.ready().then((res) =>
    {
      GoogleAuth.initialize({
        clientId: '757079808277-hml7u11pk6kb4t4ehhidtssfgas4ugka.apps.googleusercontent.com',
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      });
      this.setupBackButtonListner();
    });

    const platform = Capacitor.getPlatform();

    if (platform)
    {
      BASE_CONFIG.PLATFORM = platform;
      BASE_CONFIG.IS_WEB = this.util.toCheckPlatformIsWeb();
      this.isWeb = BASE_CONFIG.IS_WEB;

      // if (BASE_CONFIG.IS_WEB)
      // {
      //   require("../scss/web.scss");
      // }
      // else
      // {
      //   require("../scss/app.scss");
      // }
      const viewportHeight = this.util.getViewportHeight();
      this.viewportHeight = viewportHeight ? viewportHeight + 'px' : '100vh';
    }

    // this.loadStyle(this.isWeb);

    if (this.isWeb)
    {
      const network$ = this.createOnline$()
        .subscribe(isOnline => 
        {
          if (!isOnline)
          {
            this.util.navigate(PATHNAME.NO_NETWORK, null, true);
          }
        });

      this.onRouteChange(this.router.url);

      const router$ = this.router.events
        .subscribe(event =>
        {
          if (event instanceof NavigationEnd)
          {
            this.onRouteChange(this.router.url);
          }
        });

      // on search function changes
      const search$ = this.appService.toggleSearch
        .subscribe(isSearchOpened =>
        {
          this.isSearchOpen = isSearchOpened;
        });

      // detail page image zoom/fit
      const postView$ = this.store.select(getPostView())
        .subscribe(isFitToScreen => 
        {
          this.isFitToScreen = isFitToScreen;
        });

      this.subscriptions.push(network$, router$, search$, postView$);
    } else {
      const scriptElements: HTMLCollectionOf<Element> = document.getElementsByClassName('analytics');
      const scriptArray = Array.from(scriptElements)
      scriptArray.map(element => element.remove());
    }

    this.checkGuestUser();
    this.util.toGetDeviceSettings();
    this.store.dispatch(new AppInitAction(true));
    BASE_CONFIG.VIEWPORT_HEIGHT = this.util.getViewportHeight();

    this.addBackdrop();
    this.processDeeplink();
    this.configPushNotification();
    // document.body.classList.toggle('dark', true);
    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        'error in initializeApp',
        error.toString(),
        'initializeApp'
      );
      this.logger.getResponse(logRequest);
    }
  }
  processDeeplink()
  {
    try
    {
      if(BASE_CONFIG.IS_DEBUG)  console.log('processDeeplink');
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) =>
      {
        this.zone.run(() =>
        {
          if (!event || !event.url) { return; }

          // getting project id from url
          const domain = LINKS.DYNAMIC_BASE_URL + '?post-id=';

          const postId = event.url.split(domain).pop();

          if (!postId) { return; }

        this.store.selectOnce(getUserDetails())
            .subscribe((details:FirebaseUserModel) =>
            {
              if (details&&details.stsTokenManager )
              {
               

                this.util.navigate(PATHNAME.POST_DETAIL, { queryParams: { 'post-id': postId} });
              }
             
            });
       
        });
      });
    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        'error in processDeeplink',
        error.toString(),
        'processDeeplink'
      );
      this.logger.getResponse(logRequest);
    }
  }
  configPushNotification(){
    try{
    if(!BASE_CONFIG.IS_WEB){
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
  
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          console.log('Push registration success, token: ' + token.value);
        }
      );
  
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.error('Error on registration: ' + JSON.stringify(error));
        }
      );
  
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('Push received: ' + JSON.stringify(notification));
        }
      );
  
      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('Push action performed: ' , notification);
          if(notification.notification){
            this.store.selectOnce(getUserDetails())
            .subscribe((details:FirebaseUserModel) =>
            {
              if (details&&details.stsTokenManager )
              {
               

                // this.util.navigate(PATHNAME.POST_DETAIL, { queryParams: { 'post-id': } });
              }
             
           
       
        });
          }
          
        }
      );
      }
    } 
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        'error in configPushNotification',
        error.toString(),
        'configPushNotification'
      );
      this.logger.getResponse(logRequest);
    }
  }
  onRouteChange(pCurrentUrl: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onRouteChange");

      const url = pCurrentUrl.split('?')[0];

      // url == '/' check for initially root url without other url

      this.canShowHeader = (url == PATHNAME.POST || url == PATHNAME.POST_DETAIL);
      this.isDetailPage = url == PATHNAME.POST_DETAIL ? true : false;

      // this.canShowHeader = (url == PATHNAME.POST || url == PATHNAME.POST_DETAIL || url == PATHNAME.SAMPLE_POST_DETAIL);
      // this.isDetailPage = url == PATHNAME.POST_DETAIL || url == PATHNAME.SAMPLE_POST_DETAIL ? true : false;
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        "error in onRouteChange",
        err.toString(),
        "onRouteChange",
      );
      this.logger.getResponse(logRequest);
    }
  }

  checkGuestUser()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("checkGuestUser");

      const guestUser$ = this.store.select(getUserDetails())
        .subscribe((userModel: FirebaseUserModel) =>
        {
          // check token exist or not
          if (userModel && userModel.stsTokenManager)
          {
            // check guest user
            this.isGuestUser = (userModel.email == null) ? true : false;
          }
        });
      this.subscriptions.push(guestUser$);

      // guestUser$.unsubscribe();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        "error in checkGuestUser",
        err.toString(),
        "checkGuestUser",
      );
      this.logger.getResponse(logRequest);
    }
  }

  ngOnDestroy(): void
  {
    try
    {
      this.subscriptions
        .forEach((subscription) => subscription.unsubscribe());
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        "error in ngOnDestroy",
        err.toString(),
        "ngOnDestroy",
      );
      this.logger.getResponse(logRequest);
    }
  }

  setupThemeListner()
  {
    this.store.select(selectIsDarkMode()).subscribe((isDarkMode: boolean) =>
    {
      if (isDarkMode)
      {
        document.body.setAttribute('app-theme', 'dark');
      } else
      {
        document.body.removeAttribute('app-theme');
      }
    });
  }

  setupBackButtonListner()
  {
    App.addListener('backButton', (data) =>
    {
      if (!data.canGoBack)
      {
        App.exitApp();
      }
    });
  }

  ngAfterViewInit()
  {
    this.platform.ready().finally(() =>
    {
      setTimeout(() => SplashScreen.hide());
    });
  }

  onAuthCheck()
  {
    return new Promise((resolve, reject) =>
    {
      this.afAuth.onAuthStateChanged(async (_user) =>
      {
        this.authState = true;
        this.store.dispatch(new AuthStateAction(this.authState));
        if (_user) { }
        resolve(true);
      });
    });
  }

  toHandleBackFlow()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toHandleBackFlow");

      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) =>
        {
          if (event.navigationTrigger === 'popstate')
          {
            // if (event.url == '/profile/followers' || event.url == '/profile/following' || event.url.split('?')[0] == '/profile')
            if (event.url == PATHNAME.FOLLOWERS_LIST || event.url == PATHNAME.FOLLOWING_LIST || event.url.split('?')[0] == PATHNAME.PROFILE)
            {
              this.profileFlowService.canProceed.next(null);
              this.profileFlowService.removeUserName();

              // close comment or any other modals if opened
              this.util.closeModal();
            }
            // if(event.url.split('?')[0] == PATHNAME.POST_DETAIL || event.url.split('?')[0] == PATHNAME.SAMPLE_POST_DETAIL){
            //   this.util.navigate(PATHNAME.POST)
            // }
          }
        });
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        "error in toHandleBackFlow",
        err.toString(),
        "doRefresh",
      );
      this.logger.getResponse(logRequest);
    }
  }

  createOnline$()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("createOnline$");

      return merge<any>(
        fromEvent(window, 'offline').pipe(map(() => false)),
        fromEvent(window, 'online').pipe(map(() => true)),
        new Observable((sub: Observer<boolean>) =>
        {
          sub.next(navigator.onLine);
          sub.complete();
        }));
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        "error in createOnline$",
        err.toString(),
        "createOnline$",
      );
      this.logger.getResponse(logRequest);
    }
  }

  addBackdrop()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("addBackdrop");

      const feature$ = this.featureGuideService.canShowBackdrop
        .subscribe(value =>
        {
          this.canShowBackdrop = value;
        });

      this.subscriptions.push(feature$);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        "error in addBackdrop",
        err.toString(),
        "addBackdrop",
      );
      this.logger.getResponse(logRequest);
    }
  }

  // loadStyle(pIsMobile: boolean)
  // {
  //   try
  //   {
  //     if (BASE_CONFIG.IS_DEBUG) console.log("loadStyle");

  //     const head = this.document.getElementsByTagName('head')[0];

  //     let scssFile: string = pIsMobile ? '/src/scss/app.scss' : '/src/scss/web.scss';

  //     const style = this.document.createElement('link');
  //     style.id = 'client-theme';
  //     style.rel = 'stylesheet';
  //     style.href = scssFile;

  //     head.appendChild(style);
  //   }
  //   catch (err)
  //   {
  //     let logRequest = this.logger.buildRequest(
  //       COMPONENT_NAME.APP_COMPONENT,
  //       "error in loadStyle",
  //       err.toString(),
  //       "loadStyle",
  //     );
  //     this.logger.getResponse(logRequest);
  //   }
  // }
}



