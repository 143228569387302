import { JwtInterceptor } from './services/http/jwt.interceptors';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { CameraSideMenuModule } from './components/camera-side-menu/camera-side-menu.module';
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// import { ProfileMenuModule } from './components/profile-menu/profile-menu.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import { environment } from '../environments/environment';
import { customAnimation } from './components/animations/custom.animation';
import { CustomSkeletonModule } from './components/custom-skeleton/custom-skeleton.module';
import { GuestUserModelModule } from './components/guest-user-model/guest-user-model.module';
import { WebHeaderModule } from './components/web-header/web-header.module';
import { CoreModule } from './core/core.module';
import { CategoryPageModule } from './pages/category/category.module';
export function HttpLoaderFactory(http: HttpClient)
{
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

// const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyDTFDL2wg78gs9XyEmJaFFp0D5ZLKlYprE",
//   authDomain: "diy-science-projects.firebaseapp.com",
//   databaseURL: "https://diy-science-projects.firebaseio.com",
//   projectId: "diy-science-projects",
//   storageBucket: "diy-science-projects.appspot.com",
//   messagingSenderId: "536884424578",
//   appId: "1:536884424578:web:963fb01f2188b51998299b"
// };
firebase.initializeApp(environment.FIREBASE_CONFIG);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ navAnimation: customAnimation }),
    AppRoutingModule,
    // CameraSideMenuModule,
    // NgxDaterangepickerMd.forRoot({}),
    // ProfileMenuModule,
    CoreModule,
    GuestUserModelModule,
    CategoryPageModule,
    CustomSkeletonModule,
    WebHeaderModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(), //Firebase
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [Geolocation,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
