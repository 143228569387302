import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { StoreUpdateTypes } from "../../util/constants";
import { UtilFunctions } from "../../util/util";
import { AppUserProfileDetailAction, IsAppUserAction, UpdateAppUsernameAction, UpdateAppUserProfileAction, UpdateVisitedPageAction, UserProfileDetailAction, } from "./profile.actions";
import { ProfileDetail, ProfileDetailsModal, VisitedPageDetail } from "./profile.modal";

export const ProfileDetailsState: ProfileDetailsModal = {
  appUserProfile: null,
  userProfile: null,
  isAppUser: null,
  visitedPage: []
};

export const PROFILE_DETAIL_TOKEN = new StateToken<ProfileDetailsModal>('profileDetails');

@State({
  name: PROFILE_DETAIL_TOKEN,
  defaults: ProfileDetailsState,
})

@Injectable()
export class ProfileState
{
  constructor(private util: UtilFunctions) { }

  @Action(AppUserProfileDetailAction)
  updateAppUserProfileDetails(ctx: StateContext<ProfileDetailsModal>, action: AppUserProfileDetailAction)
  {
    ctx.patchState({ appUserProfile: action.profileDetail });
  }

  @Action(UpdateAppUsernameAction)
  updateAppUsername(ctx: StateContext<ProfileDetailsModal>, action: UpdateAppUsernameAction)
  {
    const state = ctx.getState();

    let stateObj: ProfileDetail = this.util.cloneDeep(state.appUserProfile);

    if (stateObj != null)
    {
      stateObj.userName = action.username;
      ctx.patchState({ appUserProfile: stateObj });
    }
  }

  @Action(UserProfileDetailAction)
  updateUserProfileDetails(ctx: StateContext<ProfileDetailsModal>, action: UserProfileDetailAction)
  {
    ctx.patchState({ userProfile: action.profileDetail });
  }

  @Action(IsAppUserAction)
  updateIsAppUser(ctx: StateContext<ProfileDetailsModal>, action: IsAppUserAction)
  {
    ctx.patchState({ isAppUser: action.isAppUser });
  }

  @Action(UpdateVisitedPageAction)
  updateProfileHistory(ctx: StateContext<ProfileDetailsModal>, action: UpdateVisitedPageAction)
  {
    const state = ctx.getState();
    let stateObj: VisitedPageDetail[] = [];

    if (action.updateType === StoreUpdateTypes.Add)
    {
      stateObj = this.util.cloneDeep(state.visitedPage);

      if (stateObj !== null && action.visitedPage)
      {
        stateObj = [...stateObj, action.visitedPage];
      }
    }
    else if (action.updateType === StoreUpdateTypes.Remove)
    {
      let newLs = [];
      let tempVisitedPage: VisitedPageDetail[] = this.util.cloneDeep(state.visitedPage);

      if (stateObj && tempVisitedPage)
      {
        newLs = tempVisitedPage.slice(0, -1);
      }
      stateObj = this.util.cloneDeep(newLs);
    }

    // default update method is reset
    ctx.patchState({ visitedPage: stateObj });
  }

  @Action(UpdateAppUserProfileAction)
  UpdateDp(ctx: StateContext<ProfileDetailsModal>, action: UpdateAppUserProfileAction)
  {
    const state = ctx.getState();

    let stateObj: ProfileDetail = state.appUserProfile;
    stateObj.dpUrl = action.dpUrl;

    ctx.patchState({ appUserProfile: stateObj });
  }

}
