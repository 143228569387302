import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterStateSerializer } from '@ngxs/router-plugin';
import { AuthHandler } from './auth-guard/auth-guard.handler';
import { AppErrorHandler } from './error-handler/app-error-handler.service';
import { CustomRouterStateSerializer } from './router/custom-router-serializer';
import { ngxsImports } from './store';

@NgModule({
  imports: [
    /* Angular */
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    /* NGXS */
    ...ngxsImports,

    /* NGX TRANSLATE */
    // TranslateModule.forRoot({
    //   defaultLanguage: appConfig.langs.default,
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '../../assets/i18n/', '.json'),
    //     deps: [HttpClient],
    //   },
    // }),
  ],
  declarations: [],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [AuthHandler],
      multi: true,
    },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
  ],
  exports: [FormsModule, TranslateModule],
})
export class CoreModule
{
  constructor() { }
}
