import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { appConfig } from '../../app.config';
import { FirebaseUserModel } from '../../model/Login.model';
import { SettingsModel } from '../../model/settings.model';
import { AuthService } from '../../services/auth/auth.service';
import { LoggerService } from '../../services/logger/logger.service';
import { getUserDetails } from '../../store/login/login.selectors';
import { getIsUserLoggedIn, getSettings } from '../../store/settings/settings.selectors';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, PATHNAME } from '../../util/constants';
import { UtilFunctions } from '../../util/util';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardAfterLoginService implements CanActivate
{
  @Select(getIsUserLoggedIn()) IsUserLoggedIn: Observable<boolean>;

  isUserLoggedInBool = false;

  constructor(
    private store: Store,
    private logger: LoggerService,
    private authService: AuthService,
    private router: Router,
    private navController: NavController,
  )
  {
    this.IsUserLoggedIn.subscribe(res =>
    {
      this.isUserLoggedInBool = res;
      if (!res)
      {
        this.authService.signInAnonymously();
      }
    });
  }

  canActivate(): Observable<boolean>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("AuthGuardAfterLoginService-AuthGuard");

      return this.store.select(getUserDetails()).pipe(
        map((userModel: FirebaseUserModel) =>
        {
          if (!this.isUserLoggedInBool)
          {
            return true;
          }
          else
          {
            if (userModel && userModel.stsTokenManager)
            {
              return true;
            }

            if (this.router.url !== PATHNAME.REGISTER && this.router.url !== PATHNAME.FORGOT)
            {
              this.navController.navigateRoot(appConfig.routes.auth.login);
            }
            return false;
          }
        }),
      );
    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_GUARD,
        "error in canActivate",
        error.toString(),
        "canActivate"
      );
      this.logger.getResponse(logRequest);
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardBeforeLoginService implements CanActivate
{
  constructor(
    private store: Store,
    private logger: LoggerService,
    private navController: NavController
  ) { }

  canActivate(): Observable<boolean>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("AuthGuardBeforeLoginService-AuthGuard");

      return this.store.select(getUserDetails()).pipe(
        map((userModel: FirebaseUserModel) =>
        {
          if (userModel && userModel.stsTokenManager)
          {
            this.navController.navigateRoot(PATHNAME.POST);
            return false;
          }
          return true;
        }),
      );
    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_GUARD,
        "error in canActivate",
        error.toString(),
        "canActivate"
      );
      this.logger.getResponse(logRequest);
    }
  }
}


@Injectable({ providedIn: 'root' })
export class isCategorySelected implements CanActivate
{
  constructor(
    private store: Store,
    private logger: LoggerService,
    private navController: NavController
  ) { }

  canActivate(): Observable<boolean>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("isCategorySelected-AuthGuard");

      return this.store.select(getSettings()).pipe(
        map((settingsModel: SettingsModel) =>
        {
          if (settingsModel && settingsModel.isCategorySelected)
          {
            this.navController.navigateRoot(PATHNAME.POST);
            return false;
          }
          else
          {
            return true;
          }
        })
      );
    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_GUARD,
        "error in canActivate",
        error.toString(),
        "canActivate"
      );
      this.logger.getResponse(logRequest);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class isFollowerSuggSelected implements CanActivate
{
  constructor(
    private store: Store,
    private logger: LoggerService,
    private navController: NavController
  ) { }

  canActivate(): Observable<boolean>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("isFollowerSuggSelected-AuthGuard");

      return this.store.select(getSettings()).pipe(
        map((settingsModel: SettingsModel) =>
        {
          if (settingsModel && settingsModel.isFollowSuggSelected)
          {
            this.navController.navigateRoot(PATHNAME.POST);
            return false;
          }
          else
          {
            return true;
          }
        })
      );
    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_GUARD,
        "error in canActivate",
        error.toString(),
        "canActivate"
      );
      this.logger.getResponse(logRequest);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class isProfileVisited implements CanActivate
{
  constructor(
    private store: Store,
    private logger: LoggerService,
    private navController: NavController
  ) { }

  canActivate(): Observable<boolean>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("isProfileVisited-AuthGuard");

      return this.store.select(getSettings()).pipe(
        map((settingsModel: SettingsModel) =>
        {
          if (settingsModel && settingsModel.isProfileVisited)
          {
            this.navController.navigateRoot(PATHNAME.POST);
            return false;
          }
          else
          {
            return true;
          }
        })
      );
    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_GUARD,
        "error in canActivate",
        error.toString(),
        "canActivate"
      );
      this.logger.getResponse(logRequest);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class checkNetwork implements CanActivate
{
  constructor(
    private util: UtilFunctions,
    private logger: LoggerService,
  ) { }

  canActivate(): boolean
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("checkNetwork-AuthGuard");

      if (navigator.onLine)
      {
        this.util.navigate(PATHNAME.POST, null, true);
        return false;
      }
      else
      {
        return true;
      }
    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_GUARD,
        "error in canActivate",
        error.toString(),
        "canActivate"
      );
      this.logger.getResponse(logRequest);
    }
  }
}

