import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { AuthResponse, AuthState, FirebaseUserModel, loggerModel, UserRoles } from '../../model/Login.model';
import { UtilFunctions } from './../../util/util';
import { AppInitAction, AuthResAction, AuthStateAction, GuestUserAction, LogReqAction, TokenAction, UserAction, UserRoleAction } from './login.actions';


export const UserInitialState: FirebaseUserModel = {
  displayName: null,
  email: null,
  phoneNumber: null,
  photoURL: null,
  stsTokenManager: null,
  ProviderId: null
};

export const USER_TOKEN = new StateToken<FirebaseUserModel>('userDetails');

@State({
  name: USER_TOKEN,
  defaults: UserInitialState,
})

@Injectable()
export class UserState
{
  constructor(private util: UtilFunctions) { }

  @Action(UserAction)
  updateUserDetails(ctx: StateContext<FirebaseUserModel>, action: UserAction)
  {
    const state = ctx.getState();
    ctx.patchState({
      displayName: action.userDetails.displayName,
      email: action.userDetails.email,
      phoneNumber: action.userDetails.phoneNumber,
      photoURL: action.userDetails.photoURL,
      stsTokenManager: action.userDetails.stsTokenManager ? action.userDetails.stsTokenManager : state.stsTokenManager,
      ProviderId: action.userDetails.ProviderId,
    });
    // console.log(ctx)
  }

  @Action(TokenAction)
  updateTokenDetails(ctx: StateContext<FirebaseUserModel>, action: TokenAction)
  {
    const state = ctx.getState();
    const userObj = this.util.cloneDeep(state);
    userObj.stsTokenManager = action.token;
    ctx.patchState({
      stsTokenManager: action.token
    });


  }


}

export const AuthResInitialState: AuthResponse = {
  msg: null,
  result: null,
};

export const AUTH_RES_TOKEN = new StateToken<AuthResponse>('AuthRes');

@State({
  name: AUTH_RES_TOKEN,
  defaults: AuthResInitialState,
})

@Injectable()
export class AuthResState
{
  constructor() { }

  @Action(AuthResAction)
  updateAuthRes(ctx: StateContext<AuthResponse>, action: AuthResAction)
  {


    ctx.patchState({
      msg: action.authResponse.msg,
      result: action.authResponse.result,
    });
  }

}


export const AuthStateInitialState: AuthState = {
  isCurrentUser: null,
  isGuestUser: false,
  isAppInit: false
};

export const AUTH_STATE_TOKEN = new StateToken<AuthState>('authState');

@State({
  name: AUTH_STATE_TOKEN,
  defaults: AuthStateInitialState,
})

@Injectable()
export class AuthStateState
{
  constructor() { }

  @Action(AuthStateAction)
  updateAuthState(ctx: StateContext<AuthState>, action: AuthStateAction)
  {


    ctx.patchState({
      isCurrentUser: action.authStateRes,
    });
  }

  @Action(GuestUserAction)
  updateGuestUser(ctx: StateContext<AuthState>, action: GuestUserAction)
  {


    ctx.patchState({
      isGuestUser: action.isGuestUser,
    });
  }


  @Action(AppInitAction)
  updateIsAppInit(ctx: StateContext<AuthState>, action: AppInitAction)
  {

    ctx.patchState({
      isAppInit: action.isAppInit,
    });
  }
}

export const UserRoleInitialState: UserRoles = {
  userRoles: null
};
export const USER_ROLES = new StateToken<UserRoles>('userRoles');
@State({
  name: USER_ROLES,
  defaults: UserRoleInitialState,
})

@Injectable()
export class UserRoleState
{
  constructor() { }

  @Action(UserRoleAction)
  getUserRolesState(ctx: StateContext<UserRoles>, action: UserRoleAction)
  {
    ctx.patchState({ userRoles: action.userRoles });
  }
}


export const LoggerReqInitialState: loggerModel = {
  logRequest: null
};
export const LOG_REQUEST = new StateToken<loggerModel>('logRequest');
@State({
  name: LOG_REQUEST,
  defaults: LoggerReqInitialState,
})

@Injectable()
export class LogReqState
{
  constructor() { }

  @Action(LogReqAction)
  getUserRolesState(ctx: StateContext<loggerModel>, action: LogReqAction)
  {
    ctx.patchState({ logRequest: action.logReq });
  }
}





