import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsModuleOptions } from '@ngxs/store';
import { environment } from '../../../environments/environment';
import { MessageDetailPageState } from '../../pages/message-detail/message-detail.state';
import { SettingsPageState } from '../../pages/settings/settings.state';
import { AuthStateState, LogReqState, UserRoleState, UserState } from '../../store/login/login.state';
import { SettingsState } from '../../store/settings/settings.state';
// import { MessagesPageState } from '../../tabs/messages/messages.state';
// import { ProfilePageState } from '../../tabs/profile/profile.state';
// import { ServicesPageState } from '../../tabs/services/services.state';
// import { TabsState } from '../../tabs/tabs.state';
import { AuthGuardState } from '../auth-guard/auth-guard.state';
import { PostPageState } from '../../pages/post/all-post/all-post.state';
import { AdminState } from '../../pages/admin/admin.state';
import { FollowersDetailsState } from '../../pages/profile/follow-request/followers.state';
import { ProfileState } from '../../pages/profile/profile.state';


const ngxsMainStates = [
  AuthGuardState,
  // TabsState,

  // ServicesPageState,
  // MessagesPageState,
  // ProfilePageState,
  SettingsPageState,
  MessageDetailPageState,
  AdminState,

  SettingsState,
  UserState,
  AuthStateState,
  ProfileState,
  PostPageState,
  UserRoleState,
  FollowersDetailsState,
  LogReqState
];
const ngxsStorageStates = [
  AuthGuardState,
  // TabsState,
  SettingsPageState,
  SettingsState,
  UserState,
  AuthStateState,
  ProfileState,
  MessageDetailPageState,
  PostPageState,
  AdminState,
  UserRoleState,
  FollowersDetailsState,
  LogReqState
];

const ngxsConfig: NgxsModuleOptions = {
  developmentMode: !environment.production,
  selectorOptions: {
    suppressErrors: false,
    injectContainerState: false,
  },
  compatibility: {
    strictContentSecurityPolicy: true,
  },
};

export const ngxsImports = [
  NgxsModule.forRoot(ngxsMainStates, ngxsConfig),
  NgxsStoragePluginModule.forRoot({
    key: ngxsStorageStates,
  }),
  NgxsLoggerPluginModule.forRoot({ logger: console, collapsed: true, disabled: environment.production }),
  NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
  NgxsFormPluginModule.forRoot(),
  NgxsRouterPluginModule.forRoot(),
];
