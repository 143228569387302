import { createSelector } from "@ngxs/store";
import { AuthResponse, AuthState, FirebaseUserModel, loggerModel, UserRoles } from '../../model/Login.model';
import { AuthResState, AuthStateState, LogReqState, UserRoleState, UserState } from './login.state';


export const getUserDetails = () =>
   createSelector([UserState],
      (state: FirebaseUserModel) => state);

export const getAuthRes = () =>
   createSelector([AuthResState],
      (state: AuthResponse) => state);

export const getAuthState = () =>
   createSelector([AuthStateState],
      (state: AuthState) => state);

export const getUserRoles = () =>
   createSelector([UserRoleState],
      (state: UserRoles) => state ? state.userRoles : null);

export const getUserToken = () =>
   createSelector([UserState],
      (state: FirebaseUserModel) => state ? state.stsTokenManager : null);

export const getLogReq = () =>
   createSelector([LogReqState],
      (state: loggerModel) => state.logRequest);

export const getIsGuestUser = () =>
   createSelector([AuthStateState],
      (state: AuthState) => state ? state.isGuestUser : null);

export const getIsAppinit = () =>
   createSelector([AuthStateState],
      (state: AuthState) => state.isAppInit);
