import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { SettingsUpdateTheme, UserSettingsAction } from './settings.actions';
import { SettingsPageStateModel } from './settings.model';


export const initialState: SettingsPageStateModel = {
  appearance: {
    isDarkMode: false,
  },
  language: {
    activeLanguage: 'en',
  },
  settings: null
};

export const STATE_TOKEN = new StateToken<SettingsPageStateModel>('settings');

@State({
  name: STATE_TOKEN,
  defaults: initialState,
})
@Injectable()
export class SettingsPageState
{
  constructor() { }

  @Action(SettingsUpdateTheme)
  updateTheme(ctx: StateContext<SettingsPageStateModel>, action: SettingsUpdateTheme)
  {
    ctx.patchState({
      appearance: {
        isDarkMode: action.isDarkMode,
      },
    });
  }

  @Action(UserSettingsAction)
  updateUserSettings(ctx: StateContext<SettingsPageStateModel>, action: UserSettingsAction)
  {
    ctx.patchState({
      settings: action.settings,
    });
  }
}
