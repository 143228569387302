import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { appConfig } from './app.config';
import { IsAdmin } from './core/auth-guard/admin.guard.service';
import { AuthGuardAfterLoginService, AuthGuardBeforeLoginService, checkNetwork, isCategorySelected, isFollowerSuggSelected } from './core/auth-guard/auth-guard.service';
import { PATHNAME } from './util/constants';

const routes: Routes = [
  {
    path: '',
    redirectTo: environment.isAdmin ? PATHNAME.ADMIN : appConfig.routes.tabs.root,
    pathMatch: 'full',
  },

  {
    path: environment.isAdmin ? 'admin' : 'auth',
    loadChildren: () =>
    {
      if (environment.isAdmin)
      {
        return import('./pages/admin/admin.module').then(m => m.AdminPageModule);
      }
      else
      {
        return import('./pages/auth/auth.module').then((m) => m.AuthPageModule);
      }
    },
  },

  /* Auth routes */
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
    canActivate: [AuthGuardBeforeLoginService],
  },

  /* Admin*/
  // {
  //   path: PATHNAME.ADMIN,
  //   loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule),
  //   canActivate: [IsAdmin]
  // },

  /* post  */
  {
    path: appConfig.routes.tabs.root,
    loadChildren: () => import('./pages/post/post.module').then(m => m.PostPageModule)
  },

  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule),
    canActivate: [AuthGuardAfterLoginService, isCategorySelected],
  },

  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuardAfterLoginService],
  },

  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
    // canActivate: [AuthGuardAfterLoginService],
  },

  {
    path: 'follower-suggestion',
    loadChildren: () => import('./pages/follower-suggestion/follower-suggestion.module').then(m => m.FollowerSuggestionPageModule),
    canActivate: [AuthGuardAfterLoginService, isFollowerSuggSelected],
  },

  {
    path: 'policies',
    loadChildren: () => import('./pages/policies/policies.module').then(m => m.PoliciesPageModule)
  },

  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },

  {
    path: 'no-network',
    loadChildren: () => import('./pages/no-network/no-network.module').then(m => m.NoNetworkPageModule),
    canActivate: [checkNetwork],
  },
  // new routes must place before username path 

  /* User Flow by userName */
  {
    path: ':userName',
    loadChildren: () => import('./pages/guest-user-profile/guest-user-profile.module').then(m => m.GuestUserProfilePageModule)
  },

  /* Redirect routes */
  {
    path: 'error',
    redirectTo: appConfig.routes.redirectOnError,
  },

  // {
  //   path: '**',
  //   loadChildren: () => import('./components/page-header/page-header.module').then( m => m.PageHeaderModule),
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
