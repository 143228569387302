import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { PostX } from "social-media-api-pkg";
import { BASE_CONFIG } from "../../../util/base-settings";
import { PostChanges, StoreUpdateTypes } from "../../../util/constants";
import { UtilFunctions } from "../../../util/util";
import { PostActions } from "./all-post.actions";
import { PostDetailIdObj, PostDetailObj, PostStateModel } from './all-post.model';

export const postInitState: PostStateModel = {
  posts: null,
  postDetail: null,
  postIds: null,
  postList: null,
  isLoading: false,
  offset: null,
  categoryLs: null,
  postDetailIdObj: null,
  postDetailPostLs: null,
  postDetailObj: null,
  peopleSuggLs: null,
  postDraft: null,
  trendingPosts: null,
  allCategories: null,
  isFitToScreen: false,
  selProducts: [],
  searchedPosts: [],
  savedPosts: [],
  userPosts: []
};

export const POSTS_STATE_TOKEN = new StateToken<PostStateModel>('post');

@State({
  name: POSTS_STATE_TOKEN,
  defaults: postInitState,
})
@Injectable()
export class PostPageState
{
  constructor(private util: UtilFunctions) { }

  /* sd */

  // @Action(PostActions.PostIdsAction)
  // fetchPostIds(ctx: StateContext<PostStateModel>, action: PostActions.PostIdsAction)
  // {
  //   const state = ctx.getState();
  //   const stateObj = this.util.cloneDeep(state);
  //   if (action)
  //   {
  //     if (stateObj.postIds && stateObj.postIds.length > 0)
  //     {
  //       let arr1;
  //       let arr2;
  //       if (stateObj.postIds && action.postIds)
  //       {
  //         if (stateObj.postIds.length > action.postIds.length)
  //         {
  //           arr1 = stateObj.postIds;
  //           arr2 = action.postIds;
  //         } else
  //         {
  //           arr1 = action.postIds;
  //           arr2 = stateObj.postIds;
  //         }
  //       }

  //       if (stateObj.postIds.length > BASE_CONFIG.MAX_POSTS_IN_STORE)
  //       {
  //         stateObj.postIds.splice(0, BASE_CONFIG.REMOVE_POSTS_OF);
  //       }
  //       if (action.toRemove)
  //       {

  //         stateObj.postIds = arr1.filter((object1) =>
  //         {
  //           return !arr2.some((object2) =>
  //           {
  //             return object1.id == object2.id;
  //           });
  //         });

  //       }
  //       else
  //       {
  //         if (action.postIds)
  //         {
  //           /// to get the new enteries in the list
  //           var filteredArr = action.postIds.filter((object1) =>
  //           {
  //             return !stateObj.postIds.some((object2) =>
  //             {
  //               return object1.id == object2.id;
  //             });
  //           });

  //           if (filteredArr.length > 0)
  //           {
  //             for (let i = 0; i < filteredArr.length; i++)
  //             {
  //               stateObj.postIds.push(filteredArr[i]);
  //             }
  //           } else
  //           {
  //             stateObj.postIds = stateObj.postIds.filter((object1) =>
  //             {
  //               return !action.postIds.some((object2) =>
  //               {
  //                 if (object1.id == object2.id)
  //                 {
  //                   object1.isViewed = object2.isViewed;
  //                 }
  //               });
  //             });
  //           }
  //         }

  //       }

  //     } else
  //     {
  //       stateObj.postIds = [];
  //       if (action.postIds)
  //       {

  //         for (let i = 0; i < action.postIds.length; i++)
  //         {
  //           stateObj.postIds.push(action.postIds[i]);
  //         }
  //       }
  //     }
  //     // stateObj.postIds=action.postIds
  //   }

  //   ctx.patchState({
  //     postIds: stateObj.postIds,
  //   });
  // } 

  /* sd */

  @Action(PostActions.PostIdsAction)
  fetchPostIds(ctx: StateContext<PostStateModel>, action: PostActions.PostIdsAction)
  {
    let postIds = [];
    if (action && action.postIds && action.postIds.length > 0)
    {
      postIds = this.util.cloneDeep(action.postIds);
    }

    ctx.patchState({ postIds: postIds });
  }

  @Action(PostActions.PostLsAction)
  savePostLs(ctx: StateContext<PostStateModel>, action: PostActions.PostLsAction)
  {
    let postLs: PostX[] = [];

    if (action && action.postLs)
    {
      // const state = ctx.getState();
      // postLs = this.util.cloneDeep(state.postList);

      // postLs = postLs ? postLs : [];

      // if (postLs)
      // {
      //   if (postLs.length > BASE_CONFIG.MAX_POSTS_IN_STORE)
      //   {
      //     postLs.splice(0, BASE_CONFIG.REMOVE_POSTS_OF);
      //   }

      //   if (action.isRefresh)
      //   {
      //     action.postLs.push(...action.postLs);
      //     postLs = this.util.cloneDeep(action.postLs);
      //   }
      //   else
      //   {
      //     postLs.push(...action.postLs);
      //   }
      // }

      postLs = this.util.cloneDeep(action.postLs);
    }

    ctx.patchState({ postList: postLs });
  }

  @Action(PostActions.PostDetailLsAction)
  savePostDetLs(ctx: StateContext<PostStateModel>, action: PostActions.PostDetailLsAction)
  {
    const state = ctx.getState();
    let postDetailLs = this.util.cloneDeep(state.postDetailPostLs);

    if (action)
    {
      if (postDetailLs && postDetailLs.length > 0)
      {
        if (action.isRefresh)
        {
          if (postDetailLs.length > BASE_CONFIG.MAX_POSTS_IN_STORE)
          {
            postDetailLs.splice(0, BASE_CONFIG.REMOVE_POSTS_OF);
          }
          for (let i = 0; i < action.postLs.length; i++)
          {
            postDetailLs.unshift(action.postLs[i]);
          }
        }
        else
        {
          if (postDetailLs.length > BASE_CONFIG.MAX_POSTS_IN_STORE)
          {
            postDetailLs.splice(0, BASE_CONFIG.REMOVE_POSTS_OF);
          }
          for (let i = 0; i < action.postLs.length; i++)
          {
            postDetailLs.push(action.postLs[i]);
          }
        }
      }
      else
      {
        postDetailLs = [];

        if (action.isRefresh)
        {
          for (let i = 0; i < action.postLs.length; i++)
          {
            postDetailLs.unshift(action.postLs[i]);
          }
        }
        else
        {
          postDetailLs = this.util.cloneDeep(action.postLs);
          // for (let i = 0; i < action.postLs.length; i++)
          // {
          //   stateObj.postDetailPostLs.push(action.postLs[i]);
          // }
        }
      }
    }

    ctx.patchState({ postDetailPostLs: postDetailLs });
  }

  @Action(PostActions.TrendingPostLsAction)
  saveTrendingPostLs(ctx: StateContext<PostStateModel>, action: PostActions.TrendingPostLsAction)
  {
    if (action && action.postLs !== undefined)
    {
      ctx.patchState({ trendingPosts: action.postLs });
    }
  }

  @Action(PostActions.PostDetailAction)
  savedPostDetails(ctx: StateContext<PostStateModel>, action: PostActions.PostDetailAction)
  {
    if (action && action.postDetail !== undefined)
    {
      ctx.patchState({
        postDetail: action.postDetail
      });
    }
  }

  @Action(PostActions.clearPostListAction)
  clearPostList(ctx: StateContext<PostStateModel>, action: PostActions.clearPostListAction)
  {
    if (action && action.post !== undefined)
    {
      ctx.patchState({
        postList: action.post
      });
    }
  }

  @Action(PostActions.ClearPostDetailListAction)
  clearPostDetailList(ctx: StateContext<PostStateModel>, action: PostActions.ClearPostDetailListAction)
  {
    if (action && action.postDetailPostLs !== undefined)
    {
      ctx.patchState({
        postDetailPostLs: action.postDetailPostLs
      });
    }
  }




  @Action(PostActions.clearPostAction)
  clearPosts(ctx: StateContext<PostStateModel>, action: PostActions.clearPostAction)
  {
    const state = ctx.getState();
    let stateObj = this.util.cloneDeep(state);
    stateObj = action.posts;
    ctx.patchState({
      ...stateObj
    });

  }

  @Action(PostActions.PostDetailAction)
  fetchPostDetails(ctx: StateContext<PostStateModel>, action: PostActions.PostDetailAction)
  {
    if (action && action.postDetail !== undefined)
    {
      ctx.patchState({
        postDetail: action.postDetail
      });
    }
  }



  @Action(PostActions.PostOffsetAction)
  fetchPostOffset(ctx: StateContext<PostStateModel>, action: PostActions.PostOffsetAction)
  {
    if (action && action.offset !== undefined)
    {
      ctx.patchState({
        offset: action.offset
      });
    }
  }

  @Action(PostActions.CategoryLsAction)
  postCategory(ctx: StateContext<PostStateModel>, action: PostActions.CategoryLsAction)
  {
    if (action && action.category !== undefined)
    {
      ctx.patchState({
        categoryLs: action.category
      });
    }
  }

  @Action(PostActions.AllCategoriesAction)
  setAllCategories(ctx: StateContext<PostStateModel>, action: PostActions.AllCategoriesAction)
  {
    if (action && action.categories !== undefined)
    {
      ctx.patchState({ allCategories: action.categories });
    }
  }

  @Action(PostActions.PostChangeAction)
  postChanges(ctx: StateContext<PostStateModel>, action: PostActions.PostChangeAction)
  {
    const state = ctx.getState();
    let postLs = this.util.cloneDeep(state.postList);

    loop1: for (let i = 0; i < postLs.length; i++)
    {
      if (action.postid == postLs[i].id)
      {
        switch (action.changes)
        {
          case PostChanges.Like:
            {
              postLs[i].likes = Number(postLs[i].likes) + 1;
              postLs[i].liked = 1;
              break;
            }
          case PostChanges.Unlike:
            {
              postLs[i].likes = Number(postLs[i].likes) - 1;
              postLs[i].liked = 0;
              break;
            }
          case PostChanges.Share:
            {
              postLs[i].shares = Number(postLs[i].shares) + 1;
              break;
            }
          case PostChanges.Added_Comment:
            {
              postLs[i].comments = Number(postLs[i].comments) + 1;
              break;
            }
          case PostChanges.Removed_Comment:
            {
              postLs[i].comments = Number(postLs[i].comments) - 1;
              break;
            }
          case PostChanges.Save:
            {
              postLs[i].isSaved = true;
              break;
            }
          case PostChanges.Remove_saved:
            {
              postLs[i].isSaved = false;
              break;
            }

          default: break;
        }
        break loop1;
      }
    }
    ctx.patchState({ postList: postLs });
  }

  @Action(PostActions.PostDetailChangeAction)
  postDetailsChanges(ctx: StateContext<PostStateModel>, action: PostActions.PostDetailChangeAction)
  {
    const state = ctx.getState();
    let postLs = this.util.cloneDeep(state.postDetailPostLs);

    if (postLs)
    {
      loop1: for (let i = 0; i < postLs.length; i++)
      {
        if (action.postid == postLs[i].id)
        {
          switch (action.changes)
          {
            case PostChanges.Like:
              {
                postLs[i].likes = Number(postLs[i].likes) + 1;
                postLs[i].liked = 1;
                break;
              }
            case PostChanges.Unlike:
              {
                postLs[i].likes = Number(postLs[i].likes) - 1;
                postLs[i].liked = 0;
                break;
              }
            case PostChanges.Share:
              {
                postLs[i].shares = Number(postLs[i].shares) + 1;
                break;
              }
            case PostChanges.Added_Comment:
              {
                postLs[i].comments = Number(postLs[i].comments) + 1;
                break;
              }
            case PostChanges.Removed_Comment:
              {
                postLs[i].comments = Number(postLs[i].comments) - 1;
                break;
              }
            case PostChanges.Save:
              {
                postLs[i].isSaved = true;
                break;
              }
            case PostChanges.Remove_saved:
              {
                postLs[i].isSaved = false;
                break;
              }

            default: break;
          }
          break loop1;
        }
      }
    }
    ctx.patchState({ postDetailPostLs: postLs });
  }

  @Action(PostActions.SearchPostChangeAction)
  searchPostChanges(ctx: StateContext<PostStateModel>, action: PostActions.SearchPostChangeAction)
  {
    const state = ctx.getState();
    let postLs = this.util.cloneDeep(state.searchedPosts);

    loop1: for (let i = 0; i < postLs.length; i++)
    {
      if (action.postId == postLs[i].id)
      {
        switch (action.changes)
        {
          case PostChanges.Like:
            {
              postLs[i].likes = Number(postLs[i].likes) + 1;
              postLs[i].liked = 1;
              break;
            }
          case PostChanges.Unlike:
            {
              postLs[i].likes = Number(postLs[i].likes) - 1;
              postLs[i].liked = 0;
              break;
            }
          case PostChanges.Share:
            {
              postLs[i].shares = Number(postLs[i].shares) + 1;
              break;
            }
          case PostChanges.Added_Comment:
            {
              postLs[i].comments = Number(postLs[i].comments) + 1;
              break;
            }
          case PostChanges.Removed_Comment:
            {
              postLs[i].comments = Number(postLs[i].comments) - 1;
              break;
            }
          case PostChanges.Save:
            {
              postLs[i].isSaved = true;
              break;
            }
          case PostChanges.Remove_saved:
            {
              postLs[i].isSaved = false;
              break;
            }

          default: break;
        }

        break loop1;
      }
    }
    ctx.patchState({ searchedPosts: postLs });
  }


  @Action(PostActions.PostDetailPageAction)
  searchedPostIds(ctx: StateContext<PostStateModel>, action: PostActions.PostDetailPageAction)
  {
    // if (action && action.detailObj != undefined)
    // {
    ctx.patchState({ postDetailIdObj: action.detailObj });
    // }
  }

  @Action(PostActions.PostDetailObjAction)
  postDetailObjAction(ctx: StateContext<PostStateModel>, action: PostActions.PostDetailObjAction)
  {
    ctx.patchState({ postDetailObj: action.detailObj });

  }

  @Action(PostActions.NextOffsetAction)
  postDetailNextOffsetAction(ctx: StateContext<PostStateModel>, action: PostActions.NextOffsetAction)
  {
    const state = ctx.getState();
    let stateObj: PostDetailObj = this.util.cloneDeep(state.postDetailObj);

    stateObj.nextOffset = action.nextoffset;
    ctx.patchState({ postDetailObj: stateObj });

  }


  @Action(PostActions.UpdatePostIndexAction)
  UpdatePostIndex(ctx: StateContext<PostStateModel>, action: PostActions.UpdatePostIndexAction)
  {
    const state = ctx.getState();
    let stateObj: PostDetailIdObj = this.util.cloneDeep(state.postDetailIdObj);

    stateObj.index = action.index;
    ctx.patchState({ postDetailIdObj: stateObj });
  }

  @Action(PostActions.PeopleSuggAction)
  UpdatePeopleSuggLs(ctx: StateContext<PostStateModel>, action: PostActions.PeopleSuggAction)
  {
    ctx.patchState({ peopleSuggLs: action.peopleSuggLs });
  }

  @Action(PostActions.AddPostDraftAction)
  savePostDraft(ctx: StateContext<PostStateModel>, action: PostActions.AddPostDraftAction)
  {
    ctx.patchState({ postDraft: action.postDetail });
  }

  @Action(PostActions.AddVideosToDraftAction)
  updateVideos(ctx: StateContext<PostStateModel>, action: PostActions.AddVideosToDraftAction)
  {
    const state = ctx.getState();
    let stateObj = this.util.cloneDeep(state.postDraft);

    if (stateObj)
    {
      stateObj.videos.push(action.videoDetail);
      ctx.patchState({ postDraft: stateObj });
    }
  }

  @Action(PostActions.PostLikeChangeAction)
  postLikeChanges(ctx: StateContext<PostStateModel>, action: PostActions.PostLikeChangeAction)
  {
    const state = ctx.getState();
    let postIdLs = this.util.cloneDeep(state.postIds);

    for (let i = 0; i < postIdLs.length; i++)
    {
      if (action.postId == postIdLs[i].id)
      {
        switch (action.changes)
        {
          case PostChanges.Like:
            {
              postIdLs[i].likes = Number(postIdLs[i].likes) + 1;
              postIdLs[i].isLiked = true;
              break;
            }
          case PostChanges.Unlike:
            {
              postIdLs[i].likes = Number(postIdLs[i].likes) - 1;
              postIdLs[i].isLiked = false;
              break;
            }

          default: break;
        }
        break;
      }
    }
    ctx.patchState({ postIds: postIdLs });
  }

  @Action(PostActions.PostViewChangeAction)
  updatePostViewChanges(ctx: StateContext<PostStateModel>, action: PostActions.PostViewChangeAction)
  {
    ctx.patchState({ isFitToScreen: action.isFitToScreen });
  }

  @Action(PostActions.SelProductsAction)
  updateSelProducts(ctx: StateContext<PostStateModel>, action: PostActions.SelProductsAction)
  {
    ctx.patchState({ selProducts: action.selProducts });
  }

  @Action(PostActions.SavedPostLsAction)
  updateSavedPosts(ctx: StateContext<PostStateModel>, action: PostActions.SavedPostLsAction)
  {
    let savedPosts = [];
    if (action.type == StoreUpdateTypes.Add)
    {
      if (action && action.postLs.length > 0)
      {
        savedPosts = this.util.cloneDeep(action.postLs);
      }
    }
    ctx.patchState({ savedPosts: savedPosts });
  }

  @Action(PostActions.UserPostLsAction)
  updateUserPosts(ctx: StateContext<PostStateModel>, action: PostActions.UserPostLsAction)
  {
    let userPosts: PostX[] = [];

    if (action.type == StoreUpdateTypes.Add)
    {
      if (action && action.postLs.length > 0)
      {
        userPosts = this.util.cloneDeep(action.postLs);
      }
    }
    ctx.patchState({ userPosts: userPosts });
  }

  @Action(PostActions.SearchedPostLsAction)
  updateSearchedPosts(ctx: StateContext<PostStateModel>, action: PostActions.SearchedPostLsAction)
  {
    let searchedPosts: PostX[] = [];

    if (action.type == StoreUpdateTypes.Add)
    {
      if (action && action.postLs.length > 0)
      {
        searchedPosts = this.util.cloneDeep(action.postLs);
      }
    }
    ctx.patchState({ searchedPosts: searchedPosts });
  }

  @Action(PostActions.ResetPostStoreAction)
  resetPostStore(ctx: StateContext<PostStateModel>)
  {
    ctx.setState(postInitState);
  }
}