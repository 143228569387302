import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BASE_CONFIG } from '../../util/base-settings';
import { COMPONENT_NAME } from '../../util/constants';
import { LoggerService } from './../../services/logger/logger.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit
{

  @Input() buttonLs: Array<any>;

  btnAction: any;
  // @Output() btnAction = new EventEmitter<any>();

  constructor(
    private logger: LoggerService
  ) { }

  ngOnInit() { }

  trackBy(index)
  {
    return index;
  }

  getSelectedData(pBtn)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getSelectedData....");

      if (pBtn)
      {
        this.btnAction(pBtn);
      }
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        COMPONENT_NAME.POPOVER_PAGE,
        "error in getSelectedData",
        err.toString(),
        "getSelectedData",
      );
      this.logger.getResponse(body);
    }
  }
}
