import { createSelector } from "@ngxs/store";
import { PostStateModel } from "./all-post.model";
import { PostPageState } from "./all-post.state";

export const getPostDetails = () =>
   createSelector([PostPageState], (state: PostStateModel) => state);

export const getPostsFeed = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.postList);

export const getPostsDetailsFeed = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.postDetailPostLs);

export const getTrendingPosts = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.trendingPosts);

export const selectPostIsLoading = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.isLoading);

export const getPostOffset = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.offset);

export const getPostIds = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.postIds);

export const getPostDetailIds = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.postDetailIdObj);

export const getPostDetailObj = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.postDetailObj);

export const getPeopleSuggLs = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.peopleSuggLs);

export const getAddPostDraft = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.postDraft);

export const getAllCategories = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.allCategories);

export const getPostView = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.isFitToScreen);

export const getUserSelCategory = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.categoryLs);

export const getSelProducts = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.selProducts);

export const getSearchedPosts = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.searchedPosts);

export const getSavedPosts = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.savedPosts);

export const getUserPosts = () =>
   createSelector([PostPageState], (state: PostStateModel) => state.userPosts);
