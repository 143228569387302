import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, SERVICE_URL } from '../../util/constants';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { APIResponse } from '../service.model';

@Injectable({
  providedIn: 'root'
})
export class LogoutService
{

  constructor(
    private logger: LoggerService,
    private httpService: HttpService
  ) { }


  logoutRes(): Observable<APIResponse<null>>
  {
    let retValue: Observable<APIResponse<null>>;

    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("logoutRes");

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_LOGOUT, null);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.LOGOUT_SERVICE,
        "error in logoutRes",
        err.toString(),
        "logoutRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }
}
