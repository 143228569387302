// import { HTTP_CODES } from "../config/API_DATA";

import { HTTP_CODES } from "./constants";

export function VALIDATE_SUCCESS_RES(response: any) {
    let returnValue = false;
    // console.log(HTTP_CODES.SUCCESS_STR);
    if (
        response &&
        response.status == HTTP_CODES.SUCCESS &&
        response.body &&
        response.body.status == HTTP_CODES.SUCCESS_STR &&
        response.body.data
    ) {
        returnValue = true;
    } else {
        returnValue = false;
    }

    return returnValue;
}

export function getErrorMessage(response: any) {
    let returnValue;
    if (
        response &&
        response.body &&
        response.body.status == HTTP_CODES.ERROR_STR &&
        response.body.error &&
        response.body.error.length > 0
    ) {

        returnValue = response.body;
    } else {
        returnValue = null;
    }

    // console.log(JSON.stringify(returnValue.error[0]));

    return returnValue;
}