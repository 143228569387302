import { environment } from '../../environments/environment';


export class BASE_CONFIG
{
    static readonly IS_DEBUG = !environment.production;
    static readonly VERSION = environment.version;
    static readonly LOGGER_URL = environment.loggerUrl;
    static readonly LOG_APP_ID = environment.logAppId;
    static readonly BASE_URL = environment.baseUrl;
    static readonly SHARE_URL = environment.shareUrl;
    static readonly DATE_FORMAT = 'dd-mmm-yyyy';
    static DEVICE_ID = null;
    static DEVICE = null;
    static NETWORK_STATUS = null;
    static PLATFORM = null;
    static orgId = "5408d9ae-df08-11eb-90b5-778bb5378171";
    static MIN_NO_OF_TABLE_ROWS = 10;
    static REMOVE_POSTS_OF = 50;
    static MAX_POSTS_IN_STORE = 100;
    static IS_WEB = false;
    static LATITUDE = null;
    static LONGITUDE = null;
    static VIEWPORT_HEIGHT = null;
}
