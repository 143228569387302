import { Injectable } from "@angular/core";
import { FirebaseMessaging } from "@awesome-cordova-plugins/firebase-messaging";
import { DevicesService } from "../services/devices/devices.service";
import { LoggerService } from "../services/logger/logger.service";
import { getErrorMessage, VALIDATE_SUCCESS_RES } from "./apiValidate";
import { BASE_CONFIG } from "./base-settings";
import { FILE_NAME } from "./constants";
import { UtilFunctions } from "./util";

@Injectable({
  providedIn: 'root',
})
export class UserDevices
{
  constructor(
    private util: UtilFunctions,
    private logger: LoggerService,
    private deviceService: DevicesService,
  ) { }

  async checkUserDevice()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("checkUserDevice");

      let deviceId: string | void = null;

      if (!BASE_CONFIG.IS_WEB)
      {
        deviceId = await FirebaseMessaging.getToken().then().catch(err =>
        {
          let logRequest = this.util.createLogRequest(FILE_NAME.USER_DEVICE, "FirebaseMessaging.getToken()", err);
          this.logger.getResponse(logRequest);
        });

        if (deviceId)
        {
          this.apiGetAllDeviceId(deviceId);
        }
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_DEVICE,
        "error in checkUserDevice",
        err.toString(),
        "checkUserDevice",
      );
      this.logger.getResponse(logRequest);
    }
  }


  apiGetAllDeviceId(deviceId: string)
  {
    let retValue: any;

    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("apiGetAllDeviceId");

      const getAllDeviceReq = this.deviceService.apiGetAllDeviceReq();

      if (getAllDeviceReq)
      {
        const apiRes$ = this.deviceService.apiGetAllDeviceRes(getAllDeviceReq);

        apiRes$.subscribe({
          next: (res: any) =>
          {
            if (VALIDATE_SUCCESS_RES(res))
            {
              if (res.body.data.userDevices)
              {
                let isAdded = false;
                let userDevices = res.body.data.userDevices;
                for (let i = 0; i < userDevices.length; i++)
                {
                  if (userDevices[i].devicetoken == deviceId)
                  {
                    isAdded = true;
                    break;
                  }
                }
                if (
                  (userDevices && userDevices.length == 0) ||
                  userDevices == null || !isAdded
                )
                {
                  if (deviceId)
                  {
                    this.addDeviceId(BASE_CONFIG.PLATFORM, deviceId);
                  } else
                  {
                    throw "err: didnt get deviceId";
                  }
                }
              }
            }
            else
            {
              const error = getErrorMessage(res);
              const isJwt = this.util.handleJWT(res);
              if (!isJwt) { }
            }
          }
        });
      } else
      {
        throw "invalid request";
      }
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_DEVICE,
        "error in apiGetAllDeviceId",
        err.toString(),
        "apiGetAllDeviceId"
      );
      this.logger.getResponse(body);
    }

    return retValue;
  }

  addDeviceId(platform, deviceID)
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("addDeviceId");

      const getAllDeviceReq = this.deviceService.apiAddDeviceReq(platform, deviceID);

      if (getAllDeviceReq)
      {
        const apiRes$ = this.deviceService.apiAddDeviceRes(getAllDeviceReq);
        apiRes$.subscribe({
          next: (res: any) =>
          {
            if (VALIDATE_SUCCESS_RES(res))
            {
              const getAllDeviceRes = res.body.data;
              retValue = getAllDeviceRes;
            }
            else
            {
              const error = getErrorMessage(res);

              const isJwt = this.util.handleJWT(error);
              if (!isJwt) { }
            }
          }
        });
      }
      else
      {
        throw "invalid request";
      }
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_DEVICE,
        "error in addDeviceId",
        err.toString(),
        "addDeviceId"
      );
      this.logger.getResponse(body);
    }
    return retValue;
  }
}