import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { BASE_CONFIG } from "../util/base-settings";

export class FormValidators
{
  static scrollToErr(pClass?: string): ValidatorFn
  {
    let retValue = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log('scrollToErr');
      setTimeout(function ()
      {
        const firstInvalidControl: HTMLElement = document.querySelector(
          pClass ? (pClass + " .hide-err") : "form .hide-err"
        );
        if (firstInvalidControl)
        {
          let formEle = document.getElementsByTagName("FORM");
          if (formEle && formEle[0])
            formEle[0].classList.add("has_error");

          firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' }); //end , start

        }
      }, 200);

    } catch (err)
    {
      throw err;
    }
    return retValue;
  }
  static alphaWithSpace(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("alphaWithSpace");

      let value: string = control.value;

      if (value && value.match(/(^[A-Za-z_ ]+$)/g) == null)
      {
        retValue = {
          alphaWithSpace: {
            value: value,
          } as ValidationErrors,
        };
      }
    } catch (err)
    {
      throw err;
    }
    return retValue;
  }

  static onlyAlpha(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onlyAlpha");

      let value: string = control.value;

      if (value && value.match(/(^[A-Za-z]+$)/g) == null)
      {
        retValue = {
          onlyAlpha: {
            value: value,
          } as ValidationErrors,
        };
      }
    } catch (err)
    {
      throw err;
    }
    return retValue;
  }

  static onlyNumber(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onlyNumber");

      let value: string = String(control.value);

      if (value && value.match(/(^[0-9]+$)/g) == null)
      {
        retValue = {
          onlyNumber: {
            value: value,
          } as ValidationErrors,
        };
      }
    } catch (err)
    {
      throw err;
    }
    return retValue;
  }

  static numberWithDecimal(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("numberWithDecimal");

      let value: string = String(control.value);
      if (value && value.match(/^\d+(\.\d+)?$/) == null)
      {
        retValue = {
          numberWithDecimal: {
            value: value,
          } as ValidationErrors,
        };
      }
    } catch (err)
    {
      throw err;
    }
    return retValue;
  }

  static alphaNumeric(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("alphaNumeric");

      let value: string = control.value;
      if (value && value.match(/(^[A-Za-z0-9]+$)/g) == null)
      {
        retValue = {
          alphaNumeric: {
            value: value,
          } as ValidationErrors,
        };
      }
    } catch (error)
    {
      throw error;
    }
    return retValue;
  }

  static mobile(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("mobile");

      let value: string = control.value;
      if (value && value.match(/^([0-9\(\)\/\+ \-]*)$/g) == null)
      {
        retValue = {
          mobile: {
            value: value,
          } as ValidationErrors,
        };
      }
    } catch (err)
    {
      throw err;
    }
    return retValue;
  }

  static email(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("email");
      let value: string = control.value;

      if (value && value.match("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$") == null)
      // if (value && value.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$") == null)
      {
        retValue = {
          email: {
            value: value,
          } as ValidationErrors,
        };
      }
    } catch (err)
    {
      throw err;
    }
    return retValue;
  }

  static userName(control: FormControl): ValidatorFn
  {

    let retValue = null;
    try
    {
      let value: string = control.value;

      if (value && value.match(/^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/i) == null)
      {
        retValue = {
          userName: {
            value: value,
          } as ValidationErrors,
        };
      }
    } catch (err)
    {
      throw err;
    }
    return retValue;
  }

  static initialSpace(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      let value: string = control.value;

      if (value && !value.trim())
      {
        retValue = {
          initialSpace: {
            value: value,
          } as ValidationErrors,
        };
      }
    }
    catch (err)
    {
      throw err;
    }
    return retValue;
  }

  static password(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      let value: string = control.value;

      if (value && value.match(/^((?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{8,20}).*$/) == null)
      {
        retValue = {
          password: {
            value: value,
          } as ValidationErrors,
        };
      }
    } catch (error)
    {
      throw error;
    }
    return retValue;
  }


  static samePassword(control: FormControl, matchControl: FormControl): ValidatorFn
  {
    let retValue = null;

    try
    {
      let value: string = control.value;
      let matchValue: string = matchControl.value;

      if (value && matchValue && value == matchValue)
      {
        retValue = {
          same: {

          } as ValidationErrors
        };
      }
    } catch (error)
    {
      throw error;
    }
    return retValue;
  }

  static confirmPassword(control: AbstractControl, matchControl: AbstractControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      let value: string = control.value;
      let matchValue: string = matchControl.value;

      if (value && matchValue && value != matchValue)
      {
        retValue = {
          match: {

          } as ValidationErrors
        };
      }

    } catch (error)
    {

      throw error;

    }
    return retValue;
  }

  static passwordValidator(control: FormGroup): ValidatorFn
  {
    let retValue = null;

    try
    {
      let value: string = control.get("confirmPassword").value;
      let matchValue: string = control.get("newPassword").value;

      if (value && value != matchValue)
      {
        retValue = {
          match: true
        } as ValidationErrors;
      }
    } catch (error)
    {
      throw error;
    }
    return retValue;
  }

  static currencyRange(min: number, max: number): ValidatorFn
  {

    return (control: FormControl) =>
    {

      let retValue = null;
      try
      {

        let value: string = control.value;
        value = (value.toString().replace(/,/g, ''));
        if (value && !((+value) >= min && (+value) <= max))
        {

          retValue = {
            currencyRange: {
              min: min,
              max: max
            }
          } as ValidationErrors;
        }

      } catch (error)
      {
        throw error;
      }
      return retValue;

    };
  }

  static validAmount(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      let value: string = control.value;

      if (value && (value.match(/^([0-9,]{1,19})([.][0-9]{1,2})*$/) == null || ! +value.replace(/[,]/g, "")))
      {
        retValue = {
          validAmount: {
            value: value,
          } as ValidationErrors,
        };

      }
    } catch (error)
    {
      throw error;


    }
    return retValue;
  }

  static notAllowNumerics(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      let value: string = control.value;
      if (value && value.match(/^([^0-9]*)$/g) == null)
      {
        retValue = {
          notAllowNumerics: {
            value: value
          } as ValidationErrors
        };
      }
    } catch (error)
    {
      throw error;
    }
    return retValue;
  }

  static notAllowSpaces(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      let value: string = control.value;
      if (value && value.match(/^\S*$/) == null)
      {
        retValue = {
          notAllowSpaces: {
            value: value
          } as ValidationErrors
        };
      }
    } catch (error)
    {
      throw error;
    }
    return retValue;
  }

  static alphaNumericWithSpaces(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      let value: string = control.value;
      if (value && value.match(/^[a-z0-9]+([-_\s]{1}[a-z0-9]+)*$/i) == null)
      {
        retValue = {
          alphaNumericWithSpaces: {
            value: value
          } as ValidationErrors
        };
      }
    } catch (error)
    {
      throw error;
    }
    return retValue;
  }

  static webLink(control: FormControl): ValidatorFn
  {
    let retValue = null;
    try
    {
      let value: string = control.value;
      if (value && value.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm) == null)
      {
        retValue = {
          webLink: {
            value: value
          } as ValidationErrors
        };
      }
    } catch (error)
    {
      throw error;
    }
    return retValue;
  }
}

