/* eslint-disable @typescript-eslint/no-namespace */
import { UserSettings } from "userbase_package";
import { LanguagesModel } from './settings.model';

export class SettingsUpdateLanguageAction
{
  static readonly type = '[Settings page] update language';
  constructor(public language: LanguagesModel) { }
}

export class SettingsUpdateTheme
{
  static readonly type = '[Settings page] update theme';
  constructor(public isDarkMode: boolean) { }
}
export class UserSettingsAction
{
  static readonly type = '[Settings page] user settings';
  constructor(public settings: UserSettings) { }
}
