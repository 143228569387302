import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { CaptchaTextAction, FiltersAction, LoginResponseAction } from "./admin.action";
import { AdminModel } from "./admin.modal";


export const initialAdminState: AdminModel = {
    loginResponse: null,
    captchaTxt: null,
    filters: null
};

export const MANAGE_ADMIN = new StateToken<AdminModel>('admin');

@State({
    name: MANAGE_ADMIN,
    defaults: initialAdminState
})

@Injectable()
export class AdminState
{
    constructor() { }

    @Action(LoginResponseAction)
    setLoginResponse(ctx: StateContext<AdminModel>, action: LoginResponseAction)
    {
        ctx.patchState({ loginResponse: action.loginResponse });
    }

    @Action(CaptchaTextAction)
    setCaptchaText(ctx: StateContext<AdminModel>, action: CaptchaTextAction)
    {
        ctx.patchState({ captchaTxt: action.captchaTxt });
    }

    @Action(FiltersAction)
    updateFilters(ctx: StateContext<AdminModel>, action: FiltersAction)
    {
        ctx.patchState({ filters: action.filters });
    }
}
