import { NgModule } from '@angular/core';
import { CommonCompModule } from '../../shared/common.component.module';
import { PageHeaderComponent } from './page-header.component';



@NgModule({
  declarations: [PageHeaderComponent],
  imports: [CommonCompModule],
  exports: [PageHeaderComponent],
})
export class PageHeaderModule { }
