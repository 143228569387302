import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService
{

  toggleSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isVideoPlaying: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isMuted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  closeSearch: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  fitToScreen: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  scrollToTop: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }
}
