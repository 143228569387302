/* eslint-disable @typescript-eslint/no-namespace */

// import { MessageModel } from '../../tabs/messages/messages.model';
import { PostCommentModel } from "social-media-api-pkg";
import { CommentsChanges } from '../../util/constants';
import { MessageDetail, MessageDraft } from "./message-detail.model";
export namespace MessageDetailActions
{
  export class FetchMessageDetail
  {
    static readonly type = '[Message detail] Fetch message detail';
    constructor(public postId: string, public offset: number, public isRefresh: boolean,)
    {
    }
  }

  export class FetchMessageDetailSuccess
  {
    static readonly type = '[Message detail] Fetch message detail success';
    constructor(public postcomments: PostCommentModel.PostCommentX[], public nextOffset: boolean, public isRefresh: boolean) { }
  }

  export class FetchMessageOffset
  {
    static readonly type = '[Message detail] offset';
    constructor(public offset: number) { }
  }

  export class FetchMessagePostID
  {
    static readonly type = '[Message detail] postID';
    constructor(public postId: string) { }
  }


  export class FetchMessageComments
  {
    static readonly type = '[Message detail] comments';
    constructor(public comments: PostCommentModel.PostCommentX[]) { }
  }

  export class FetchMessageDetailFail
  {
    static readonly type = '[Message detail] Fetch message detail fail';
    constructor(public error: string) { }
  }

  export class MessageChangesActions
  {
    static readonly type = '[Message changes] changes';
    constructor(public message: PostCommentModel.PostCommentX, public changes: CommentsChanges) { }
  }

  export class Reset
  {
    static readonly type = '[MessageResetActions] reset messages';
    constructor() { }
  }
}

export namespace MessageSendActions
{
  export class Send
  {
    static readonly type = '[MessageSendActions] Send message';
    constructor(public messageId: string, public messageDetail: MessageDetail, public isUpdate: boolean) { }
  }

  export class Update
  {
    static readonly type = '[MessageUpdateActions] Update message';
    constructor(public messageId: string, public message: string) { }
  }

  export class Delete
  {
    static readonly type = '[MessageDeleteActions] delete message';
    constructor(public message: PostCommentModel.PostCommentX) { }
  }
}

export namespace MessageDraftAction
{
  export class Draft
  {
    static readonly type = '[Message Draft] store message draft';
    constructor(public message: MessageDraft) { }
  }
  export class Remove
  {
    static readonly type = '[Message Remove] remove message from draft';
    constructor(public postId: string) { }
  }
  export class Reset
  {
    static readonly type = '[Message Reset] reset message draft';
    constructor() { }
  }
}
