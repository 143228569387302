import { LoggerRequest } from "../../model/logger/logger.model";
import { AuthResponse, FirebaseUserModel } from "../../model/Login.model";

export class UserAction
{
  static readonly type = '[user] state';
  constructor(public userDetails: FirebaseUserModel) { }
}

export class TokenAction
{
  static readonly type = '[token] state';
  constructor(public token: string) { }
}

export class AuthResAction
{
  static readonly type = '[authRes] state';
  constructor(public authResponse: AuthResponse) { }
}

export class GuestUserAction
{
  static readonly type = '[guestuser] state';
  constructor(public isGuestUser: boolean) { }
}

export class AppInitAction
{
  static readonly type = '[isAppInit] state';
  constructor(public isAppInit: boolean) { }
}

export class AuthStateAction
{
  static readonly type = '[authState] state';
  constructor(public authStateRes: boolean) { }
}

export class UserRoleAction
{
  static readonly type = '[userRoles] state';
  constructor(public userRoles: string) { }
}

export class LogReqAction
{
  static readonly type = '[logReq] state';
  constructor(public logReq: LoggerRequest) { }
}
