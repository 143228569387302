import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirebaseAuthentication } from '@awesome-cordova-plugins/firebase-authentication';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../services/logger/logger.service';
import { TokenAction } from '../../store/login/login.actions';
import { getIsGuestUser, getUserRoles, getUserToken } from '../../store/login/login.selectors';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, PATHNAME, SERVICE_URL, UserRole } from '../../util/constants';

import { APIResponse } from '../service.model';

@Injectable({
  providedIn: 'root',
})
export class HttpService 
{
  private BASE_URL: string = environment.apiUrl;

  videosIds = new BehaviorSubject(null);
  dataSubject = new BehaviorSubject(null);
  token: string;
  isGuestUser: boolean;
  isAdminFlow: boolean = environment.isAdmin;

  constructor(
    private httpClient: HttpClient,
    private logger: LoggerService,
    private store: Store,
    private navController: NavController
  )
  {
    const guestUserStore = this.store.select(getIsGuestUser())
      .subscribe((isGuestUser: boolean) =>
      {
        this.isGuestUser = isGuestUser;
      });
  }

  makePostRequest(
    serviceURL: string,
    requestBody: any | null,
    isVideoProcessing: boolean = false,
    isVideoUploading: boolean = false,
    imageUploading: boolean = false,
  ): Observable<APIResponse<any>>
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log('makePostRequest');

      let sToken = this.store.select(getUserToken()).subscribe((token: string) =>
      {
        if (token) { this.token = token; }
      });

      const userStore = this.store.select(getUserRoles()).subscribe((roles) =>
      {
        if (roles) { this.isAdminFlow = roles === UserRole.Admin ? true : false; }
      });

      this.BASE_URL = isVideoProcessing ? environment.videoApiUrl : environment.apiUrl;
      if (
        serviceURL != SERVICE_URL.API_INCREASE_VIEW_COUNT &&
        serviceURL != SERVICE_URL.API_ADD_LIKE &&
        serviceURL != SERVICE_URL.APT_VIDEO_PROCESSING &&
        serviceURL != SERVICE_URL.API_REMOVE_LIKE &&
        serviceURL != SERVICE_URL.API_SAVE_POST &&
        serviceURL != SERVICE_URL.API_REMOVE_SAVE_POST)
      {
        this.dataSubject.next(true);
      }
      userStore.unsubscribe();
      if (
        serviceURL == SERVICE_URL.API_LOGIN ||
        serviceURL == SERVICE_URL.APP_AUTH_URL ||
        serviceURL == SERVICE_URL.API_CREATE_USER
      )
      {
        return this.httpClient.post(this.BASE_URL + serviceURL, requestBody, { headers: this.getHttpHeader(), observe: 'response' })
          .pipe(
            map((response: any) =>
            {
              this.dataSubject.next(false);
              return response;
            }),
          );
      }
      else
      {
        return this.callHttpPost(serviceURL, requestBody, isVideoProcessing, imageUploading, isVideoUploading, this.token);
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HTTP_SERVICE,
        'error in makePostRequest',
        err.toString(),
        'makePostRequest',
      );
      this.logger.getResponse(logRequest);
    }
  }

  getHttpHeader(): HttpHeaders
  {
    try
    {
      return new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': 'en',
      });
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HTTP_SERVICE,
        'error in getHttpHeader',
        err.toString(),
        'getHttpHeader',
      );
      this.logger.getResponse(logRequest);
    }
  }

  callHttpPost(
    serviceURL: string, requestBody: any,
    isVideoProcessing: boolean, imageUploading: boolean,
    isVideoUploading: boolean, pToken: string
  ): Observable<APIResponse<any>>
  {
    try
    {
      let responseType = null;
      responseType = 'json';
      let tokenHeader: HttpHeaders;

      let Authorization = this.isAdminFlow ? pToken : ('Bearer ' + pToken);

      if (imageUploading || isVideoUploading)
      {
        tokenHeader = new HttpHeaders({
          Authorization: Authorization,
          orgid: BASE_CONFIG.orgId,
        });
      }
      else
      {
        tokenHeader = new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: Authorization,
          'Accept-Language': 'en',
          orgid: BASE_CONFIG.orgId,
        });
      }

      if (isVideoUploading)
      {
        this.httpClient.post(this.BASE_URL + serviceURL, requestBody, { headers: tokenHeader, responseType: 'json' })
          .subscribe(response =>
          {
            this.videosIds.next(response);
            // this.addVideosToDraft(response);
            this.dataSubject.next(false);
          });
        // .pipe(
        //   map((response: any) =>
        //   {
        //     this.videosIds.next(response);
        //     this.dataSubject.next(false);
        //     return response;
        //   }),
        //   catchError(this.handleError.bind(this))
        // );
      }
      else if (!isVideoProcessing || !isVideoUploading)
      {
        return this.httpClient
          .post(this.BASE_URL + serviceURL, requestBody, { headers: tokenHeader, observe: 'response' })
          .pipe(
            map((response: any) =>
            {
              this.dataSubject.next(false);
              return response;
            }),
            catchError(this.handleError.bind(this))
          );
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HTTP_SERVICE,
        'error in callHttpPost',
        err.toString(),
        'callHttpPost',
      );
      this.logger.getResponse(logRequest);
    }
  }

  async getToken(): Promise<any>
  {
    try
    {
      if (!BASE_CONFIG.IS_WEB)
      {
        await FirebaseAuthentication.getCurrentUser().then(async (res) =>
        {
          if (res)
          {
            let idToken = await FirebaseAuthentication.getIdToken(true);
            this.store.dispatch(new TokenAction(idToken));
          }
        });
      }
      else
      {
        if (firebase.auth().currentUser)
        {
          firebase
            .auth()
            .currentUser.getIdToken()
            .then((token) =>
            {
              this.store.dispatch(new TokenAction(token));
            });
        } else
        {
          // return  this.store.select(getUserToken())
        }
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HTTP_SERVICE,
        'error in getToken',
        err.toString(),
        'getToken',
      );
      this.logger.getResponse(logRequest);
    }
  }

  private handleError(error: HttpErrorResponse)
  {
    try
    {
      if (error && error.status === 0)
      {
        // A client-side or network error occurred. Handle it accordingly.
        this.navController.navigateRoot([PATHNAME.NO_NETWORK, null, true]);
      }
      else
      {
        this.dataSubject.next(false);
      }
      // else
      // {
      //   // The backend returned an unsuccessful response code.
      //   // The response body may contain clues as to what went wrong.
      //   console.error(`Backend returned code ${error.status}, body was: `, error.error);
      // }

      // Return an observable with a user-facing error message.
      return throwError(() => new Error('Something bad happened; please try again later.'));
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HTTP_SERVICE,
        'error in handleError',
        err.toString(),
        'handleError',
      );
      this.logger.getResponse(logRequest);
    }
  }

  // private addVideosToDraft(pResponse)
  // {
  //   try
  //   {
  //     let videoDetail = pResponse.data;
  //     let videoObj = { id: videoDetail.videoId, url: "https://vz-93ef608a-b75.b-cdn.net/193c4e51-22ec-435b-aa99-92d3df60e71a/playlist.m3u8" };
  //     this.store.dispatch(new PostActions.AddVideosToDraftAction(videoObj));
  //   }
  //   catch (err)
  //   {
  //     throw err;
  //   }
  // }
}
