
export class IntroVisitAction
{
  static readonly type = '[isIntroVisited] state';
  constructor(public isIntroVisited: boolean) { }
}

export class CategorySelectAction
{
  static readonly type = '[categorySelect] state';
  constructor(public categorySelect: boolean) { }
}

export class FollowSuggSelectAction
{
  static readonly type = '[followSuggSelect] state';
  constructor(public followSuggSelect: boolean) { }
}

export class ProfileVisitAction
{
  static readonly type = '[isProfileVisited] state';
  constructor(public isProfileVisited: boolean) { }
}

export class IsUserLoggedInAction
{
  static readonly type = '[isUserLoggedIn] state';
  constructor(public isUserLoggedIn: boolean) { }
}

export class PeopleSuggReqFromAction
{
  static readonly type = '[peopleSugg] state';
  constructor(public reqFrom: string) { }
}
export class GeoLocationAction
{
  static readonly type = '[settings] location state';
  constructor(public location: any) { }
}
export class PostGuideFeatureAction
{
  static readonly type = '[settings] post guide';
  constructor(public isPostGuideShown: boolean) { }
}
export class DetailsGuideFeatureAction
{
  static readonly type = '[settings] detail guide';
  constructor(public isDetailGuideShown: boolean) { }
}