import { FiltersModal } from "./admin.modal";

export class LoginResponseAction
{
    static readonly type = '[LoginResponse] set loginResponse';
    constructor(public loginResponse: any) { };
}

export class CaptchaTextAction
{
    static readonly type = '[Captcha] text';
    constructor(public captchaTxt: string) { };
}

export class FiltersAction
{
    static readonly type = '[Filters] text';
    constructor(public filters: FiltersModal) { };
}