import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import { Share } from '@capacitor/share';
import { ActionSheetController, AlertButton, AlertController, ModalController, NavController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import cloneDeep from 'lodash/cloneDeep';
import { lastValueFrom } from 'rxjs';
import { PopoverComponent } from '../components/popover/popover.component';
import { FirebaseUserModel } from '../model/Login.model';
import { LoginResponseAction } from '../pages/admin/admin.action';
import { LoginPage } from '../pages/auth/login/login.page';
import { MessageDetailActions, MessageDraftAction } from '../pages/message-detail/message-detail.actions';
import { MessageDetailPageStateModel } from '../pages/message-detail/message-detail.model';
import { getMsgDetail } from '../pages/message-detail/message-detail.selectors';
import { PostActions } from '../pages/post/all-post/all-post.actions';
import { postInitState } from '../pages/post/all-post/all-post.state';
import { AppUserProfileDetailAction, UpdateVisitedPageAction, UserProfileDetailAction } from '../pages/profile/profile.actions';
import { ProfileDetail } from '../pages/profile/profile.modal';
import { getAppUserProfile } from '../pages/profile/profile.selectors';
import { SettingsUpdateTheme, UserSettingsAction } from '../pages/settings/settings.actions';
import { AdminService } from '../services/admin/admin.service';
import { LoggerService } from '../services/logger/logger.service';
import { LogoutService } from '../services/logout/logout.service';
import { ErrorAlertVal, Status } from '../services/service.model';
import { AuthStateAction, TokenAction, UserRoleAction } from '../store/login/login.actions';
import { getUserDetails, getUserRoles } from '../store/login/login.selectors';
import { UserInitialState } from '../store/login/login.state';
// import { GeoLocationAction, ProfileVisitAction } from '../store/settings/settings.action';
import { PostX } from "social-media-api-pkg";
import { AppInitAction, GuestUserAction, UserAction } from './../store/login/login.actions';
import { BASE_CONFIG } from './base-settings';
import { APIError, BaseSettings, FILE_NAME, PATHNAME, PLATFORM, PostLinkType, PostStatus, ROUTER_PARAM, StoreUpdateTypes, UserRole } from './constants';
import * as uniqBy from 'lodash.uniqby';

@Injectable({
  providedIn: 'root',
})
export class UtilFunctions
{

  toastPresented: boolean = false;
  constructor(
    private logger: LoggerService,
    private router: Router,
    private navController: NavController,
    private toastController: ToastController,
    private store: Store,
    private popoverController: PopoverController,
    private actionSheetController: ActionSheetController,
    private translate: TranslateService,
    private tokenRenewal: AdminService,
    private logoutService: LogoutService,
    private alertController: AlertController,
    private modalController: ModalController,
    private geolocation: Geolocation,
    private platform: Platform,
  ) { }

  ///to set device settings
  async toGetDeviceSettings(): Promise<void>
  {
    try
    {
      const platform = Capacitor.getPlatform();
      const deviceInfo = await Device.getInfo();
      BASE_CONFIG.DEVICE = deviceInfo;

      if (platform)
      {
        BASE_CONFIG.PLATFORM = platform;
        BASE_CONFIG.IS_WEB = this.toCheckPlatformIsWeb();

        if (platform.toLowerCase() == PLATFORM.web)
        {
          // GoogleAuth.init();
          this.getBrowserLocation();
        }
        else
        {
          // this.getDeviceLocation();
          this.platform.ready().then(() =>
          {
            this.getDeviceLocation();
          });

          BASE_CONFIG.NETWORK_STATUS = (await Network.getStatus()).connected;

          const networkListener = Network.addListener("networkStatusChange", (status) =>
          {
            BASE_CONFIG.NETWORK_STATUS = status.connected;
            if (!status.connected)
            {
              this.navigate(PATHNAME.NO_NETWORK, null, true);
            }
          });
        }
      }
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        'error in toGetDeviceSettings',
        err.toString(),
        'toGetDeviceSettings',
      );
      this.logger.getResponse(logRequest);
    }
  }

  async getDeviceLocation()
  {
    try
    {
      if (!BASE_CONFIG.IS_WEB)
      {
        let location = await this.geolocation.getCurrentPosition()
          // .catch((err: any) => { this.geoLocFail(err); });
          .catch((err: any) => { });

        if (location)
        {
          // let options: NativeGeocoderOptions = {
          //   useLocale: true,
          //   maxResults: 1
          // };
          if (location.coords.latitude != null && location.coords.longitude != null)
          {
            BASE_CONFIG.LATITUDE = location.coords.latitude;
            BASE_CONFIG.LONGITUDE = location.coords.longitude;
          }
          // this.nativeGeocoder.reverseGeocode(location.coords.latitude, location.coords.longitude, options)

          //   .then((result: NativeGeocoderResult[]) =>
          //   {
          //     this.store.dispatch(new GeoLocationAction(result[0]));
          //   })
          //   .catch((error: any) => console.error(error));
        }
      }

      /**
      * This method will configure and initialize the flipper package.
      * @param enabled - boolean true/false to enable/disable flipper
      *        network - boolean true/false to enable network plugin
      *        crash_report - boolean true/false to enable crash reporting
      *        layout_inspector - boolean true/false to enable layout inspector
      *        database - boolean true/false to enable database plugin (sqlite)
      *        database_path - custom database path if database is not stored in application context (Android)
      * @returns void
      * Make sure you have flipper running in background or restart the app for plugins to communicate with flipper
      */
      // Flipper.initialize({
      //     enabled: true,
      //     network: true,
      //     crash_report: true,
      //     layout_inspector: true,
      //     database: false,
      // });

      /**
       * This method will trigger a custom crash notification.
       * @param none
       * @returns void
       */
      // Flipper.emulateCrash({
      //     message: "a24 - Forced Crash",
      // });
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getDeviceLocation",
        err.toString(),
        "getDeviceLocation",
      );
      this.logger.getResponse(logRequest);
    }
  }



  geoLocFail(pErr: any)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("geoLocFail");

      let logRequest = this.createLogRequest(FILE_NAME.UTIL, "geoLocFail", JSON.stringify(pErr));
      this.logger.getResponse(logRequest);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in geoLocFail",
        err.toString(),
        "geoLocFail",
      );
      this.logger.getResponse(logRequest);
    }
  }

  getBrowserLocation()
  {
    try
    {
      if (navigator.geolocation)
      {
        navigator.geolocation.getCurrentPosition(location =>
        {
          if (location)
          {
            BASE_CONFIG.LATITUDE = location.coords.latitude;
            BASE_CONFIG.LONGITUDE = location.coords.longitude;
          }
        });
      }
      // else
      // {
      //   this.toShowToast(this.translate.instant('noGeoLocation'));
      // }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        'error in getBrowserLocation',
        err.toString(),
        'getBrowserLocation',
      );
      this.logger.getResponse(logRequest);
    }
  }
  navigate(pRouteName, pQuery?, pIsReplace?)
  {
    try
    {

      const route = {
        path: pRouteName,

      };
      if (pRouteName)
      {
        let navigationExtras: NavigationExtras = {};
        if (pQuery)
        {
          navigationExtras['queryParams'] = pQuery.queryParams ? pQuery.queryParams : pQuery;

          if (pQuery.state)
          {
            navigationExtras['state'] = pQuery.state;
          }
        }
        if (pIsReplace)
        {
          navigationExtras['replaceUrl'] = true;
        }
        this.navController.navigateRoot([pRouteName], navigationExtras);

      } else
      {

        throw "invalid Route Name";
      }
    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in navigate",
        err.toString(),
        "navigate",
      );
      this.logger.getResponse(logRequest);
    }
  }

  cloneDeep(pValue)
  {

    let retValue;

    try
    {
      retValue = cloneDeep(pValue);
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in cloneDeep",
        err.toString(),
        "cloneDeep",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  getErrorAlertValue(pError, pIsNeedCode?, isAlertExist?): ErrorAlertVal
  {
    let errorAlert: ErrorAlertVal = {
      codeList: [],
      valueList: []
    };
    try
    {
      if (pError && pError.error && pError.error.length)
      {
        if (!(true))
        {//pIsNeedCode
          for (let index = 0; index < pError.error.length; index++)
          {
            if (pError.error[index].error_text)
            {
              errorAlert.valueList.push(pError.error[index].value);
            }
          }
        }
        else if (true)
        { //pIsNeedCode
          for (let index = 0; index < pError.error.length; index++)
          {
            if (pError.error[index].code)
            {
              errorAlert.codeList.push(pError.error[index].code);
            }
            if (pError.error[index].value)
            {
              errorAlert.valueList.push(pError.error[index].value);
            }
          }
        }
      }
      else
      {
        errorAlert.valueList = ["Failed"];
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getErrorAlertValue",
        err.toString(),
        "getErrorAlertValue",
      );
      this.logger.getResponse(logRequest);
    }
    return errorAlert;
  }

  handleJWT(pError): boolean
  {
    let retValue: boolean = false;
    try
    {
      if (pError)
      {
        let queryParams = {} as any;
        let isJwt = false;
        if (pError.error)
        {
          for (let index = 0; index < pError.error.length; index++)
          {
            const err = pError.error[index];
            if (err.code == Status.ERROR_JWT_01 ||
              err.code == Status.ERROR_JWT_02 ||
              err.code == Status.ERROR_AUT2 ||
              err.code == Status.ERROR_AUT01 ||
              err.code == Status.ERROR_PER)
            {
              queryParams[ROUTER_PARAM.PARAM] = [err.code, err.value];
              isJwt = true;

            }
          }
        }

        if (isJwt)
        {
          retValue = true;
          if (queryParams.param.length > 0)
          {
            let userDetails = this.store.select(getUserDetails()).subscribe((details: FirebaseUserModel) =>
            {
              if (details)
              {
                if (this.router.url === PATHNAME.POST)
                {
                  this.navigate(PATHNAME.LOGIN, true);
                }
                else
                {
                  this.navToLogin(queryParams);
                }
              }
            });
            userDetails.unsubscribe();
          }
        } else
        {
          retValue = false;
        }
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in handleJWT",
        err.toString(),
        "handleJWT",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  async navToLogin(queryParams?): Promise<void>
  {
    try
    {
      if (!this.toastPresented)
      {
        this.toastPresented = true;
        this.toastController.create({

          header: 'Session expired',

          position: 'bottom',
          buttons: [
            {
              side: 'start',
              icon: 'star',
              text: 'OK',
              handler: async () =>
              {
                //
                const userStore = this.store.select(getUserRoles()).subscribe(roles =>
                {
                  if (roles && roles === UserRole.Admin)
                  {
                    this.store.dispatch(new TokenAction(null));
                    this.navigate(PATHNAME.ADMIN_LOGIN, true);
                  }
                  else
                  {
                    this.navigate(PATHNAME.LOGIN, true);
                  }
                  this.closeModal();
                });
                userStore.unsubscribe();
                //

                // this.navigate(PATHNAME.LOGIN, true);
              }
            }],

          message: queryParams.param[1] + '<br>' + "<p style='color: #a7a7a7; padding-top:10px'>" + queryParams.param[0] + '</p>',
          // showCloseButton: true
        }).then(res =>
        {
          res.present().then(() =>
          {

          });
        });
      }
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in navToLogin",
        err.toString(),
        "navToLogin",
      );
      this.logger.getResponse(logRequest);
    }

  }

  clearLocalStorage()
  {
    try
    {
      this.store.dispatch(new UserAction(UserInitialState));
      this.store.dispatch(new AuthStateAction(null));
      this.store.dispatch(new SettingsUpdateTheme(null));
      this.store.dispatch(new TokenAction(null));
      this.store.dispatch(new UserSettingsAction(null));
      this.store.dispatch(new PostActions.PostDetailAction(null));
      this.store.dispatch(new PostActions.PostIdsAction(null));
      this.store.dispatch(new PostActions.PostOffsetAction(null));
      this.store.dispatch(new UserRoleAction(null));
      this.store.dispatch(new LoginResponseAction(null));
      this.store.dispatch(new PostActions.clearPostListAction(null));
      this.store.dispatch(new PostActions.ClearPostDetailListAction(null));
      this.store.dispatch(new UserProfileDetailAction(null));
      this.store.dispatch(new AppUserProfileDetailAction(null));
      this.store.dispatch(new PostActions.clearPostAction(postInitState));
      this.store.dispatch(new UpdateVisitedPageAction(StoreUpdateTypes.Remove));
      this.store.dispatch(new PostActions.PostDetailPageAction(null));
      this.store.dispatch(new PostActions.AllCategoriesAction(null));
      this.store.dispatch(new AppInitAction(true));
      this.store.dispatch(new GuestUserAction(false));
      this.store.dispatch(new MessageDetailActions.Reset());
      this.store.dispatch(new PostActions.AddPostDraftAction(null));
      this.store.dispatch(new PostActions.AllCategoriesAction(null));
      this.store.dispatch(new MessageDraftAction.Reset());
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in clearLocalStorage",
        err.toString(),
        "clearLocalStorage",
      );
      this.logger.getResponse(logRequest);
    }
  }

  toGetPopover(ev, buttonLs)
  {
    try
    {
      let popover = this.popoverController.create({
        component: PopoverComponent,
        event: ev,
        translucent: true,
        componentProps: {
          parent: this,
          buttonLs: buttonLs
        }
      });
      // currentPopover = popover;
      popover.then(res =>
      {
        res.present();
      });
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in toGetPopover",
        err.toString(),
        "toGetPopover",
      );
      this.logger.getResponse(logRequest);
    }
  }


  async onGetActionSheet(pButton: any[], pCustomClass: string = '')
  {
    try
    {
      const actionSheet = await this.actionSheetController.create({
        cssClass: 'my-custom-class ' + pCustomClass,
        buttons: pButton
      });
      await actionSheet.present();
    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in onGetActionSheet",
        err.toString(),
        "onGetActionSheet",
      );
      this.logger.getResponse(logRequest);
    }
  }

  async callCommentsSection(pPostId: string)
  {
    try
    {
      let offSet = null;
      let offsetReq = this.store.select(getMsgDetail()).subscribe((details: MessageDetailPageStateModel) =>
      {

        if (details.offset == null || details.postId != pPostId)
        {
          offSet = 0;
        } else
        {
          offSet = details.offset + 1;
        }
      });
      offsetReq.unsubscribe();
      this.store.dispatch(new MessageDetailActions.FetchMessageOffset(offSet));
      //  let that = this
      // setTimeout(async () => {
      await lastValueFrom(this.store.dispatch(new MessageDetailActions.FetchMessageDetail(pPostId, offSet, false)));
      this.store.dispatch(new MessageDetailActions.FetchMessagePostID(pPostId));
      // await this.ionContent.scrollToBottom(0);
      // })
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in callCommentsSection",
        err.toString(),
        "callCommentsSection",
      );
      this.logger.getResponse(logRequest);
    }
  }

  /**
   * @param  {string} pMsg Toast Message
   * @param {string} pToastType Toast Type (Success, Failure, Warning)
   * @param {boolean} isNeedBtn Need Button (Default False)
   * @param {string} pButtonTxt Toast Button Text (Optional)
   * @param {number} pDuration Toast Duration (Optional)
   */
  async toShowToast(pMsg: string, isNeedBtn: boolean = false, pToastType: string = 'dark', pClass: string = '', pDuration: number = 3000)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toShowToast");

      let button: any[] = [];

      if (isNeedBtn)
      {
        button = [
          {
            icon: 'checkmark-outline',
            handler: () =>
            {
              let toast = this.toastController.getTop();

              if (toast)
              {
                toast.then(toast => toast.dismiss());
              }
            }
          }
        ];
      }

      let toast = await this.toastController.create({
        message: this.translate.instant(pMsg),
        duration: pDuration,
        cssClass: 'toast-custom-class ' + pClass,
        position: 'bottom',
        color: pToastType,
        buttons: button
      });



      await toast.present();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in onGetActionSheet",
        err.toString(),
        "onGetActionSheet",
      );
      this.logger.getResponse(logRequest);
    }
  }

  createLogRequest(pComponent, pFunction, pError)
  {
    let errorObj = 'No error object is passed';
    let returnValue;
    try
    {
      if (pError)
      {
        if (typeof pError != 'string')
        {
          if (pError.message && typeof pError.message === 'string')
          {
            errorObj = pError.message;
          } else if (pError.message && typeof pError.message != 'string')
          {
            errorObj = JSON.parse(JSON.stringify(pError.message));
          } else
          {
            errorObj = JSON.parse(JSON.stringify(pError));
          }
        } else if (typeof pError === 'string')
        {
          errorObj = pError;
        }
      } else
      {
        errorObj = 'No error object is passed';
      }
      returnValue = this.logger.buildRequest(
        pComponent,
        "error in " + pFunction,
        errorObj,
        // pError && pError.message ? (typeof pError.message === 'string' ? pError.message : JSON.parse(JSON.stringify(pError.message))) : typeof pError === 'string' ? pError : JSON.parse(JSON.stringify(pError)),
        pFunction, (pError && pError.status) ? pError.status : null
      );
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in createLogRequest",
        err.toString(),
        "createLogRequest",
      );
      this.logger.getResponse(logRequest);
    }
    return returnValue;
  }

  // user role in store
  setUserRole(pUserRole: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("setUserRole");

      this.store.dispatch(new UserRoleAction(pUserRole));
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in setUserRole",
        err.toString(),
        "setUserRole",
      );
      this.logger.getResponse(logRequest);
    }
  }


  navToProfilePage()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToProfilePage");

      let userDetails: ProfileDetail = null;

      const profileStore = this.store.select(getAppUserProfile()).subscribe(userProfile =>
      {
        if (userProfile)
        {
          userDetails = userProfile;
        }
      });
      profileStore.unsubscribe();

      this.navigate(PATHNAME.PROFILE, { queryParams: { username: userDetails.userName } });
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in navToProfilePage",
        err.toString(),
        "navToProfilePage",
      );
      this.logger.getResponse(logRequest);
    }
  }


  async toShareApp(pUrl: string, pIsPostShare: boolean)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log('toShareApp');

      let shareText = pIsPostShare ? 'postShareTxt' : 'profileShareTxt';

      if (!BASE_CONFIG.IS_WEB) // mbl
      {
        let shareRet = await Share.share({
          url: pUrl,
          text: this.translate.instant(shareText),
          title: this.translate.instant('shareTitle'),
          dialogTitle: this.translate.instant('shareDialogue'),
        }).then(res =>
        {
          if (res) { }
        }).catch(err =>
        {
          // dnt need to handle it
        });
      }
      else // web
      {
        // open link in new tab for web
        // this.onOpenLink(pUrl); 

        // show available share apps for web
        this.toShareURL('shareTitle', shareText, pUrl);
      }
    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        'error in toShareApp',
        err.toString(),
        'toShareApp',
      );
      this.logger.getResponse(logRequest);
    }
  }

  onOpenLink(pLink: string)
  {
    try
    {
      window.open(pLink);
    }
    catch (err: any)
    {
      let body = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error while opening link",
        err.toString(),
        "onOpenLink",
      );
      this.logger.getResponse(body);
    }
  }

  async renew()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("renew");
      let isSuccess = await this.tokenRenewal.renewToken().toPromise().then();
      if (!isSuccess)
      {
        let data = { "status": "Error", "error": [{ "code": "JWT01", "value": "Your session has expired. Please enter your email/password again to continue." }] };
        this.handleJWT(data);
      }

    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in renew",
        err.toString(),
        "renew",
      );
      this.logger.getResponse(body);
    }
  }

  copyTextToClipboard(pText: string, pMgs: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("copyTextToClipboard");

      var tempInput = document.createElement("input");
      tempInput.value = pText;
      document.body.appendChild(tempInput);
      tempInput.select();
      //  tempInput.setSelectionRange(0, 99999); /* For mobile devices */

      //Copy the text inside the text field
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      this.toShowToast(pMgs);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in copyTextToClipboard",
        err.toString(),
        "copyTextToClipboard",
      );
      this.logger.getResponse(logRequest);
    }
  }

  checkGuestUser()
  {
    let retValue: boolean = true;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("checkGuestUser");

      let userDetails = this.store.select(getUserDetails()).subscribe(async (details: FirebaseUserModel) =>
      {
        if (details)
        {
          retValue = (details.email == null) ? true : false;
        }
      });
      userDetails.unsubscribe();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in checkGuestUser",
        err.toString(),
        "checkGuestUser",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  async openGuestUserModal()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("openGuestUserModal");

      this.closeModal();

      let modal = await this.modalController.create({
        component: LoginPage,
        swipeToClose: true,
        initialBreakpoint: 1,
        breakpoints: [0],
        componentProps: {
          isGuestFlow: true,
          isIntroSkipped: false,
          isIntroSlidesVisited: true,
          closeModal: this.closeModal.bind(this),
        },
      });
      await modal.present();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in openGuestUserModal",
        err.toString(),
        "openGuestUserModal",
      );
      this.logger.getResponse(logRequest);
    }
  }

  async closeModal()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("closeModal");

      const isModalOpened = await this.modalController.getTop();
      if (isModalOpened)
      {
        await isModalOpened.dismiss();
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in closeModal",
        err.toString(),
        "closeModal",
      );
      this.logger.getResponse(logRequest);
    }
  }

  doLogout(pIsAdmin: boolean): void
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("doLogout");

      this.logoutService.logoutRes().subscribe({
        next: val =>
        {
          // this.clearLocalStorage();
          if (pIsAdmin)
          {
            this.navigate(PATHNAME.ADMIN_LOGIN, null, true);
          }
          else
          {
            this.navigate(PATHNAME.LOGIN, null, true);
          }
        },
        error: (error: HttpErrorResponse) =>
        {

          // this.clearLocalStorage();
          if (pIsAdmin)
          {
            this.navigate(PATHNAME.ADMIN_LOGIN, null, true);
          }
          else
          {
            this.navigate(PATHNAME.LOGIN, null, true);
          }
          let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
            "error in doLogout",
            error.message.toString(),
            "doLogout"
          );
          this.logger.getResponse(logRequest);
        }
      });
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in doLogout",
        err.toString(),
        "doLogout",
      );
      this.logger.getResponse(logRequest);
    }
  }

  // alert modal
  async openAlertModal(pHeader: string, pMsg: string, pButtons: AlertButton[])
  {
    try
    {
      const alert = await this.alertController.create({
        cssClass: 'custom_alert_modal',
        header: pHeader,
        message: pMsg,
        buttons: pButtons
      });

      await alert.present();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        'error in openAlertModal',
        err.toString(),
        'openAlertModal',
      );
      this.logger.getResponse(logRequest);
    }
  }

  getLinkLs(pPosts: PostX[])
  {
    let retValue: any[] = [];
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getLinkLs");

      if (pPosts && pPosts.length > 0)
      {
        let approvedPosts = [];
        let unApprovedPosts = [];

        for (let i = 0; i < pPosts.length; i++)
        {
          const post = pPosts[i];

          if (post && post.postStatus == PostStatus.Published)
          {
            let currPost = this.cloneDeep(post);
            let linkLs: any[] = [];
            let hasMedia: boolean = false;

            if (post.videos && post.videos.length > 0)
            {
              hasMedia = true;
              for (let i = 0; i < post.videos.length; i++)
              {
                const linkObj = { id: this.generateId(), type: PostLinkType.video, src: post.videos[i] };

                linkLs.push(linkObj);
              }
            }

            if (post.mainImage && post.mainImage.length > 0)
            {
              hasMedia = true;
              for (let i = 0; i < post.mainImage.length; i++)
              {
                // const linkObj = { id: this.generateId(), type: PostLinkType.video, src: { url: "https://vz-93ef608a-b75.b-cdn.net/83ac7d2d-555d-47f4-87fe-338c5599f3c8/playlist.m3u8" } };

                const linkObj = { type: PostLinkType.image, src: post.mainImage[i] };

                linkLs.push(linkObj);
              }
            }

            if (hasMedia)
            {
              currPost['linkLs'] = linkLs;
              currPost['canShowIcon'] = false;
              approvedPosts.push(currPost);
            }
          }
          else
          {
            unApprovedPosts.push({ postStatus: post.postStatus, postId: post.id });
          }
        }

        // retValue = this.cloneDeep(uniqBy(approvedPosts, 'id'));
        retValue = this.cloneDeep(approvedPosts);

        if (unApprovedPosts.length > 0)
        {
          let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
            "error in getLinkLs",
            "UnApproved post occurred " + JSON.stringify(unApprovedPosts),
            "getLinkLs",
          );
          this.logger.getResponse(logRequest);
        }
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getLinkLs",
        err.toString(),
        "getLinkLs",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  getFileReader()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getFileReader");

      if (BASE_CONFIG.PLATFORM == PLATFORM.ios)
      {
        const fileReader = new FileReader();
        const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
        return zoneOriginalInstance || fileReader;
      }
      else
      {
        var reader = new FileReader();
        return reader;
      }
    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getFileReader",
        err.toString(),
        "getFileReader",
      );
      this.logger.getResponse(logRequest);
    }
  }

  logFunctionError(pErr: any, pCompName: string, pFuncName: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("logFunctionError");

      const logRequest = this.logger.buildRequest(
        pCompName,
        "error in " + pFuncName,
        pErr.toString(),
        pFuncName,
      );
      this.logger.getResponse(logRequest);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in logFunctionError",
        err.toString(),
        "logFunctionError",
      );
      this.logger.getResponse(logRequest);
    }
  }

  getIndexValues(pIndex: number = BaseSettings.showPeopleSuggCount): [number, number]
  {
    let retValue: [number, number] = [0, BaseSettings.showUsersCount];
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getIndexValues");

      // requireIndices (0, 5) (5, 10), (10, 15)
      // getValues 10, 20, 30
      // (userCount * ((index / postCount) - 1)), (userCount * (index / postCount))

      const calIndex = Math.floor(pIndex / BaseSettings.showPeopleSuggCount);
      const startIndex = BaseSettings.showUsersCount * (calIndex - 1);
      const endIndex = BaseSettings.showUsersCount * calIndex;

      retValue = [startIndex, endIndex];
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getIndexValues",
        err.toString(),
        "getIndexValues",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///to check for a platform
  toCheckPlatformIsWeb(): boolean
  {
    let retValue = false;
    try
    {
      if (BASE_CONFIG.PLATFORM == PLATFORM.web)
      {
        retValue = true;
      } else
      {
        retValue = false;
      }
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        'error in toCheckPlatformIsWeb',
        err.toString(),
        'toCheckPlatformIsWeb',
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  getFirebaseErrMsg(pMessage: any): string
  {
    let retValue: string = '';
    try
    {
      let message = (pMessage && pMessage.message) ? pMessage.message : pMessage;

      if (message)
      {
        const paramRegEx = new RegExp(`(.*)${ APIError.errorPrefixText }`, 'gm');
        retValue = message.replace(paramRegEx, '').split(' (')[0];
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        'error in getFirebaseErrMsg',
        err.toString(),
        'getFirebaseErrMsg',
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  };

  async toShareURL(pTitle: string, pText: string, pUrl: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toShareURL");

      let shareRet = {
        title: this.translate.instant(pTitle),
        text: this.translate.instant(pText),
        url: pUrl
      };
      await navigator.share(shareRet);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL, "error in toShareURL",
        err.toString(),
        "toShareURL",
      );
      this.logger.getResponse(logRequest);
    }
  }

  getViewportHeight()
  {
    let retValue: number = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getViewportHeight");

      if (BASE_CONFIG.IS_WEB)
      {
        const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

        // reduce x pixels from viewportHeight because of web header height
        retValue = viewportHeight - 80;
      }
      else
      {
        const viewportHeight = this.platform.height();

        // reduce x pixels from viewportHeight because of mobile footer height
        retValue = viewportHeight - 66;
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getViewportHeight",
        err.toString(),
        "getViewportHeight",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  defaultErrMsg()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("defaultErrMsg");

      let toastMsg = this.translate.instant('defaultErrorMsg');
      this.toShowToast(toastMsg);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in defaultErrMsg",
        err.toString(),
        "defaultErrMsg",
      );
      this.logger.getResponse(logRequest);
    }
  }

  reloadPage()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("reloadPage");

      window.location.reload();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in reloadPage",
        err.toString(),
        "reloadPage",
      );
      this.logger.getResponse(logRequest);
    }
  }


  generateId(charLength: number = 10)
  {
    let retValue = "";
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("generateId");

      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charsLength = chars.length;

      for (let i = 0; i < charLength; i++)
      {
        retValue += chars.charAt(Math.floor(Math.random() * charsLength));
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in generateId",
        err.toString(),
        "generateId",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }
}




