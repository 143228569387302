import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoggerService } from '../../services/logger/logger.service';
import { BASE_CONFIG } from '../../util/base-settings';
import { COMPONENT_NAME, LINKS } from '../../util/constants';
import { UtilFunctions } from '../../util/util';

@Component({
  selector: 'app-guest-user-model',
  templateUrl: './guest-user-model.component.html',
  styleUrls: ['./guest-user-model.component.scss'],
})
export class GuestUserModelComponent implements OnInit
{

  @Input() isAllPostPage: boolean = false;
  @Output() closeLoginModalEvent = new EventEmitter();

  constructor(
    private logger: LoggerService,
    private util: UtilFunctions
  ) { }

  ngOnInit() { }

  closeLoginModal()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("closeLoginModal");

      this.closeLoginModalEvent.emit();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.GUEST_USER_MODEL_COMPONENT,
        "error in closeLoginModal",
        err.toString(),
        "closeLoginModal",
      );
      this.logger.getResponse(logRequest);
    }
  }

  onSignIn()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onSignIn");

      this.util.openGuestUserModal();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.GUEST_USER_MODEL_COMPONENT,
        "onSignIn",
        err.toString(),
        "onSignIn",
      );
      this.logger.getResponse(logRequest);
    }
  }

  openStaticPage()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("openStaticPage");

      this.util.onOpenLink(LINKS.GUEST_SIGNIN_URL);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.GUEST_USER_MODEL_COMPONENT,
        "openStaticPage",
        err.toString(),
        "openStaticPage",
      );
      this.logger.getResponse(logRequest);
    }
  }

}
