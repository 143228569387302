export const environment = {
  appName: 'diy app',
  envName: 'PROD',
  production: true,
  apiUrl: 'https://tinq-api.ascent24.io/',
  videoApiUrl: 'https://tinq-video-api.ascent24.io/',
  apiVersion: 'v1',
  version: '2.0.383',
  logAppId: 'live.tinq',
  loggerUrl: 'https://b2bapplog.ascent24.io/live.tinq',
  isAdmin: false,
  baseUrl: 'https://tinq.ascent24.io/', // live
  shareUrl: 'https://tinq.ascent24.io/share', // live
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyAm_t5kvme2EP6-kCJUFVfb4knESy1lIy8",
    authDomain: "tinqapp.ascent24.io",
    databaseURL: "https://tinq-app.firebaseio.com",
    projectId: "tinq-app",
    storageBucket: "tinq-app.appspot.com",
    messagingSenderId: "757079808277",
    appId: "1:757079808277:web:bc6cd99bb364f2a451b11c",
    measurementId: "G-487Q4HV540"
  }
};
