<div
  class="back_btns"
  *ngIf="!isAllPostPage"
  (click)="closeLoginModal()"
>
  <ion-icon name="chevron-back-outline"></ion-icon>
</div>
<div class="guest_page design">
  <div class="head">
    <!-- <ion-icon src="/assets/svg/settings/tinq_real_logo.svg" class="post_page_logo"></ion-icon> -->
    <p>
      {{"guestLoginDesc1" | translate}}
    </p>
    <p>
      {{"guestLoginDesc2" | translate}}
    </p>
  </div>
  <div class="sub_text">
    <ul>
      <li>
        <ion-icon name="checkmark-outline"></ion-icon> <span>{{"guestLoginPoint1" | translate}}</span>
      </li>
      <li>
        <ion-icon name="checkmark-outline"></ion-icon> <span> {{"guestLoginPoint2" | translate}} </span>
      </li>
      <li>
        <ion-icon name="checkmark-outline"></ion-icon> <span> {{"guestLoginPoint3" | translate}} </span>
      </li>
    </ul>
  </div>

  <div
    *ngIf="isAllPostPage"
    class="login_btns"
  >
    <ion-button
      expand="block"
      class="ion-margin-start ion-margin-end ion-margin-bottom explore_btn sign_btns"
    >
      <a
        href="/assets/tinq/home.html"
        target="_blank"
      >{{ ("exploreTinQ" | translate) }}</a>

    </ion-button>

    <ion-button
      expand="block"
      class="ion-margin-start ion-margin-end ion-margin-bottom sign_btns sing_new"
      (click)="onSignIn()"
    >
      {{ ("signInText" | translate) }}

      <!-- <ion-spinner
            name="bubbles"
            *ngIf="isSignBtn && showLoading"
          ></ion-spinner> -->
    </ion-button>
  </div>

</div>
