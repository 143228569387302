import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { UtilFunctions } from "../../../util/util";
import { PeopleSuggLsAction, ReceivedFollowersAction, SentFollowingsAction } from "./followers.action";
import { followersDetails } from "./followers.modal";
import { GetUserDetailsResponse } from "social-media-api-pkg";

export const FollowersState: followersDetails = {
    receivedFollowersName: null,
    sentFollowingsName: null,
    peopleSuggLs: []
};

export const FOLLOWERS_TOKEN = new StateToken<followersDetails>('followersDetails');

@State({
    name: FOLLOWERS_TOKEN,
    defaults: FollowersState,
})

@Injectable()
export class FollowersDetailsState
{
    constructor(private util: UtilFunctions) { }

    @Action(ReceivedFollowersAction)
    ReceivedFollowersLs(ctx: StateContext<followersDetails>, action: ReceivedFollowersAction)
    {
        ctx.patchState({ receivedFollowersName: action.followerNames });
    }

    @Action(SentFollowingsAction)
    SentFollowingsLs(ctx: StateContext<followersDetails>, action: SentFollowingsAction)
    {
        ctx.patchState({ sentFollowingsName: action.followingNames });
    }

    @Action(PeopleSuggLsAction)
    updatePeopleSuggLs(ctx: StateContext<followersDetails>, action: PeopleSuggLsAction)
    {
        const state = ctx.getState();

        let people: GetUserDetailsResponse[] = this.util.cloneDeep(state.peopleSuggLs);
        people = people.concat(action.people);

        ctx.patchState({ peopleSuggLs: people });
    }

}
