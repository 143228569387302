import { createSelector } from "@ngxs/store";
import { SettingsModel } from "../../model/settings.model";
import { SettingsState } from "./settings.state";

export const getSettings = () =>
   createSelector([SettingsState], (state: SettingsModel) => state);

export const getIsUserLoggedIn = () =>
   createSelector([SettingsState], (state: SettingsModel) => state.isUserLoggedIn);

export const getPeopleSuggReqFrom = () =>
   createSelector([SettingsState], (state: SettingsModel) => state.reqFrom);

export const getDeviceLocation = () =>
   createSelector([SettingsState], (state: SettingsModel) => state.location);

export const getPostGuideDetail = () =>
   createSelector([SettingsState], (state: SettingsModel) => state.featureShownDetail);
