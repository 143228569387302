import { StoreUpdateTypes } from "../../util/constants";
import { ProfileDetail, VisitedPageDetail } from "./profile.modal";

export class AppUserProfileDetailAction
{
  static readonly type = '[profile] app user profile detail';
  constructor(public profileDetail: ProfileDetail) { }
}

export class UserProfileDetailAction
{
  static readonly type = '[profile] user profile detail';
  constructor(public profileDetail: ProfileDetail) { }
}

export class IsAppUserAction
{
  static readonly type = '[profile] app user';
  constructor(public isAppUser: boolean) { }
}

export class UpdateVisitedPageAction
{
  static readonly type = '[profile] user visited profile detail';
  constructor(public updateType: StoreUpdateTypes, public visitedPage?: VisitedPageDetail) { }
}

export class UpdateAppUsernameAction
{
  static readonly type = '[profile] app username';
  constructor(public username: string) { }
}
export class UpdateAppUserProfileAction
{
  static readonly type = '[profile] user profile';
  constructor(public dpUrl: string) { }
}