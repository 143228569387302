import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebHeaderComponent } from './web-header.component';
import { CommonCompModule } from '../../shared/common.component.module';
import { FivFeatureDiscoveryModule } from '@fivethree/core';


@NgModule({
  declarations: [WebHeaderComponent],
  imports: [
    CommonModule,
    CommonCompModule,
    FivFeatureDiscoveryModule
  ],
  exports: [WebHeaderComponent]
})
export class WebHeaderModule { }
