import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { GetGroupsClientRes, GetPreferenceClientRes, UpdatePreferenceClientRes } from 'social-media-api';
import { SettingsModel } from '../../model/settings.model';
import { APIResponse } from '../../services/service.model';
import { CategorySelectAction } from '../../store/settings/settings.action';
import { getSettings } from '../../store/settings/settings.selectors';
import { getErrorMessage, VALIDATE_SUCCESS_RES } from '../../util/apiValidate';
import { BASE_CONFIG } from '../../util/base-settings';
import { COMPONENT_NAME, SuggestedCategories } from '../../util/constants';
import { PostActions } from '../post/all-post/all-post.actions';
import { getAllCategories, getUserSelCategory } from '../post/all-post/all-post.selectors';
import { LoggerService } from './../../services/logger/logger.service';
import { PostService } from './../../services/post/post.service';
import { UserService } from './../../services/user/user.service';
import { PATHNAME } from './../../util/constants';
import { UtilFunctions } from './../../util/util';
@Component({
  selector: 'app-category',
  templateUrl: './category.page.html',
  styleUrls: ['./category.page.scss'],
})
export class CategoryPage implements OnInit
{

  categoryLs: any[] = [];
  selectedCategoryLs: Array<string> = [];
  selectAlert: string = null;
  userSelectedPreference: any = null;
  isShowFollowSugg: boolean = false;

  @Input() isWebSidePanel: boolean = false;
  @Input() isFromFeedPage: boolean = false;
  @Input() categoryOpt: any[] = [];

  selCategoryLs: any;
  isSelectedAll: boolean = false;

  translate: TranslateService;
  constructor(
    private logger: LoggerService,
    private util: UtilFunctions,
    private store: Store,
    private apiGetCategoryService: PostService,
    private userService: UserService,
    private modalController: ModalController,
    translateService: TranslateService
  ) { this.translate = translateService; }

  ngOnInit()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("ngOnInit");

      let followerSuggStore = this.store.select(getSettings()).subscribe((settingsModel: SettingsModel) =>
      {
        this.isShowFollowSugg = settingsModel.isFollowSuggSelected ? false : true;
      });
      followerSuggStore.unsubscribe();

      if (this.isFromFeedPage)
      {
        this.selectedCategoryLs = this.categoryOpt;

        let allCategoriesStore = this.store.select(getAllCategories())
          .subscribe(categories =>
          {
            if (categories && categories.length > 0)
            {
              this.categoryLs = this.setSelCategories(this.categoryOpt, categories);
              this.isSelectedAll = (this.categoryOpt.length == categories.length) ? true : false;
            }
            else
            {
              this.toGetCategoryList(this.categoryOpt);
            }
          });
        allCategoriesStore.unsubscribe();
      }
      else
      {
        this.checkUserPreference();
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in ngOnInit",
        err.toString(),
        "ngOnInit",
      );
      this.logger.getResponse(logRequest);
    }
  }

  trackItems(index, propOptions: any)
  {
    // return propOptions['groupid'];
    return 'cat_' + index;
  }


  onSkipPreference()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onSkipPreference");

      this.store.dispatch(new CategorySelectAction(false));

      if (this.isShowFollowSugg)
      {
        this.util.navigate(PATHNAME.FOLLOWER_SUGGESTION, null, true);
      }
      else
      {
        this.util.navigate(PATHNAME.POST, null, true);
      }
    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in onSkipPreference",
        err.toString(),
        "onSkipPreference",
      );
      this.logger.getResponse(logRequest);
    }
  }

  toGetCategoryList(pUserSelectedPre)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toGetCategoryList");

      let getCategoryRes$ = this.apiGetCategoryService.getCategoryRes();
      getCategoryRes$.subscribe({
        next: (res: APIResponse<GetGroupsClientRes>) =>
        {
          if (VALIDATE_SUCCESS_RES(res))
          {
            this.categoryLs = res.body.data.groups;
            this.isSelectedAll = this.selectedCategoryLs.length == this.categoryLs.length;
            let categoryLs = this.util.cloneDeep(this.categoryLs);

            this.store.dispatch(new PostActions.AllCategoriesAction(categoryLs));

            if (pUserSelectedPre)
            {
              this.categoryLs = this.setSelCategories(pUserSelectedPre, categoryLs);
            }
            // if (pUserSelectedPre)
            // {
            //   categoryLs.filter(res =>
            //   {
            //     if (res)
            //     {
            //       if (pUserSelectedPre.groups ? pUserSelectedPre.groups.includes(res.groupid) : pUserSelectedPre.includes(res.groupid))
            //       {
            //         res['isSelected'] = true;
            //       } else
            //       {
            //         res['isSelected'] = false;
            //       }
            //     }
            //   });
            // }
            // this.categoryLs = categoryLs;
            if (this.isFromFeedPage)
            {
              this.setDefaultCategory();
            }
          }
          else
          {
            const error = getErrorMessage(res);

            let logRequest = this.util.createLogRequest(COMPONENT_NAME.CATEGORY_PAGE, "toGetCategoryList", error.error[0]);
            this.logger.getResponse(logRequest);

            const isJwt = this.util.handleJWT(error);
            if (!isJwt)
            {
              let alertValue = this.util.getErrorAlertValue(error);
            }
            // this.util.toShowToast(JSON.stringify(error));
          }
        },
        error: (err: HttpErrorResponse) => { },

      });

    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in toGetCategoryList",
        err.toString(),
        "toGetCategoryList",
      );
      this.logger.getResponse(logRequest);
    }
  }


  setDefaultCategory()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("setDefaultCategory");

      for (let suggested in SuggestedCategories)
      {
        loopB: for (let category of this.categoryLs) 
        {
          if (SuggestedCategories[suggested] == category.groupid)
          {
            category.isSelected = true;
            break loopB;
          }
        };
      }
    } catch (error)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in setDefaultCategory",
        error.toString(),
        "setDefaultCategory",
      );
      this.logger.getResponse(logRequest);
    }
  }


  toSavePreference(pGroup)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toSavePreference");


      if (pGroup)
      {
        pGroup.isSelected = !pGroup.isSelected;

        // if (pGroup.isSelected)
        // {
        //   this.selectedCategoryLs.push(pGroup.groupid);
        // }
        // else
        // {
        //   const index = this.selectedCategoryLs.indexOf(pGroup.groupid);
        //   if (index !== -1)
        //   {
        //     this.selectedCategoryLs.splice(index, 1);
        //   }
        // }
      }

      const category = pGroup && pGroup.groupid;

      if (this.selectedCategoryLs.includes(category))
      {
        this.selectedCategoryLs = this.selectedCategoryLs.filter(e => e !== category);
      }
      else
      {
        this.selectedCategoryLs = [...this.selectedCategoryLs, category];
        // this.selectedCategoryLs.push(category);
      }

      if (this.isFromFeedPage || this.isWebSidePanel)
      {
        if (this.selectedCategoryLs.length > 0)
        {
          this.toSaveAllPostPreference(this.selectedCategoryLs);
        }
        else
        {
          this.selectAll();
          this.toSaveAllPostPreference(this.selectedCategoryLs);
        }
      }
      // else
      // {
      //   this.toSaveAllPostPreference(this.selectedCategoryLs);
      // }
      // if (this.selectedCategoryLs.length >= BaseSettings.minCategorySelection)
      // {
      //   this.selectAlert = null;
      // }
      // else
      // {
      //   this.selectAlert = this.translate.instant('categoryErr1') + BaseSettings.minCategorySelection + this.translate.instant('categoryErr2');
      // }

      // this.isSelectedAll = this.categoryLs.every(category => category.isSelected === true);
      this.isSelectedAll = this.selectedCategoryLs.length == this.categoryLs.length;

    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in toSavePreference",
        err.toString(),
        "toSavePreference",
      );
      this.logger.getResponse(logRequest);
    }
  }

  onSaveThePreference()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onSaveThePreference");

      if (this.isFromFeedPage)
      {
        this.modalController.dismiss(this.selectedCategoryLs);
      }
      else
      {
        // if (this.selectedCategoryLs.length >= BaseSettings.minCategorySelection)
        if (this.selectedCategoryLs.length > 0)
        {
          this.selectAlert = null;
          this.toUpdatePreference(this.selectedCategoryLs);
        }
        else
        {
          let allCategories = this.categoryLs.map(category => category.groupid);
          this.toUpdatePreference(allCategories);
          // this.selectAlert = this.translate.instant('categoryErr1') + BaseSettings.minCategorySelection + this.translate.instant('categoryErr2');
        }
      }
    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in onSaveThePreference",
        err.toString(),
        "onSaveThePreference",
      );
      this.logger.getResponse(logRequest);
    }
  }

  toGetPreference()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toGetPreference");

      let getCategoryRes$ = this.userService.getPreferenceRes();
      getCategoryRes$.subscribe({
        next: (res: APIResponse<GetPreferenceClientRes>) =>
        {
          if (VALIDATE_SUCCESS_RES(res))
          {
            // if(res && res.body && res.body.data){
            if (res.body.data.preference)
            {
              this.userSelectedPreference = res.body.data.preference;
              this.store.dispatch(new PostActions.CategoryLsAction(this.userSelectedPreference.groups));

              this.selectedCategoryLs = this.userSelectedPreference.groups;
            }
            this.toGetCategoryList(this.userSelectedPreference);
          }
          else
          {
            const error = getErrorMessage(res);

            let logRequest = this.util.createLogRequest(COMPONENT_NAME.CATEGORY_PAGE, "toGetPreference", error.error[0]);
            this.logger.getResponse(logRequest);

            const isJwt = this.util.handleJWT(error);
            if (!isJwt)
            {
              let alertValue = this.util.getErrorAlertValue(error);
            }
            // this.util.toShowToast(JSON.stringify(error));
          }
        },
        error: (err: HttpErrorResponse) => { },
        // complete: () => console.info('complete') 
      });

    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in toGetPreference",
        err.toString(),
        "toGetPreference",
      );
      this.logger.getResponse(logRequest);
    }
  }

  toUpdatePreference(pSelectedCategoryLs: string[])
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toUpdatePreference");
      let updatePreferenceReq$ = this.userService.updatePreferenceReq(pSelectedCategoryLs);

      let getCategoryRes$ = this.userService.updatePreferenceRes(updatePreferenceReq$);
      getCategoryRes$.subscribe({
        next: (res: APIResponse<UpdatePreferenceClientRes>) =>
        {
          if (VALIDATE_SUCCESS_RES(res))
          {
            // if(res && res.body && res.body.data.isUpdated){
            if (res.body.data.isUpdated)
            {
              this.store.dispatch(new PostActions.CategoryLsAction(pSelectedCategoryLs));

              this.store.dispatch(new CategorySelectAction(true));
              if (!this.isFromFeedPage && !this.isWebSidePanel)
              {
                this.util.navigate(PATHNAME.FOLLOWER_SUGGESTION, null, true);
              }

            }
          }
          else
          {
            const error = getErrorMessage(res);

            let logRequest = this.util.createLogRequest(COMPONENT_NAME.CATEGORY_PAGE, "toUpdatePreference", error.error[0]);
            this.logger.getResponse(logRequest);

            const isJwt = this.util.handleJWT(error);
            if (!isJwt)
            {
              let alertValue = this.util.getErrorAlertValue(error);
            }
            // this.util.toShowToast(JSON.stringify(error));
          }
        },
        error: (err: HttpErrorResponse) => { },
        // complete: () => console.info('complete') 
      });

    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in toUpdatePreference",
        err.toString(),
        "toUpdatePreference",
      );
      this.logger.getResponse(logRequest);
    }
  }

  setSelCategory(pSelCategory)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("setSelCategory");

      if (pSelCategory)
      {
        const categoryId = pSelCategory.groupid;

        this.categoryLs.forEach(category =>
        {
          if (category.groupid === categoryId)
          {
            category.isSelected = !category.isSelected;
          }
        });

        if (this.selectedCategoryLs.includes(categoryId))
        {
          this.selectedCategoryLs = this.selectedCategoryLs.filter(e => e !== categoryId);
        }
        else
        {
          this.selectedCategoryLs = [...this.selectedCategoryLs, categoryId];
        }

        // if (this.selectedCategoryLs.length >= BaseSettings.minCategorySelection)
        // {
        //   this.selectAlert = null;
        // }
        // else
        // {
        //   this.selectAlert = this.translate.instant('categoryErr1') + BaseSettings.minCategorySelection + this.translate.instant('categoryErr2');
        // }

        // this.isSelectedAll = this.categoryLs.every(category => category.isSelected === true);
        this.isSelectedAll = this.selectedCategoryLs.length == this.categoryLs.length;
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in setSelCategory",
        err.toString(),
        "setSelCategory",
      );
      this.logger.getResponse(logRequest);
    }
  }

  selectAll()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("selectAll");

      this.isSelectedAll = !this.isSelectedAll;

      let tempLs = [];

      this.categoryLs.forEach(category =>
      {
        if (this.isSelectedAll)
        {
          tempLs.push(category.groupid);
        }
        category.isSelected = this.isSelectedAll;
      });

      this.selectedCategoryLs = this.util.cloneDeep(tempLs);

      // if(this.isFromFeedPage){
      //   let tempCategoryList = [];
      //   if(this.isSelectedAll){
      //     tempCategoryList = this.selectedCategoryLs;
      //   }
      //   this.toSaveAllPostPreference(tempCategoryList);
      // }

      // if (this.isSelectedAll)
      // {
      //   this.selectAlert = null;
      // }
      // else
      // {
      //   this.selectAlert = this.translate.instant('categoryErr1') + BaseSettings.minCategorySelection + this.translate.instant('categoryErr2');
      // }

    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in selectAll",
        error.toString(),
        "selectAll"
      );
      this.logger.getResponse(logRequest);
    }
  }

  searchSelCategory()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("searchSelCategory");

      this.selCategoryLs(this.categoryLs, true);

    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in searchSelCategory",
        err.toString(),
        "searchSelCategory",
      );
      this.logger.getResponse(logRequest);
    }
  }

  navToOtherPage()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToOtherPage");

      this.util.navigate(PATHNAME.ADD_PROFILE);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in navToOtherPage",
        err.toString(),
        "navToOtherPage",
      );
      this.logger.getResponse(logRequest);
    }
  }

  setSelCategories(selLs: any, pCategoryLs: any[])
  {
    let retValue: any = [];
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("setSelCategories");

      if (selLs)
      {
        pCategoryLs.filter(category =>
        {
          if (category)
          {
            let tempValue = this.util.cloneDeep(category);
            if (tempValue.groupid != "explore")
            {
              if (
                selLs.groups
                  ? selLs.groups.includes(category.groupid)
                  : selLs.includes(category.groupid)
              )
              {
                tempValue['isSelected'] = true;
              }
              else
              {
                tempValue['isSelected'] = false;
              }
            }
            retValue.push(tempValue);
          }
        });
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in setSelCategories",
        err.toString(),
        "setSelCategories",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  toSaveAllPostPreference(pGroup)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toSaveAllPostPreference");

      this.toUpdatePreference(pGroup);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in toSaveAllPostPreference",
        err.toString(),
        "toSaveAllPostPreference",
      );
      this.logger.getResponse(logRequest);
    }
  }

  checkUserPreference()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("checkUserPreference");

      let preferenceStore = this.store.select(getUserSelCategory())
        .subscribe(preference =>
        {
          if (preference && preference.length > 0)
          {
            this.selectedCategoryLs = preference;
            this.getAllCategoriesFromStore();
          }
          else
          {
            this.toGetPreference();
          }
        });
      preferenceStore.unsubscribe();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in checkUserPreference",
        err.toString(),
        "checkUserPreference",
      );
      this.logger.getResponse(logRequest);
    }
  }


  getAllCategoriesFromStore()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getAllCategoriesFromStore");

      let allCategoriesStore = this.store.select(getAllCategories())
        .subscribe(categories =>
        {
          if (categories && categories.length > 0)
          {
            this.categoryLs = this.setSelCategories(this.selectedCategoryLs, categories);
            this.isSelectedAll = (this.selectedCategoryLs.length == categories.length) ? true : false;
          }
          else
          {
            this.toGetCategoryList(this.selectedCategoryLs);
          }
        });
      allCategoriesStore.unsubscribe();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.CATEGORY_PAGE,
        "error in getAllCategoriesFromStore",
        err.toString(),
        "getAllCategoriesFromStore",
      );
      this.logger.getResponse(logRequest);
    }
  }
}
