import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, SERVICE_URL } from '../../util/constants';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { APIResponse } from '../service.model';

@Injectable({
  providedIn: 'root'
})
export class DevicesService
{

  constructor(
    private logger: LoggerService,
    private httpService: HttpService
  ) { }


  apiGetAllDeviceReq = () =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("apiGetAllDeviceReq....");

      const reqBody = {};
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.DEVICE_SERVICE_SERVICE,
        "error in apiGetAllDeviceReq",
        err.toString(),
        "apiGetAllDeviceReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };


  apiGetAllDeviceRes = (pReq: any): Observable<APIResponse<any>> =>
  {
    let retValue: Observable<APIResponse<any>>;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("apiGetAllDeviceRes");

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_ALL_DEVICE, pReq);

    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.DEVICE_SERVICE_SERVICE,
        "error in apiGetAllDeviceRes",
        err.toString(),
        "apiGetAllDeviceRes",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };


  apiAddDeviceReq = (pDeviceType: string, pDeviceId: string) =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("apiAddDeviceReq....");

      const reqBody = {
        "deviceType": pDeviceType,
        "deviceToken": pDeviceId
      };
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.DEVICE_SERVICE_SERVICE,
        "error in apiAddDeviceReq",
        err.toString(),
        "apiAddDeviceReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };


  apiAddDeviceRes = (pReq: any): Observable<APIResponse<any>> =>
  {
    let retValue: Observable<APIResponse<any>>;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("apiAddDeviceRes");

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_ADD_DEVICE, pReq);

    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.DEVICE_SERVICE_SERVICE,
        "error in apiAddDeviceRes",
        err.toString(),
        "apiAddDeviceRes",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };


  getUserSettingsReq = () =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUserSettingsReq....");

      const reqBody = {};
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.DEVICE_SERVICE_SERVICE,
        "error in getUserSettingsReq",
        err.toString(),
        "getUserSettingsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };


  getUserSettingsRes = (pReq: any): Observable<APIResponse<any>> =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getUserSettingsRes");

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_USER_SETTINGS, pReq);

    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.DEVICE_SERVICE_SERVICE,
        "error in getUserSettingsRes",
        err.toString(),
        "getUserSettingsRes",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };
}
