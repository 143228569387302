import { createSelector } from "@ngxs/store";
import { ProfileDetailsModal } from "./profile.modal";
import { ProfileState } from "./profile.state";

export const getAppUserProfile = () =>
    createSelector([ProfileState], (state: ProfileDetailsModal) => state.appUserProfile);

export const getUserProfile = () =>
    createSelector([ProfileState], (state: ProfileDetailsModal) => state.userProfile);

export const getIsAppUser = () =>
    createSelector([ProfileState], (state: ProfileDetailsModal) => state.isAppUser);

export const getUserVisitedPage = () =>
    createSelector([ProfileState], (state: ProfileDetailsModal) => state.visitedPage);
