import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { GoogleAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat/app';
// import * as firebaseui from 'firebaseui';
//import 'firebaseui/dist/firebaseui.css';
import { Subscription } from 'rxjs';
import { FirebaseUserModel } from '../../../model/Login.model';
import { SettingsModel } from '../../../model/settings.model';
import { AuthService } from '../../../services/auth/auth.service';
import { LoggerService } from '../../../services/logger/logger.service';
import { GuestUserAction } from '../../../store/login/login.actions';
import { CategorySelectAction, FollowSuggSelectAction, IsUserLoggedInAction, ProfileVisitAction } from '../../../store/settings/settings.action';
import { getSettings } from '../../../store/settings/settings.selectors';
import { BASE_CONFIG } from '../../../util/base-settings';
import { COMPONENT_NAME } from '../../../util/constants';
import { FormValidators } from '../../../util/form-validators';
import { PostFunctions } from '../../../util/post-functions';
import { PostActions } from '../../post/all-post/all-post.actions';
import { PATHNAME, SOCIAL_LOGIN_TYPE } from './../../../util/constants';
import { UtilFunctions } from './../../../util/util';

let firebaseui = null;
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, OnDestroy
{
  @Input() isGuestFlow: boolean = false;

  login: FormGroup;
  alertValue: string = null;
  isSubmitted: boolean = false;
  isSignBtn: boolean = false;

  SOCIAL_LOGIN_TYPE = SOCIAL_LOGIN_TYPE;

  @Input() isIntroSlidesVisited: boolean = false;
  @Input() isIntroSkipped: boolean = false;
  isProfileVisited: boolean = true;

  closeModal: any = null;
  subscriptions: Subscription[] = [];

  translate: TranslateService;
  showLoading: boolean = false;

  constructor(
    private store: Store,
    private util: UtilFunctions,
    private authService: AuthService,
    private logger: LoggerService,
    private postFunctions: PostFunctions,
    translateService: TranslateService
  ) { this.translate = translateService; }


  ngOnInit(): void
  {
    try
    {
      // this.click=fromEvent(document.body,'click');

      if (!this.isGuestFlow)
      {
        this.util.clearLocalStorage();
        this.checkIntroSlidesVisited();
      }

      this.login = new FormGroup({
        email: new FormControl('', [Validators.required, FormValidators.email]),
        password: new FormControl('', [FormValidators.notAllowSpaces, FormValidators.password, Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
      });
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in ngOnInit",
        err.toString(),
        "ngOnInit",
      );
      this.logger.getResponse(logRequest);
    }
  }

  ngOnDestroy()
  {
    try
    {
      this.subscriptions
        .forEach((subscription) => subscription.unsubscribe());
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in ngOnDestroy",
        err.toString(),
        "ngOnDestroy",
      );
      this.logger.getResponse(logRequest);
    }
  }


  displayOneTapSignIn()
  {
    try
    {
      let userObj: FirebaseUserModel = {
        displayName: null,
        email: null,
        phoneNumber: null,
        photoURL: null,
        stsTokenManager: null,
        ProviderId: null
      };
      // var ui = new firebaseui.auth.AuthUI(firebase.auth())

      var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
      let that = this;
      var uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl)
          {
            if (authResult)
            {
              that.authService.firebaseAuthApi(authResult.user.auth.currentUser.accessToken).then(res =>
              {
                if (res)
                {
                  // let clonedGoogleUser = this.util.cloneDeep(authResult.user);
                  let clonedGoogleUser = that.util.cloneDeep(authResult.user);
                  // let clonedGoogleUser = authResult.user;

                  userObj.displayName = clonedGoogleUser.displayName;
                  userObj.email = clonedGoogleUser.email ? clonedGoogleUser.email : null;
                  userObj.phoneNumber = clonedGoogleUser.phoneNumber;
                  userObj.photoURL = clonedGoogleUser.photoURL;
                  userObj.stsTokenManager = authResult.user.auth.currentUser.accessToken;
                  userObj.ProviderId = clonedGoogleUser.providerId;

                  that.authService.setUserProfile(clonedGoogleUser);

                  that.postFunctions.handleRedirect(false, that.isGuestFlow);
                  // this.userDevices.checkUserDevice();
                  // this.getUserSettings();
                  // this.getPreference();
                }
              });
            }
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return false;
          },

        },
        signInOptions: [
          {
            provider: GoogleAuthProvider.PROVIDER_ID,
            clientId: '757079808277-hml7u11pk6kb4t4ehhidtssfgas4ugka.apps.googleusercontent.com'
          }
        ],
        credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
      };

      if (document.querySelector('#firebaseui-auth-container'))
      {
        ui.start('#firebaseui-auth-container', uiConfig);
      }

      // ui.disableAutoSignIn()
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in displayOneTapSignIn",
        err.toString(),
        "displayOneTapSignIn",
      );
      this.logger.getResponse(logRequest);
    }
  }

  ionViewDidEnter()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log('ionViewDidEnter');

      if (BASE_CONFIG.IS_WEB)
      {
        this.authService.isAppInit.subscribe(res =>
        {
          if (res && (this.isIntroSkipped || this.isIntroSlidesVisited == true))
          {
            firebaseui = require('firebaseui');

            this.displayOneTapSignIn();
          }
        });
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in ionViewDidEnter",
        err.toString(),
        "ionViewDidEnter",
      );
      this.logger.getResponse(logRequest);
    }
  }

  toGetIsIntroSkipped(event: boolean)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toGetIsIntroSkipped");

      this.isIntroSkipped = event;

    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in toGetIsIntroSkipped",
        err.toString(),
        "toGetIsIntroSkipped",
      );
      this.logger.getResponse(logRequest);
    }
  }

  checkIntroSlidesVisited()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("checkIntroSlidesVisited");

      let basicSettingStore$ = this.store.select(getSettings())
        .subscribe((settingsModel: SettingsModel) =>
        {
          if (settingsModel)
          {
            this.isIntroSlidesVisited = settingsModel.isIntroVisited;
            this.isProfileVisited = settingsModel.isProfileVisited;
          }
        });

      this.subscriptions.push(basicSettingStore$);
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in checkIntroSlidesVisited",
        err.toString(),
        "checkIntroSlidesVisited",
      );
      this.logger.getResponse(logRequest);
    }
  }

  async navToForgot()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToForgot");

      this.util.closeModal();
      this.util.navigate(PATHNAME.FORGOT, null, true);

    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in navToForgot",
        err.toString(),
        "navToForgot",
      );
      this.logger.getResponse(logRequest);
    }
  }

  async navToRegister()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToRegister");

      this.util.closeModal();

      this.util.navigate(PATHNAME.REGISTER, null, true);

    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in navToRegister",
        err.toString(),
        "navToRegister",
      );
      this.logger.getResponse(logRequest);
    }
  }

  async signInAsGuest()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("signInAsGuest");

      this.showLoading = true;
      this.isSignBtn = false;

      let isValidLogin = await this.authService.signInAnonymously();

      if (isValidLogin)
      {
        // this.util.clearLocalStorage();
        this.store.dispatch(new PostActions.PostIdsAction(null));
        this.store.dispatch(new PostActions.clearPostListAction(null));

        this.store.dispatch(new GuestUserAction(true));
        await this.util.closeModal();
        this.util.navigate(PATHNAME.POST, null, true);
      }

      this.showLoading = false;
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in signInAsGuest",
        err.toString(),
        "signInAsGuest",
      );
      this.logger.getResponse(logRequest);
    }
  }

  async emailPwdLogin()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("emailPwdLogin");

      this.isSubmitted = true;
      this.isSignBtn = true;
      this.alertValue = null;

      if (this.login.valid)
      {
        this.store.dispatch(new GuestUserAction(false));
        this.store.dispatch(new IsUserLoggedInAction(true));
        this.showLoading = true;
        this.alertValue = null;

        if (this.isGuestFlow)
        {
          // this.util.clearLocalStorage();
          this.store.dispatch(new ProfileVisitAction(true));
        }

        const isLoggedin: any = await this.authService.loginWithEmailPwd(
          this.login.controls['email'].value,
          this.login.controls['password'].value
        );

        if (isLoggedin == true)
        {
          this.util.closeModal();
          // this.userDevices.checkUserDevice();
          // guest user or normal user, now generally consider as app user
          // this.postFunctions.handleRedirect(this.isProfileVisited);
          this.postFunctions.handleRedirect(false, this.isGuestFlow);
        }
        else
        {
          this.alertValue = this.util.getFirebaseErrMsg(isLoggedin.message);
        }
        this.showLoading = false;
      }
      else
      {
        this.alertValue = this.translate.instant('mandatoryFields');
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in emailPwdLogin",
        err.toString(),
        "emailPwdLogin",
      );
      this.logger.getResponse(logRequest);
    }
  }

  async onSocialLogin(socialType: SOCIAL_LOGIN_TYPE)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onSocialLogin");

      this.store.dispatch(new GuestUserAction(false));
      this.store.dispatch(new IsUserLoggedInAction(true));
      //   let isEntered=false;
      //  this.sClick= this.click.subscribe(()=>{
      //     if(isEntered){
      //       this.showLoading = false;
      //       this.test();
      //     }
      //     isEntered=true;

      //   })

      // this.isSubmitted = true;
      this.showLoading = true;
      this.alertValue = null;

      // if (this.login.valid)
      // {
      const isLoggedin: any = await this.authService.onSocialLogin(socialType);

      if (isLoggedin == true)
      {
        let isNewUser: boolean = this.checkIsNewUser();

        if (isNewUser)
        {
          this.util.closeModal();
          this.clearStore();
          this.store.dispatch(new IsUserLoggedInAction(true));
          await this.postFunctions.handleRedirect(true, this.isGuestFlow);
        }
        else
        {
          this.store.dispatch(new IsUserLoggedInAction(true));
          await this.postFunctions.handleRedirect(false, this.isGuestFlow);
        }
        // guest user or normal user, now generally consider as app user
        // this.postFunctions.handleRedirect(this.isProfileVisited);
        // this.postFunctions.handleRedirect(false, this.isGuestFlow);
      }
      else
      {
        this.alertValue = this.util.getFirebaseErrMsg(isLoggedin.message);
      }
      // }
      // else
      // {
      //   this.alertValue = this.translate.instant('mandatoryFields');
      // }
      this.showLoading = false;
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in togglePwdType",
        err.toString(),
        "togglePwdType",
      );
      this.logger.getResponse(logRequest);
    }
  }

  closeLoginModal()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("closeLoginModal");

      this.closeModal();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in closeLoginModal",
        err.toString(),
        "closeLoginModal",
      );
      this.logger.getResponse(logRequest);
    }
  }

  clearStore()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("clearStore");

      this.store.dispatch(new ProfileVisitAction(false));
      this.store.dispatch(new CategorySelectAction(false));
      this.store.dispatch(new FollowSuggSelectAction(false));
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in clearStore",
        err.toString(),
        "clearStore",
      );
      this.logger.getResponse(logRequest);
    }
  }

  checkIsNewUser()
  {
    let retValue: boolean = false;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("checkIsNewUser");

      let basicSettingStore$ = this.store.select(getSettings())
        .subscribe((settingsModel: SettingsModel) =>
        {
          if (settingsModel)
          {
            retValue = !settingsModel.isProfileVisited;
          }
        });

      basicSettingStore$.unsubscribe();
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.LOGIN_PAGE,
        "error in checkIsNewUser",
        err.toString(),
        "checkIsNewUser",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }
}
