<ion-list>
  <ion-item
    button
    *ngFor="let btn of buttonLs; trackBy:trackBy; let i = index"
    (click)="getSelectedData(btn)"
  >
    <div
      [innerHtml]="btn.name"
      class="popover_cotainer_text"
    ></div>
  </ion-item>
</ion-list>
