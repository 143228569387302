import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddPostCommentClientReq, AddPostCommentClientRes, DeletePostCommentClientReq, DeletePostCommentClientRes, DislikePostCommentClientReq, DislikePostCommentClientRes, GetPostCommentClientReq, GetPostCommentClientRes, LikePostCommentClientReq, LikePostCommentClientRes, RemoveCommentDislikeClientReq, RemoveCommentDislikeClientRes, RemovePostCommentLikeClientReq, RemovePostCommentLikeClientRes, ReportPostCommentClientReq, ReportPostCommentClientRes, UpdatePostCommentClientReq, UpdatePostCommentClientRes } from 'social-media-api';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, SERVICE_URL } from '../../util/constants';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { APIResponse } from '../service.model';
import { PostCommentModel } from "social-media-api-pkg";
@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(private logger: LoggerService, private httpService: HttpService) { }

  /// add comment
  addCommentsReq = (pPostId: string, pComment: string) => {
    let retValue: any;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("addCommentsReq....");

    
      const reqBody: AddPostCommentClientReq = {
        postId: pPostId,
        comment: pComment,
      };
      retValue = reqBody
    } catch (err: any) {
      const body = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in addCommentsReq",
        err.toString(),
        "addCommentsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  }

  addCommentsRes(pReq: AddPostCommentClientReq): Observable<APIResponse<AddPostCommentClientRes>> {
    let retValue: Observable<APIResponse<AddPostCommentClientRes>>;

    try {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_ADD_COMMENT, pReq);

    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in addCommentsRes",
        err.toString(),
        "addCommentsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  /// add comment like

  addCommentsLikeReq = (pPostId: string, pCommentId: string): LikePostCommentClientReq => {
    let retValue: any;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("addCommentsLikeReq....");


      const reqBody: LikePostCommentClientReq = {
        postId: pPostId,
        commentId: pCommentId
      };
      retValue = reqBody
    } catch (err: any) {
      const body = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in addCommentsLikeReq",
        err.toString(),
        "addCommentsLikeReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  }

  addCommentsLikeRes(pReq: LikePostCommentClientReq): Observable<APIResponse<LikePostCommentClientRes>> {
    let retValue: Observable<APIResponse<LikePostCommentClientRes>>;

    try {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_ADD_COMMENT_LIKE, pReq);

    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in addCommentsLikeRes",
        err.toString(),
        "addCommentsLikeRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  /// remove comment like

  removeCommentsLikeReq = (pPostId: string, pCommentId: string): RemovePostCommentLikeClientReq => {
    let retValue: any;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeCommentsLikeReq....");


      const reqBody: RemovePostCommentLikeClientReq = {
        postId: pPostId,
        commentId: pCommentId
      };
      retValue = reqBody
    } catch (err: any) {
      const body = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in removeCommentsLikeReq",
        err.toString(),
        "removeCommentsLikeReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  }

  removeCommentsLikeRes(pReq: RemovePostCommentLikeClientReq): Observable<APIResponse<RemovePostCommentLikeClientRes>> {
    let retValue: Observable<APIResponse<RemovePostCommentLikeClientRes>>;

    try {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_REMOVE_COMMENT_LIKE, pReq);

    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in removeCommentsLikeRes",
        err.toString(),
        "removeCommentsLikeRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// add comment dislike

  addCommentsDisLikeReq = (pPostId: string, pCommentId: string): DislikePostCommentClientReq => {
    let retValue: any;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("addCommentsDisLikeReq....");


      const reqBody: DislikePostCommentClientReq = {
        postId: pPostId,
        commentId: pCommentId
      };
      retValue = reqBody
    } catch (err: any) {
      const body = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in addCommentsDisLikeReq",
        err.toString(),
        "addCommentsDisLikeReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  }

  addCommentsDisLikeRes(pReq: DislikePostCommentClientReq): Observable<APIResponse<DislikePostCommentClientRes>> {
    let retValue: Observable<APIResponse<DislikePostCommentClientRes>>;

    try {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_ADD_COMMENT_DISLIKE, pReq);

    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in addCommentsDisLikeRes",
        err.toString(),
        "addCommentsDisLikeRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// remove comment dislike

  removeCommentsDisLikeReq = (pPostId: string, pCommentId: string): RemoveCommentDislikeClientReq => {
    let retValue: any;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("removeCommentsDisLikeReq....");


      const reqBody: RemoveCommentDislikeClientReq = {
        postId: pPostId,
        commentId: pCommentId
      };
      retValue = reqBody
    } catch (err: any) {
      const body = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in removeCommentsDisLikeReq",
        err.toString(),
        "removeCommentsDisLikeReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  }

  removeCommentsDisLikeRes(pReq: RemoveCommentDislikeClientReq): Observable<APIResponse<RemoveCommentDislikeClientRes>> {
    let retValue: Observable<APIResponse<RemoveCommentDislikeClientRes>>;

    try {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_REMOVE_COMMENT_DISLIKE, pReq);

    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in removeCommentsDisLikeRes",
        err.toString(),
        "removeCommentsDisLikeRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// report comment 

  reportCommentsReq = (pPostId: string, pCommentId: string,pReason: PostCommentModel.ReportReason, pReasonTxt: string,): ReportPostCommentClientReq => {
    let retValue: any;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("reportCommentsReq....");


      const reqBody: ReportPostCommentClientReq = {
        postId: pPostId,
        commentId: pCommentId,
    reason: pReason,
    reasonText: pReasonTxt,
      };
      retValue = reqBody
    } catch (err: any) {
      const body = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in reportCommentsReq",
        err.toString(),
        "reportCommentsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  }

  reportCommentsRes(pReq: ReportPostCommentClientReq): Observable<APIResponse<ReportPostCommentClientRes>> {
    let retValue: Observable<APIResponse<ReportPostCommentClientRes>>;

    try {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_REPORT_COMMENT, pReq);

    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in reportCommentsRes",
        err.toString(),
        "reportCommentsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// get comment 

  getCommentsReq = (pPostId: string, poffset: number): GetPostCommentClientReq => {
    let retValue: any;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("getCommentsReq....");


      const reqBody: GetPostCommentClientReq = {
        postId: pPostId,
        offset: poffset
      };
      retValue = reqBody
    } catch (err: any) {
      const body = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in getCommentsReq",
        err.toString(),
        "getCommentsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  }

  getCommentsRes(pReq: GetPostCommentClientReq): Observable<APIResponse<GetPostCommentClientRes>> {
    let retValue: Observable<APIResponse<GetPostCommentClientRes>>;

    try {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_COMMENT, pReq);

    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in getCommentsRes",
        err.toString(),
        "getCommentsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  /// update comment 

  updateCommentsReq = (pPostId: string, pComment: string, pCommentId: string): UpdatePostCommentClientReq => {
    let retValue: any;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("updateCommentsReq....");


      const reqBody: UpdatePostCommentClientReq = {
        postId: pPostId,
        comment: pComment,
        commentId: pCommentId
      };
      retValue = reqBody
    } catch (err: any) {
      const body = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in updateCommentsReq",
        err.toString(),
        "updateCommentsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  }

  updateCommentsRes(pReq: UpdatePostCommentClientReq): Observable<APIResponse<UpdatePostCommentClientRes>> {
    let retValue: Observable<APIResponse<UpdatePostCommentClientRes>>;

    try {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_UPDATE_COMMENT, pReq);

    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.COMMENT_SERVICE,
        "error in updateCommentsRes",
        err.toString(),
        "updateCommentsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

    /// delete comment 

    deleteCommentsReq = (pPostId: string, pCommentId: string): DeletePostCommentClientReq => {
      let retValue: any;
      try {
        if (BASE_CONFIG.IS_DEBUG) console.log("deleteCommentsReq....");
  
  
        const reqBody: DeletePostCommentClientReq = {
          postId: pPostId,
          commentId: pCommentId
        };
        retValue = reqBody
      } catch (err: any) {
        const body = this.logger.buildRequest(
          FILE_NAME.COMMENT_SERVICE,
          "error in deleteCommentsReq",
          err.toString(),
          "deleteCommentsReq",
        );
        this.logger.getResponse(body);
      }
      return retValue;
    }
  
    deleteCommentsRes(pReq: DeletePostCommentClientReq): Observable<APIResponse<DeletePostCommentClientRes>> {
      let retValue: Observable<APIResponse<DeletePostCommentClientRes>>;
  
      try {
  
        retValue = this.httpService.makePostRequest(SERVICE_URL.API_DELETE_COMMENT, pReq);
  
      } catch (err) {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.COMMENT_SERVICE,
          "error in deleteCommentsRes",
          err.toString(),
          "deleteCommentsRes",
        );
        this.logger.getResponse(logRequest);
      }
      return retValue;
    }

}
