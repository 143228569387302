import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FivFeature } from '@fivethree/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { AppService } from '../../services/app/app.service';
import { FeatureGuideService } from '../../services/feature-guide.service';
import { LoggerService } from '../../services/logger/logger.service';
import { ProfileFlowService } from '../../services/profile/profile-flow.service';
import { getPostGuideDetail } from '../../store/settings/settings.selectors';
import { BASE_CONFIG } from '../../util/base-settings';
import { COMPONENT_NAME, FeatureTypes, PageNames, PATHNAME } from '../../util/constants';
import { UtilFunctions } from '../../util/util';
@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.scss'],
})
export class WebHeaderComponent implements OnInit
{
  @ViewChild('searchFeature') searchFeature: FivFeature;
  @ViewChild('addPostFeature') addPostFeature: FivFeature;
  @ViewChild('profileFeature') profileFeature: FivFeature;
  @ViewChild('infoFeature') infoFeature: FivFeature;

  @Input() isSearch: boolean = false;
  @Input() isDetailPage: boolean = false;
  @Input() isGuestUser: boolean = false;
  @Input() isFitToScreen: boolean = false;

  currFeatureInd: number = -1;
  currFeature: any = null;

  featureDescLs = [
    { id: FeatureTypes.Search, title: 'exploreDIY', desc: 'searchFeature' },
    { id: FeatureTypes.AddPost, title: 'postDIY', desc: 'addPostFeature' },
    { id: FeatureTypes.Profile, title: 'profile', desc: 'profileFeature' },
    { id: FeatureTypes.ViewChange, title: 'postView', desc: 'viewFeature' },
    { id: FeatureTypes.HeaderDummy, title: '', desc: '' },
  ];

  subscriptions: Subscription[] = [];
  // alreadyChecked: boolean;

  constructor(
    private util: UtilFunctions,
    private logger: LoggerService,
    private router: Router,
    private store: Store,
    private appService: AppService,
    private profileFlowService: ProfileFlowService,
    private featureGuideService: FeatureGuideService,
  ) { }

  ngOnInit() { }


  ngAfterViewInit()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("ngAfterViewInit");

      this.checkFeature();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in ngAfterViewInit",
        err.toString(),
        "ngAfterViewInit",
      );
      this.logger.getResponse(logRequest);
    }
  }

  checkFeature()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("checkFeature");

      // enable to see Feature Discover
      const feature$ = this.store.select(getPostGuideDetail())
        .subscribe(details =>
        {
          let currUrl = this.router.url.split('?')[0];

          if (currUrl == PATHNAME.POST)
          {
            if (!this.isGuestUser && details && !details.isPostGuideShown)
            {
              this.featureGuideService.canShowBackdrop.next(true);
              let that = this;
              this.currFeatureInd = FeatureTypes.Search;
              setTimeout(() => { that.showNextFeature(); }, 2000);
            }
            else
            {
              this.featureGuideService.canShowBackdrop.next(false);
            }
          }
        });

      this.subscriptions.push(feature$);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in checkFeature",
        err.toString(),
        "checkFeature",
      );
      this.logger.getResponse(logRequest);
    }
  }

  toggleIsSearch()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toggleIsSearch");

      if (this.isGuestUser)
      {
        this.util.openGuestUserModal();
      }
      else
      {
        if (this.isDetailPage) // details page search click
        {
          let query = { state: { openSearch: true } };
          this.router.navigate([PATHNAME.POST], query);
        }
        this.appService.toggleSearch.next(true);
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in toggleIsSearch",
        err.toString(),
        "toggleIsSearch",
      );
      this.logger.getResponse(logRequest);
    }
  }

  onCloseSearch()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onCloseSearch");

      if (this.isGuestUser)
      {
        this.util.openGuestUserModal();
      }
      else
      {
        this.appService.closeSearch.next(true);
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in onCloseSearch",
        err.toString(),
        "onCloseSearch",
      );
      this.logger.getResponse(logRequest);
    }
  }

  scrollToTop()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("scrollToTop");

      this.appService.scrollToTop.next(true);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in scrollToTop",
        err.toString(),
        "scrollToTop",
      );
      this.logger.getResponse(logRequest);
    }
  }

  onClickFitToScreen()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onClickFitToScreen");

      if (this.isGuestUser)
      {
        this.util.openGuestUserModal();
      }
      else
      {
        this.appService.fitToScreen.next(true);
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in onClickFitToScreen",
        err.toString(),
        "onClickFitToScreen",
      );
      this.logger.getResponse(logRequest);
    }
  }

  navToNotification()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToNotification");

      if (this.isGuestUser)
      {
        this.util.openGuestUserModal();
      }
      else
      {
        this.util.navigate(PATHNAME.USER_NOTIFICATION);
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in navToNotification",
        err.toString(),
        "navToNotification",
      );
      this.logger.getResponse(logRequest);
    }
  }

  navToPost()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToPost");

      if (this.isGuestUser)
      {
        this.util.openGuestUserModal();
      }
      else
      {
        let currUrl = this.router.url ? this.router.url.split("?") : '';
        let postId = currUrl.length > 1 ? currUrl[1].split("=")[1] : null;

        let query = { replaceUrl: true, state: { postId: postId } };
        this.router.navigate([PATHNAME.POST], query);
        // this.util.navigate(PATHNAME.POST);
        this.profileFlowService.resetUserName();
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in navToPost",
        err.toString(),
        "navToPost",
      );
      this.logger.getResponse(logRequest);
    }
  }

  navToSavedPosts()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToSavedPosts");

      if (this.isGuestUser)
      {
        this.util.openGuestUserModal();
      }
      else
      {
        this.util.navigate(PATHNAME.SAVED_POSTS);
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in navToSavedPosts",
        err.toString(),
        "navToSavedPosts",
      );
      this.logger.getResponse(logRequest);
    }
  }

  navToAppUserProfile()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToAppUserProfile");

      if (this.isGuestUser)
      {
        this.util.openGuestUserModal();
      }
      else
      {
        this.profileFlowService.resetUserName();
        this.profileFlowService.addUserName(null, true, PageNames.Profile, false);
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in navToAppUserProfile",
        err.toString(),
        "navToAppUserProfile",
      );
      this.logger.getResponse(logRequest);
    }
  }

  navToAddPost()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToAddPost");

      if (this.isGuestUser)
      {
        this.util.openGuestUserModal();
      }
      else
      {
        this.util.navigate(PATHNAME.ADD_POST);
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in navToAddPost",
        err.toString(),
        "navToAddPost",
      );
      this.logger.getResponse(logRequest);
    }
  }

  showNextFeature()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("showNextFeature");

      if ((this.searchFeature && this.addPostFeature && this.profileFeature) || this.infoFeature)
      {
        this.currFeature = this.featureDescLs[this.currFeatureInd];

        switch (this.currFeatureInd)
        {
          case FeatureTypes.Search:
            {
              // backdrop event disable
              document.body.classList.add('diy_feature');

              this.searchFeature.featurePadding = 50;
              this.searchFeature.diameter = 750;

              this.searchFeature.show();
              this.addPostFeature.hide();
              this.profileFeature.hide();

              this.currFeatureInd = FeatureTypes.AddPost;
              break;
            }
          case FeatureTypes.AddPost:
            {
              this.addPostFeature.featurePadding = 50;
              this.addPostFeature.diameter = 750;

              this.searchFeature.hide();
              this.addPostFeature.show();
              this.profileFeature.hide();

              this.currFeatureInd = FeatureTypes.Profile;
              break;
            }
          case FeatureTypes.Profile:
            {
              this.profileFeature.featurePadding = 50;
              this.profileFeature.diameter = 750;

              this.searchFeature.hide();
              this.addPostFeature.hide();
              this.profileFeature.show();

              this.currFeatureInd = FeatureTypes.HeaderDummy;
              break;
            }
          case FeatureTypes.ViewChange:
            {
              this.searchFeature.hide();
              this.addPostFeature.hide();
              this.profileFeature.hide();
              this.infoFeature.show();

              this.currFeatureInd = FeatureTypes.HeaderDummy;
              break;
            }
          case FeatureTypes.HeaderDummy:
            {
              this.searchFeature.hide();
              this.addPostFeature.hide();
              this.profileFeature.hide();

              this.featureGuideService.otherElemInViewport.next(false);
              const url = this.router.url.split('?')[0];

              if (url == PATHNAME.POST)
              {
                this.featureGuideService.canShowPostFeature.next(true);
              }
              else
              {
                this.infoFeature.hide();
                this.featureGuideService.canShowPostDetailsFeature.next(true);
              }
              break;
            }
          default: break;
        }
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.WEB_HEADER_COMPONENT,
        "error in showNextFeature",
        err.toString(),
        "showNextFeature",
      );
      this.logger.getResponse(logRequest);
    }
  }
}
