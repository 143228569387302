import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeletePostAdminClientReq, GetPostClientReq, GetPostClientRes } from 'social-media-api';
import { GetAllPostAdminReq, GetAllPostAdminRes } from 'social-media-api-pkg';
import { TokenAction } from '../../store/login/login.actions';
import { BASE_CONFIG } from '../../util/base-settings';
import { FILE_NAME, SERVICE_URL } from '../../util/constants';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { APIResponse, Status } from '../service.model';


@Injectable({
  providedIn: 'root'
})
export class AdminService
{

  constructor(
    private logger: LoggerService,
    private httpService: HttpService,
    private store: Store
  ) { }

  loginWithEmailPwd(pReq: any):Observable<APIResponse<any>>
  {
    let retValue$:Observable<APIResponse<any>>;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("loginWithEmailPwd");

      retValue$ = this.httpService.makePostRequest(SERVICE_URL.API_LOGIN, pReq);
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.ADMIN_SERVICE,
        "error in loginWithEmailPwd",
        err.toString(),
        "loginWithEmailPwd",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue$;
  }

  getCaptchaLsRes(req: null): Observable<APIResponse<any>>
  {
    let retValue: Observable<APIResponse<any>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_CAPTCHA, req);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.ADMIN_SERVICE,
        "error in apiGetCaptchaLsRes",
        err.toString(),
        "apiGetCaptchaLsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }


  // post ids
  getAllPostIdsReq = (pFromDate: string, pToDate: string, pUserIds: string[], pStatus: number[]): GetAllPostAdminReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getAllPostIdsReq....");

      const reqBody: GetAllPostAdminReq = {
        fromDate: pFromDate,
        toDate: pToDate,
        userId: pUserIds,
        status: pStatus
      };
      retValue = reqBody;
    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.ADMIN_SERVICE,
        "error in getAllPostIdsReq",
        err.toString(),
        "getAllPostIdsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getAllPostIdsRes(pReq: GetAllPostAdminReq): Observable<APIResponse<GetAllPostAdminRes>>
  {
    let retValue: Observable<APIResponse<GetAllPostAdminRes>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_ALL_POST_IDS, pReq);

    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.ADMIN_SERVICE,
        "error in getAllPostIdsRes",
        err.toString(),
        "getAllPostIdsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  // get all posts

  getAdminAllPostsReq = (pPostIds: string[]): GetPostClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getAdminAllPostsReq....");

      const reqBody: GetPostClientReq = {
        "postIds": pPostIds,
        isUser: true
      };
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.ADMIN_SERVICE,
        "error in getAdminAllPostsReq",
        err.toString(),
        "getAdminAllPostsReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };

  getAdminAllPostsRes(pReq: GetPostClientReq): Observable<APIResponse<GetPostClientRes>>
  {
    let retValue: Observable<APIResponse<GetPostClientRes>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_GET_ALL_POSTS, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.ADMIN_SERVICE,
        "error in getAdminAllPostsRes",
        err.toString(),
        "getAdminAllPostsRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  // delete post

  getAdminDeletePostReq = (pPostIds: string[]): DeletePostAdminClientReq =>
  {
    let retValue: any;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getAdminDeletePostReq....");

      const reqBody: DeletePostAdminClientReq = {
        postId: pPostIds
      };
      retValue = reqBody;
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.ADMIN_SERVICE,
        "error in getAdminDeletePostReq",
        err.toString(),
        "getAdminDeletePostReq",
      );
      this.logger.getResponse(body);
    }
    return retValue;
  };


  getAdminDeletePostRes(pReq: DeletePostAdminClientReq): Observable<APIResponse<any>>
  {
    let retValue: Observable<APIResponse<any>>;

    try
    {
      retValue = this.httpService.makePostRequest(SERVICE_URL.API_ADMIN_DELETE_POST, pReq);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.ADMIN_SERVICE,
        "error in getAdminDeletePostRes",
        err.toString(),
        "getAdminDeletePostRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  ///renew token
  getRenewTokenRes(): Observable<APIResponse<any>>
  {
    let retValue: Observable<APIResponse<any>>;

    try
    {

      retValue = this.httpService.makePostRequest(SERVICE_URL.RENEW_JWT, null);

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.ADMIN_SERVICE,
        "error in getRenewTokenRes",
        err.toString(),
        "getRenewTokenRes",
      );
      this.logger.getResponse(logRequest);
    }
    return retValue;
  }

  renewToken(): Observable<boolean>
  {
    let retValue: Observable<boolean>;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("renewToken");

      let renewalStatus$ = this.getRenewTokenRes();

      return renewalStatus$.pipe(
        map((data) =>
        {
          let authToken = data.body.status == Status.Success ? data.body.data.authorization : null;

          if (data.body.status == Status.Success)
          {
            this.store.dispatch(new TokenAction(authToken));
          } else
          {
            this.store.dispatch(new TokenAction(null));
          }
          return data.body.status == Status.Success;
        })
      );
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.ADMIN_SERVICE,
        "error in renewToken",
        err.toString(),
        "renewToken"
      );
      this.logger.getResponse(logRequest);
    }
  }

}
