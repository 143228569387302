<ion-app [style.--height-viewport]="viewportHeight">

  <!-- <app-custom-skeleton
    [skeletonType]="'profile'"
    *ngIf="showLoading"
  ></app-custom-skeleton> -->

  <ion-backdrop
    visible="true"
    *ngIf="canShowBackdrop"
  ></ion-backdrop>

  <app-web-header
    *ngIf="isWeb && canShowHeader && !isSearchOpen"
    [isDetailPage]="isDetailPage"
    [isFitToScreen]="isFitToScreen"
    [isGuestUser]="isGuestUser"
  >
  </app-web-header>

  <!-- API progress bar -->
  <ion-progress-bar
    *ngIf="isPrgrsCompleted"
    style="z-index: 999;"
    type="indeterminate"
  ></ion-progress-bar>

  <ion-split-pane
    [when]="splitPane"
    contentId="main-content"
    [ngClass]="{'has_header': (canShowHeader && !isSearchOpen)}"
  >
    <!--  the side menu  -->
    <div
      *ngIf="isWeb"
      side="start"
      contentId="main-content"
      [ngClass]="{'has_not_side_pane': !canShowHeader || isSearchOpen || isDetailPage}"
    >
      <!-- show side panel modal only for post feed page -->
      <div *ngIf="canShowHeader && !isSearchOpen && !isDetailPage">

        <!-- guest modal -->
        <app-guest-user-model
          *ngIf="isGuestUser"
          [isAllPostPage]="true"
        >
        </app-guest-user-model>

        <!-- Category Model -->
        <app-category
          *ngIf="!isGuestUser"
          [isWebSidePanel]="true"
        ></app-category>

      </div>
    </div>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
<!-- *ngIf="authState" id="main" -->
