import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuideService
{

  canShowPostFeature = new BehaviorSubject<boolean>(false);
  canShowPostDetailsFeature = new BehaviorSubject<boolean>(false);
  canShowMobilePostFeature = new BehaviorSubject<boolean>(false);
  canShowViewFeature = new BehaviorSubject<boolean>(false);
  otherElemInViewport = new BehaviorSubject<boolean>(false);
  canShowBackdrop = new BehaviorSubject<boolean>(false);

  constructor() { }
}
