<div
  id="firebaseui-auth-container"
  style="display: none;"
></div>

<ion-content
  [fullscreen]="true"
  class="auth-page ion_content_modal"
  [ngClass]="{ 
    'login_page': isIntroSlidesVisited || isIntroSkipped || isGuestFlow,
    'intro_content' : (!isIntroSlidesVisited && !isGuestFlow) ? (!isIntroSkipped ? true : false) : false
  }"
>

  <app-c-intro-slides
    *ngIf="(!isIntroSlidesVisited && !isGuestFlow) ? (!isIntroSkipped ? true : false) : false"
    (isIntroSlidesSkipped)="toGetIsIntroSkipped($event)"
    (introVisited)="checkIntroSlidesVisited()"
  >
  </app-c-intro-slides>

  <ion-grid *ngIf="isIntroSlidesVisited || isIntroSkipped || isGuestFlow">
    <ion-row>


      <ion-col
        *ngIf="isGuestFlow"
        [ngClass]="{'guest_login': isGuestFlow}"
      >
        <!-- <div class="back_btns">
          <ion-icon
            name="chevron-back-outline"
            (click)="closeLoginModal()"
          ></ion-icon>
        </div>
        <div class="guest_page design">
          <div class="head">
            <p>
              {{"guestLoginDesc1" | translate}}
            </p>
            <p>
              {{"guestLoginDesc2" | translate}}
            </p>
          </div>
          <div class="sub_text">
            <ul>
              <li>
                <ion-icon name="checkmark-outline"></ion-icon> <span>{{"guestLoginPoint1" | translate}}</span>
              </li>
              <li>
                <ion-icon name="checkmark-outline"></ion-icon> <span> {{"guestLoginPoint2" | translate}} </span>
              </li>
              <li>
                <ion-icon name="checkmark-outline"></ion-icon> <span> {{"guestLoginPoint3" | translate}} </span>
              </li>
            </ul>
          </div>

        </div> -->

        <app-guest-user-model (closeLoginModalEvent)="closeLoginModal()"> </app-guest-user-model>
      </ion-col>

      <ion-col
        class="ion-text-center"
        size-md="6"
        size-lg="5"
        size-xs="12"
      >
        <div class="logo">
          <!-- <ion-img src="assets/svg/tinq_logo.svg"></ion-img> -->
          <ion-icon src="/assets/svg/cube_logo.svg"></ion-icon>
          <p
            *ngIf="!isGuestFlow"
            (click)="!showLoading ? signInAsGuest() : null"
          >{{"guest" | translate}}</p>
        </div>

        <form
          [formGroup]="login"
          class="login_form_container"
        >

          <h1
            class="header"
            *ngIf="!isGuestFlow"
          >{{ ("welcomeBackText" | translate) }}</h1>
          <p class="sub_header">
            {{ ("signInDescText" | translate) }}
          </p>

          <ion-card class="auth-card ion-margin">
            <!-- <ion-card-content> -->
            <ion-item>
              <a24-label
                [labelTxt]="'emailId'"
                [position]="'floating'"
              ></a24-label>
              <a24-input
                [className]="''"
                [propForm]="login"
                [propControlName]="'email'"
                [propControl]="login.controls['email']"
                [propType]="'email'"
                [propAutoComplete]="'off'"
                [isNeedIcon]="true"
                [iconName]="'person-outline'"
                [iconClass]="'with-icon right'"
                [validateOnTouch]="false"
                [isFormSubmitted]="isSubmitted"
              >
              </a24-input>
            </ion-item>
            <ion-item>
              <a24-label
                [labelTxt]="'passwordText'"
                [position]="'floating'"
              ></a24-label>
              <a24-input
                [className]="''"
                [propForm]="login"
                [propControlName]="'password'"
                [propControl]="login.controls['password']"
                [propType]="'Password'"
                [propAutoComplete]="'off'"
                [isNeedIcon]="true"
                [iconSwap]="true"
                [iconName]="'lock-closed-outline'"
                [iconName2]="'lock-open-outline'"
                [iconClass]="'with-icon right'"
                [validateOnTouch]="false"
                [isFormSubmitted]="isSubmitted"
              >
              </a24-input>
            </ion-item>
            <!-- </ion-card-content> -->
          </ion-card>
        </form>
        <div
          class="forgot_pw"
          *ngIf="!isGuestFlow"
        >
          <a
            (click)="navToForgot()"
            class="ion-text-right"
          >
            {{ ("forgetPwdText" | translate) }}
          </a>
        </div>

        <ion-button
          expand="block"
          class="ion-margin-start ion-margin-end ion-margin-bottom sign_btns"
          (click)="emailPwdLogin()"
          [disabled]="showLoading"
        >
          {{ ("signInText" | translate) }}

          <ion-spinner
            name="bubbles"
            *ngIf="isSignBtn && showLoading"
          ></ion-spinner>
        </ion-button>

        <div
          *ngIf="alertValue"
          class="hide-err"
        >
          {{alertValue}}
        </div>

        <div class="or_continue">
          <span class="sideline left">
            <ion-img src="/assets/img/sideline-left.svg"></ion-img>
            <!-- <ion-icon src="/assets/img/sideline-left.svg"></ion-icon> -->
          </span>
          <ion-text>
            <p>{{ ("alterOptText" | translate) }}</p>
          </ion-text>
          <span class="sideline right">
            <span class="sideline left">
              <!-- <ion-icon src="/assets/img/sideline-right.svg"></ion-icon> -->
              <ion-img src="/assets/img/sideline-right.svg"></ion-img>
            </span>
          </span>
        </div>

        <div class="social_login">
          <span (click)="onSocialLogin(SOCIAL_LOGIN_TYPE.GOOGLE)">
            <ion-icon src="assets/svg/logo_google.svg"></ion-icon>
            <div>
              <p>{{ "googleLogin" | translate}}</p>
            </div>
          </span>

          <!-- this feature enable in future -->
          <!-- <span (click)="onSocialLogin(SOCIAL_LOGIN_TYPE.FACEBOOK)">
            <ion-icon name="logo-facebook"></ion-icon>
          </span>
          <span (click)="onSocialLogin(SOCIAL_LOGIN_TYPE.TWITTER)">
            <ion-icon name="logo-twitter"></ion-icon>
          </span> -->
        </div>

        <!-- <ion-button
          *ngIf="!isGuestFlow"
          expand="block"
          class="ion-margin guest_btn"
          [disabled]="showLoading"
          (click)="signInAsGuest()"
        >
          {{ ("asGuestText" | translate) }}

          <ion-spinner
            name="bubbles"
            *ngIf="!isSignBtn && showLoading"
          ></ion-spinner>
        </ion-button> -->

      </ion-col>
      <div class="spacer"></div>
      <div class="d-flex justify-center sign_bottom">
        <p> {{ ("newAccountText" | translate) }}</p>
        <ion-button
          expand="block"
          class="ion-margin ion-no-padding transparent_txt_btn"
          [disabled]="showLoading"
          (click)="navToRegister()"
        >
          {{ ("signUpText" | translate) }}
        </ion-button>
      </div>

    </ion-row>
  </ion-grid>
</ion-content>
